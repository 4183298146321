@use '@angular/material' as mat;
@mixin map-controls($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .ccp-map-controls {
        .ccp-map-controls {
            &-btn {
                &.ccp-map-controls-btn--remove {
                    .rpc-icon-btn {
                        &:not(.mat-button-disabled) {
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($warn, default);
                            }
                        }
                    }
                }
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        border-color: mat.get-color-from-palette($foreground, divider);
                        background-color: mat.get-color-from-palette($background, card);
                        &:hover,
                        &.active {
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
            }
            &-item {
                &--neighborhoods {
                    border-color: mat.get-color-from-palette($foreground, divider);
                    background-color: mat.get-color-from-palette($background, card);
                }
            }
        }
    }
}