@mixin map-layout-common() {
    google-map {
        .map {
            &-container {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
