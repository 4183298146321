@use '@angular/material' as mat;
@mixin paginator($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .paginator{
        .rpc-btn-link {
            &.active {
                background: mat.get-color-from-palette($accent, default);
                border-color: mat.get-color-from-palette($accent, default);
                .rpc-btn-link {
                    &-text {
                        color: mat.get-color-from-palette($background, card);
                    }
                    &-icon {
                        color: mat.get-color-from-palette($background, card);
                    }
                }
            }
        }
    }
}