@use '@angular/material' as mat;
@mixin omni-search($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .chip {
        &-wrap {
            &.mat-form-field-appearance {
                &-outline {
                    .mat-form-field-outline {
                        background-color: mat.get-color-from-palette($background, card);
                    }
                }
            }
        }
        &-icon {
            color: mat.get-color-from-palette($accent, default);
        }
        &-list {
            &-empty {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-input {
            background-color: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, text);
        }
        &-item {
            background-color: mat.get-color-from-palette($accent, default, .2);
            color: mat.get-color-from-palette($accent, default);
            &-icon {
                color: mat.get-color-from-palette($accent, default-contrast);
                --text-color: #{mat.get-color-from-palette($accent, default)};
                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
            }
        }
        &-dropdown {
            &-group {
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                    --main-color: #{mat.get-color-from-palette($accent, default)};
                    --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                }
                .mat {
                    &-optgroup {
                        &-label {
                            color: mat.get-color-from-palette($foreground, text);
                            border-bottom-color: mat.get-color-from-palette($foreground, divider);
                        }
                    }
                    &-option {
                        border-bottom-color: mat.get-color-from-palette($foreground, divider);
                    }
                }
            }
        }
    }
}