@mixin registration-common() {
    .register {
        .auth {
            &-form {
                &-input {
                    &:last-of-type {
                        .mat-form-field-wrapper {
                            padding-bottom: 10px;
                        }
                    }
                }
                .agent-name {
                    font-size: 16px;
                }
            }
        }
    }
}
