@use '@angular/material' as mat;
@mixin toastr-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .toast-container {
        .toast-custom {
            &.ngx-toastr {
                box-shadow: none;
                background: none;
            }
        }
    }
}
.toast-container {
    position: absolute;
    top: 5px;
    right: 5px;
    left: 5px;
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
    align-items: start;
    justify-items: end;
    @include media-breakpoint("gt-xs") {
        top: 10px;
        right: 10px;
        left: auto;
        row-gap: 30px;
    }
    @include media-breakpoint("gt-sm") {
        top: 29px;
        right: 29px;
        row-gap: 30px;
    }
    .toast-custom {
        &.ngx-toastr {
            border-radius: 0;
            padding: 0;
            width: 100%;
            max-width: 568px;
            margin: 0;
            overflow: visible;
            @include media-breakpoint("gt-xs") {
                max-width: 650px;
            }
            @include media-breakpoint("gt-sm") {
                width: 650px;
                max-width: none;
            }
            & > div {
                display: grid;
                grid-template-columns: 100%;
                justify-items: end;
                align-items: start;
            }
        }
    }
}