@mixin listing-notes-common() {
    .rpc-l-notes {
        &-modal {
            width: 714px;
            max-width: 100% !important;
            max-height: 85vh;
            .mat-dialog-container {
                --padding: 15px;
                padding: 0;
                @include media-breakpoint("gt-xs") {
                    --padding: 20px;
                }
            }
        }
        &-wrap {
            .rpc-textarea {
                &.mat-form-field {
                    .mat-input-element {
                        max-height: 100px;
                        @media screen and (min-height: 400px) {
                            max-height: 160px;
                        }
                        @media screen and (min-height: 480px) {
                            max-height: 50vh;
                        }
                    }
                }
                .mat-form-field {
                    &-wrapper {
                        padding-bottom: 0;
                    }
                    &-label {
                        mat-label {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}