@use '@angular/material' as mat;
@mixin rpc-range-slider($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .range-slider {
        &-wrap {
            .mat-slider {
                &-min-value {
                    &.mat-slider-thumb-label-showing {
                        .mat-slider-thumb,
                        .mat-slider-thumb-label {
                            background-color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &-track-background {
                    background-color: mat.get-color-from-palette($foreground, text);
                }
                &:hover,
                &.cdk-focused {
                    .mat-slider-track-background {
                        background-color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
        }
    }
}
