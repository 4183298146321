html,
body {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: visible !important;
    overscroll-behavior-y: none;
}

// Remove outline for Firefox
.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
    &::-moz-focus-inner,
    &:active,
    &:focus {
        outline: none;
        // border: 0;
        -moz-outline-style: none;
    }
}

body {
    &.modal-open {
        .global-content {
            @include media-breakpoint("sm") {
                overflow-y: hidden;
            }
            &-wrap {
                @include media-breakpoint("xs") {
                    overflow-y: hidden;
                }
            }
        }
    }
    &.refreshing {
        .global-toolbar,
        .global-content-wrap,
        .quick-panel {
            filter: blur(1px);
            touch-action: none;
            pointer-events: none;
        }
        .refresher {
            transform: translate3d(0, 150%, 0) scale(1);
            z-index: 1100;
            visibility: visible;
            &.shrink {
                transform: translate3d(0, 150%, 0) scale(0);
                opacity: 0;
            }
        }
        .calendar-legend-btn {
            [data-device*="android"] & {
                @include media-breakpoint("xs") {
                    top: 93px;
                }
            }
        }
        .sr-view-sidebar {
            @include media-breakpoint("sm") {
                top: 0;
            }
            @include media-breakpoint("xs") {
                top: 0;
            }
        }
    }
}

.bottom-panel-wrap {
    height: 100vh;
    max-height: 100vh !important;
    @include media-breakpoint("gt-xs") {
        height: auto !important;
        max-height: 80vh !important;
    }
}

button,
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="password"],
input[type="image"],
input[type="submit"],
input[type="button"],
input[type="search"],
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}

.word-wrap {
    word-break: break-all;
}
.update-toast a {
    color: #ffffff !important;
}

.main-content {
    padding: 0;
    @include media-breakpoint("gt-sm") {
        padding: 0 60px;
    }
}
.prompt-main-btn .rpc-btn-link {
    width: 100%;
}
