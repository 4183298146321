@use '@angular/material' as mat;
@mixin footer($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .footer {
        &-wrap {
            color: mat.get-color-from-palette($foreground, secondary-text);
            @if ($is-dark) {
                background: mat.get-color-from-palette($foreground, base, .12);
            } @else {
                background: #d5d5d5;
            }
        }
    }
}
