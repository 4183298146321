@use '@angular/material' as mat;
@mixin portfolio-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .portfolio-page {
        &-top {
            color: mat.get-color-from-palette($foreground, text);
            &-text {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}