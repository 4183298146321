@mixin paginator-common() {
    .paginator {
        .rpc-btn-link {
            min-width: 1px;
            width: 40px;
            padding:  0;
            &-icon{
                font-size:1em;
            }
        }
    }
}
