@mixin chat-common() {
    .rpc-chatbot {
        &-chat {
            &-message {
                &-text {
                    span {
                        font-weight: 500;
                    }
                }
                &-icon {
                    .rpci {
                        font-size: 20px;
                    }
                }
            }
            &-button {
                &.rpc-chatbot-chat-button {
                    &--additional {
                        .rpc-btn-link {
                            &.mat-stroked-button {
                                .rpc-btn-link {
                                    &-icon {
                                        font-size: 26px;
                                    }
                                }
                            }
                        }
                    }
                    &--secondary {
                        .rpc-btn-link {
                            &.mat-stroked-button {
                                justify-content: flex-start;
                                height: auto;
                                min-height: 40px;
                                white-space: normal;
                                .rpc-btn-link {
                                    &-text {
                                        text-align: left;
                                        padding-block: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
                .rpc-btn-link {
                    &.mat-stroked-button {
                        border-radius: 20px;
                        .rpc-btn-link {
                            &-icon {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
            @keyframes load {
                0%    {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
                16.67%{-webkit-mask-position: 0% 100%,50% 0%  ,100% 0%  }
                33.33%{-webkit-mask-position: 0% 100%,50% 100%,100% 0%  }
                50%   {-webkit-mask-position: 0% 100%,50% 100%,100% 100%}
                66.67%{-webkit-mask-position: 0% 0%  ,50% 100%,100% 100%}
                83.33%{-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 100%}
                100%  {-webkit-mask-position: 0% 0%  ,50% 0%  ,100% 0%  }
            }
        }
    }
}
