@mixin gallery-external-listing-card-common() {
    
    .rpc-external-card {
        &-img-wrap {
            .main,
            .broken {
                &-background-image {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }
        }
        &-top {
            &-avatar {
                .avatar-initials-wrap {
                    .text,
                    .main-image {
                        font-size: inherit;
                        width: 2em;
                        height: 2em;
                        line-height: 2em;
                    }
                }
            }
        }
        
        &-info {
            .rpc-icon-field {
                &-text {
                    font-size: 14px;
                    line-height: 16px;
                    text-transform: capitalize;
                }
            }
        }
        &-footer {
            &-btn {
                
            }
        }
    }
}
