@use '@angular/material' as mat;
@mixin onboarding-dialog($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-onboarding {
        &-close {
            background-color: mat.get-color-from-palette($background, card);
            .rpc-btn-link {
                &:not(.mat-button-disabled) {
                    .rpc-btn-link-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        &-system-text {
            color: mat.get-color-from-palette($accent, 500);
        }
        &-list {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-counter {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-nav {
            .rpc-icon-btn {
                border-color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
