@mixin custom-spinner-common() {
    .mp-splash {
        &-wrap {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 100;
            font-size: 30px;
        }
    }
}
