@mixin checkbox-filter-common() {
    .cf {
        &-btn {
            .rpc-btn-link {
                min-width: 30px;
                padding: 0 10px;
                &-text {
                    font-size: 0.8em;
                }
            }
        }
        &-checkbox {
            .mat-checkbox {
                &-layout {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 9px 0;
                }
                &-inner-container {
                    margin: 0 8px 0 0;
                }
            }
        }
    }
}
