@use '@angular/material' as mat;

@mixin last-price-change($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .price {
        &-simple{
            color:mat.get-color-from-palette($foreground, text);
        }
        &-btn{
            &--down {
                .rpc-menu-icon-btn{
                    &:hover,
                    &.active,
                    &[aria-expanded=true]{
                        .rpc-menu-icon-btn-icon{
                            color: var(--decrease-color) !important;
                        }
                    }
                    &-icon{
                        color: var(--decrease-color) !important;
                    }
                }
            }
            &--up {
                .rpc-menu-icon-btn{
                    &:hover,
                    &.active,
                    &[aria-expanded=true]{
                        .rpc-menu-icon-btn-icon{
                            color: var(--increase-color) !important;
                        }
                    }
                    &-icon{
                        color: var(--increase-color) !important;
                    }
                }
            }
        }
        &-dropdown{
            color:mat.get-color-from-palette($foreground, text);
            &-info{
                border-left-color:mat.get-color-from-palette($accent, default);
                &-option{
                    span{
                        color:mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
}
