@mixin not-found-common() {
    .error{
        &-info{
            .rpc-btn-link{
                width:100%;
                @include media-breakpoint('gt-sm'){
                    width:auto;
                }
                &-text{
                    font-weight:700;
                    letter-spacing: .21px;
                }
            }
        }
    }
}