@mixin profile-common() {
    .profile {
        &-content {
            .mat-tab {
                &-body-content {
                    padding: 30px 10px 30px;
                    @include media-breakpoint("gt-xs") {
                        padding: 30px 30px 70px;
                    }
                    @include media-breakpoint("gt-sm") {
                        padding: 30px;
                    }
                }
            }
        }
        &-block {
            &-btn {
                &-wrap {
                    .fix {
                        .rpc-btn-link {
                            -webkit-transform: none;
                        }
                    }
                }
            }
            .fix {
                .rpc-btn-link {
                    -webkit-transform: none;
                }
            }
        }
        &-agent {
            &-inner {
                .pal {
                    &-item {
                        @include media-breakpoint('gt-sm') {
                            position: relative;
                        }
                        @media screen and (min-width: 1350px) {
                            position: static;
                        }
                        &:not(:first-of-type) {
                            &:before {
                                @include media-breakpoint('gt-sm') {
                                    position: absolute;
                                    display: block;
                                    content: '';
                                    top: 0;
                                    left: -16px;
                                    bottom:0;
                                    border-left-width: 1px;
                                    border-left-style: solid;
                                    z-index: 5;
                                }
                                @media screen and (min-width: 1350px) {
                                    display: none;
                                }
                            }
                        }
                    }
                    &-divider {
                        @include media-breakpoint('gt-sm') {
                            display: none;
                        }
                        @media screen and (min-width: 1350px) {
                            display: block;
                        }
                    }
                }
            }
        }
        &-col-box {
            .dark-toggle {
                .mat-slide-toggle {
                    &-label {
                        justify-content: space-between;
                        align-items: flex-start;
                    }
                    &-bar {
                        margin-top: 3px;
                    }
                }
            }
        }
    }
}
