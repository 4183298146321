@mixin add-edit-folder-form-common() {
    .folder-edit {
        &-form {
            &--menu {
                .folder-edit-action {
                    .rpc-btn-link {
                        width: 100%;
                    }
                }
            }
        }
    }
}