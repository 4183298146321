@mixin additional-footer-common() {
    &.theme-bhs,
    &.theme-bhs-dark,
    &.theme-halstead,
    &.theme-halstead-dark {
        .rpc-additional-footer {
            .rpc-additional-footer-company {
                max-width: 100px;
            }
        }
    }
}