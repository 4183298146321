@use '@angular/material' as mat;
@mixin appointments($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .app {
        &-empty {
            &-wrap {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        // &-group {
        //     &-title {
        //         color: mat-color($foreground, secondary-text);
        //         &-wrap {
        //             border-bottom-color: mat-color($foreground, divider);
        //         }
        //     }
        // }
    }
}
