@mixin agent-selection-common() {
    .agent-selection-page-wrap {
        .agent-selection-list {
            @include media-breakpoint("gt-sm") {
                overflow: visible;
                max-height: none;
            }
        }
    }
}
