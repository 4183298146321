@use '@angular/material' as mat;
@mixin common($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    --picklist-color: #4da850;
    --picklist-color-200: #4da85053;
    --like-color: #4a769d;
    --like-color-200: rgba(74, 118, 157, 0.1);
    --dislike-color: #{mat.get-color-from-palette($warn, 600)};
    --dislike-color-200: #{mat.get-color-from-palette($warn, 600, .1)};
    --visit-color: #f7d416;
    --visit-color-200: #f7d5164d;

    --nm-500: #8A58CA;
    --nm-200: #E8DEF4;

    --na-500: #F78F16;
    --na-200: #FDE9D0;

    --price-drop-500: #40A167;
    --price-drop-200: #D9ECE1;

    --decrease-color: #40A167;
    --increase-color: #{mat.get-color-from-palette($warn, 600)};

    --status-accept-waiting: #{mat.get-color-from-palette($foreground, secondary-text)};
    --status-accept-required: #{mat.get-color-from-palette($foreground, secondary-text)};
    --status-confirmed: #4da850;
    --status-confirmed-200: rgb(77 168 80 / 15%);
    --status-shown: #f7d416;
    --status-declined: #{mat.get-color-from-palette($warn, 600)};
    --status-declined: #{mat.get-color-from-palette($warn, 600)};

    --status-accept-waiting--new: #{mat.get-color-from-palette($accent, 50)};
    --status-accept-required--new: #fffce6;
    --status-confirmed--new: rgba(0, 185, 92, 0.05);
    --status-shown--new: rgba(102, 102, 102, 0.05);
    --status-declined--new: #{mat.get-color-from-palette($warn, 50)};

    --rentals-flag: #f7d416;
    --sales-flag: #4da850;

    --has-comments: #{mat.get-color-from-palette($foreground, secondary-text)};
    --has-comments-color: #{mat.get-color-from-palette($background, card)};

    @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
        --has-new-comments: #{mat.get-color-from-palette($warn, default)};
        --has-new-comments-color: #{mat.get-color-from-palette($warn, default-contrast)};
    } @else {
        --has-new-comments: #{mat.get-color-from-palette($accent, default)};
        --has-new-comments-color: #{mat.get-color-from-palette($accent, default-contrast)};
    }
    
    --comment-read-color: #4da850;

    // Chatbot variables
    @if ($is-dark) {
        --chatbot-logo-500: #ffffff;
        --chatbot-logo-600: #ffffff;
        --chatbot-300: rgba(167, 98, 166, .2);
        --chatbot-500: #db6fd9;
        --chatbot-600: #aa37cc;
        --chatbot-500-contrast: #fff;
        --chatbot-600-contrast: #fff;

        --chatbot-primary-300: rgb(91 95 118 / 35%);
        --chatbot-primary-300-contrast: #fff;
    } @else {
        --chatbot-logo-500: #7460a5;
        --chatbot-logo-600: #c92166;
        --chatbot-300: rgba(167, 98, 166, .1);
        --chatbot-500: #a762a6;
        --chatbot-600: #9811c0;
        --chatbot-500-contrast: #fff;
        --chatbot-600-contrast: #fff;

        --chatbot-primary-300: rgb(55 59 84 / 5%);
        --chatbot-primary-300-contrast: #373b54;
    }

    --chatbot-primary-500: #373b54;
    --chatbot-primary-500-contrast: #fff;

    // rpc-info colors
    @if ($is-dark) {
        --info-500-15: rgb(74 118 157 / 15%);
        --info-500: #4c9adf;

        --warn-500-15: rgb(247 212 22 / 15%);
        --warn-500: #f9da33;
    } @else {
        --info-500-15: rgb(74 118 157 / 15%);
        --info-500: #4a769d;

        --warn-500-15: rgb(247 212 22 / 15%);
        --warn-500: #c2a400;
    }

    --error-500-15: #{mat.get-color-from-palette($warn, 500, .15)};
    --error-500: #{mat.get-color-from-palette($warn, 500)};

    --success-500-15: rgb(77 168 80 / 10%);
    --success-500: #4da850;

    color: mat.get-color-from-palette($foreground, secondary-text);

    & {
        color: mat.get-color-from-palette($foreground, text);
        @if ($is-dark) {
            background: mat.get-color-from-palette($background, background);
        } @else {
            background: #f3f3f3;
        }

        &.theme-aanyc {
            --has-new-comments: #{mat.get-color-from-palette($warn, 600)};
        }
    }

    .rpc-dropdown-menu {
        &-item {
            &:hover {
                .item {
                    &-text {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            .item {
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
    }
}
