@mixin last-price-change-common() {
    .price {
        &-wrap {
            .mat-menu-content {
                padding: 0;
                font-size: 14px;
                line-height: 20px;
            }
        }
        &-btn {
            .rpc-menu-icon-btn {
                font-size: 20px !important;
                width: 30px;
                height: 30px;
                line-height: 30px;
                display: block;
            }
        }
        &-dropdown {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 16px;
            line-height: 20px;
            &-wrap {
                max-width: 320px;
                @include media-breakpoint("gt-xs") {
                    max-width: 340px;
                }
            }
            &-title {
                font-weight: 500;
                margin-bottom: 2px;
            }
            &-date {
                font-size: 12px;
                line-height: 1.17;
                margin-bottom: 12px;
            }
            &-info {
                border-left: 3px solid;
                padding-left: 12px;
                margin: 0 10px;
                &-wrap {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0 -10px;
                }
                &-title {
                    font-weight: 500;
                }
            }
        }
    }
}
