@use '@angular/material' as mat;
@mixin neighborhood-tooltip($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .neighborhood-tooltip {
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text);
    }
}