@use '@angular/material' as mat;
@mixin rpc-menu-button($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-menu-btn-link {
        &:not(.mat-button-disabled) {
            &.active,
            &:hover,
            &[aria-expanded="true"] {
                .rpc-menu-btn-link-text {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
            .rpc-menu-btn-link {
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
        &.mat-button-disabled {
            .rpc-menu-btn-link {
                &-icon {
                    color: mat.get-color-from-palette($accent, default, .1);
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, disabled);
                }
            }
        }
        &-badge {
            background-color: mat.get-color-from-palette($background, card);
        }
    }
}