@use '@angular/material' as mat;
@mixin listing-transportation($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .transportation {
        &-footer {
            &-btn {
                color: mat.get-color-from-palette($accent, default);
                &:hover {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
    }
}
