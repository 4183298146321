@use '@angular/material' as mat;
@mixin appointment-notification($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-notification-appointment {
        &-address,
        &-comment {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-address {
            &:hover {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-date {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-btn {
            &--accept {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        .rpc-btn-link {
                            &-icon {
                                color: var(--status-confirmed);
                                --main-color: var(--status-confirmed);
                                --text-color: #fff;
                            }
                            &-text {
                                color: var(--status-confirmed);
                            }
                        }
                    }
                }
            }
            &--decline {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        .rpc-btn-link{ 
                            &-icon {
                                color: var(--status-declined);
                                --main-color: var(--status-declined);
                                --text-color: #fff;
                            }
                            &-text {
                                color: var(--status-declined);
                            }
                        }
                    }
                }
            }
        }
        &-status {
            &--confirmed {
                color: var(--status-confirmed);
            }
            &--declined {
                color: var(--status-declined);
                --main-color: var(--status-declined);
                --text-color: #fff;
            }
            &--shown {
                color: var(--status-shown);
            }
        }
    }
    .rpc-notification-item--read {
        .rpc-notification-appointment {
            &-address,
            &-date,
            &-comment {
                color: mat.get-color-from-palette($foreground, disabled);
            }
        }
    }
}
