@use '@angular/material' as mat;
@mixin login-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .login {
        .auth {
            &-form {
                &-wrap {
                    .forgot-password {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-terms {
                &-wrap {
                    a {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
}
