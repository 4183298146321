@use '@angular/material' as mat;
@mixin avatar-initials($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .avatar-initials-wrap {
        &--with-hover {
            .hover-wrap {
                background: mat.get-color-from-palette($foreground, text);
                &-icon {
                    color: mat.get-color-from-palette($background, card);
                }
            }
        }
        .text {
            background: mat.get-color-from-palette($foreground, text);
            color: mat.get-color-from-palette($background, card);
        }
    }
}
