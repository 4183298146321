@mixin media-gallery-common() {
    .rpc-mg {
        &-dialog {
            .mat-dialog-container {
                padding: 0;
            }
        }
        &-close {
            .rpc-btn-link {
                padding: 0;
                min-width: 40px;
                border-radius: 50%;
                touch-action: manipulation;
                @include media-breakpoint("gt-sm") {
                    padding: 0 16px;
                    border-radius: 4px;
                }
                &-icon {
                    font-size: 30px;
                }
                &-text {
                    display: none;
                    font-size: 18px;
                    line-height: 22px;
                    margin: 0 !important;
                    @include media-breakpoint("gt-sm") {
                        display: block;
                    }
                }
                .mat-button-wrapper {
                    column-gap: 5px;
                }
            }
        }
        &-slide {
            &-inner {
                .default-image-wrap {
                    width: 100%;
                    height: 100%;
                }
                .main-zoom-img-wrap {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .pz-zoom-button {
                    opacity: 1 !important;
                    transition: background-color .5s cubic-bezier(0.35, 0, 0.25, 1);
                    will-change: background-color;
                }
            }
        }
    }
}
