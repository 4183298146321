.rpc-dropdown {
    &-menu {
        &-item {
            display: flex !important;
            justify-content: flex-start;
            align-items: center;
            .item {
                &-icon {
                    display: block;
                    font-size: 22px;
                }
                &-text {
                    font-size: 14px;
                    line-height: 1.14;
                    margin-left: 15px;
                    transition: color 0.2s ease-in-out;
                }
            }
        }
    }
    &-form {
        max-width: none;
        min-width: 1px;
        width: 280px;
        .mat-menu-content {
            padding: 14px 16px;
        }
    }
}
.cdk-overlay-backdrop {
    pointer-events: all !important;
    opacity: 1 !important;
}