@mixin range-filter-common() {
    .range-filter {
        &-input {
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 11px !important;
                }
                &-subscript-wrapper {
                    margin: 0 !important;
                }
            }
        }
    }
}