@mixin rpc-button-toggle-common() {
    .rpc-button-toggle {
        &:first-of-type {
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
            .mat-button-toggle-button {
                border-top-left-radius: var(--border-radius);
                border-bottom-left-radius: var(--border-radius);
            }
        }
        &:last-of-type {
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
            .mat-button-toggle-button {
                border-right-width: 1px;
                border-right-style: solid;
                border-top-right-radius: var(--border-radius);
                border-bottom-right-radius: var(--border-radius);
            }
            .mat-button-toggle-ripple {
                right: -1px;
            }
        }
        &-wrap {
            --border-radius: 10px;
            border: none !important;
            .mat-button-toggle {
                &-label {
                    &-content {
                        letter-spacing: 0.24px;
                        line-height: 20px;
                    }
                }
                &-checked {
                    .mat-button-toggle-focus-overlay {
                        border: none;
                    }
                }
            }
        }
        .mat-button-toggle-button {
            display: flex;
            justify-content: center;
            align-items: center;
            border-width: 1px;
            border-style: solid;
            height: 40px;
        }
        .mat-button-toggle-ripple {
            top: -1px;
            bottom: -1px;
            left: -1px;
        }
        .mat-button-toggle-focus-overlay {
            border: none;
        }
    }
}
