@mixin new-match-settings-common() {
    .nm {
        &-item {
            &-btn {
                &--icon {
                    .rpc-btn-link {
                        .mat-button-wrapper {
                            flex-direction: row-reverse;
                            column-gap: 10px;
                            .rpc-btn-link-text {
                                margin: 0 !important;
                            }
                        }
                    }
                }
                .rpc-btn-link {
                    padding: 0 6px;
                    @include media-breakpoint("gt-xs") {
                        padding: 0 13px;
                    }
                    &.mat-flat-button {
                        border-width: 1px;
                        border-style: solid;
                    }
                    &-text {
                        font-size: 0.8em;
                    }
                }
            }
            &-box {
                &-select {
                    &--xs {
                        .rpc-select {
                            &.mat-form-field {
                                &.field-outline {
                                    .mat-form-field-flex {
                                        padding: 0 10px !important;
                                    }
                                }
                            }
                        }
                        .mat-form-field {
                            &.mat-form-field-type-mat-select {
                                .mat-form-field-infix {
                                    .mat-select-trigger {
                                        min-width: 40px !important;
                                    }
                                }
                            }
                        }
                    }
                    &--sm {
                        .mat-form-field {
                            &.mat-form-field-type-mat-select {
                                .mat-form-field-infix {
                                    .mat-select-trigger {
                                        min-width: 110px !important;
                                    }
                                }
                            }
                        }
                    }
                    .rpc-select {
                        margin: 5px 0;
                        &.mat-form-field {
                            &.field-outline {
                                .mat-form-field-flex {
                                    padding: 0 5px;
                                }
                            }
                        }
                    }
                    .mat-form-field {
                        &-wrapper {
                            padding-bottom: 0 !important;
                            margin: 0;
                        }
                        &-flex {
                            width: auto;
                        }
                        &-subscript-wrapper {
                            display: none;
                        }
                        &.mat-form-field-type-mat-select {
                            .mat-form-field-infix {
                                .mat-select-trigger {
                                    width: auto;
                                    min-width: 60px;
                                }
                            }
                        }
                    }
                }
                .rpc-btn-link {
                    min-width: 1px;
                    padding: 0 5px;
                    @include media-breakpoint("gt-xs") {
                        padding: 0 8px;
                    }
                }
            }
        }
        &-info-wrap {
            .rpc-info {
                @include media-breakpoint("gt-xs") {
                    padding: 20px;
                }
            }
        }
    }
}
