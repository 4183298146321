@mixin forgot-password-common() {
    .forgot-password {
        .auth {
            &-submit-button {
                .rpc-btn-link {
                    @include media-breakpoint("gt-sm") {
                        width: auto;
                    }
                }
            }
        }
    }
}
