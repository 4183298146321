// Define the primary, accent and warn palettes
$sothebys-theme-primary-palette: mat-palette($sothebys-primary-color);
$sothebys-theme-accent-palette: mat-palette($sothebys-accent-color);
$sothebys-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$sothebys-theme: mat-light-theme($sothebys-theme-primary-palette, $sothebys-theme-accent-palette, $sothebys-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-sothebys {
    // Generate the Angular Material theme
    @include angular-material-theme($sothebys-theme);

    // Apply the theme to the user components
    @include components-theme($sothebys-theme);

}
