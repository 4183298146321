@mixin activity-controls-common() {
    .multi-activity-dropdown {
        max-width: 230px;
    }
    .activities {
        &-wrap {
            &--combined {
                .activities {
                    .rpc-menu-btn-link {
                        width: 100%;
                        padding: 0 8px;
                        &-icon {
                            display: block;
                            font-size: 24px;
                        }
                        &-text {
                            display: block;
                            margin-left: 4px !important;
                            width: calc(100% - 28px);
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        &-badge {
                            right: auto;
                            left: 20px;
                        }
                    }
                }
                &-icon {
                    .activities {
                        .rpc-menu-btn-link {
                            padding: 0;
                            border-radius: 50%;
                            min-width: 1px;
                            width: 40px;
                            &.rpc-menu-btn-link--custom {
                                width: 100%;
                                border-radius: 6px;
                            }
                            &-text {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .activity-controls {
        &-wrapper {
            &--removed {
                .rpc-btn-link {
                    padding: 0 6px;
                    &-icon {
                        & ~ .rpc-btn-link-text {
                            margin-left: .2em;
                        }
                    }
                }
                .rpc-menu-btn-link {
                    padding: 0 6px;
                    &-icon {
                        & ~ .rpc-btn-link-text {
                            margin-left: .2em;
                        }
                    }
                    &-trigger {
                        display: none;
                    }
                }
            }
        }
    }
}
