@use '@angular/material' as mat;
@mixin profile-info-base($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .profile {
        &-item {
            &-hint {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-list {
            &--email {
                .profile-list {
                    &-text,
                    &-item {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-label {
                        background-color: mat.get-color-from-palette($accent, default);
                        color: mat.get-color-from-palette($background, card);
                    }
                }
            }
        }
    }
}
