@mixin avatar-common() {
    .avatar {
        .mat-form-field {
            display:block;
            width:100%;
            height:100%;
            &-wrapper {
                display:block;
                height:100%;
                width:100%;
                padding: 0 !important;
                ngx-mat-file-input {
                    display: none !important;
                }
            }
            &-flex{
                display:block;
                height:100%;
                width:100%;
            }
            &-infix {
                display:block;
                height:100%;
                width:100%;
                padding: 0 !important;
                border: none !important;
            }
            &-label {
                &-wrapper {
                    padding: 0!important;
                    top: 0px!important;
                    left: 0px;
                    right: 0px;
                    bottom:0px;
                    width: auto;
                    height: auto;
                    border-radius: 50%;
                }
            }
            &-underline {
                display: none;
            }
            &-subscript-wrapper {
                display: none;
            }
        }
        .avatar-initials-wrap{
            .main-image,
            .text{
                width:100%;
                height:100%;
                font-size:32px;
                line-height: 2.5em;
            }
        }
    }
}
