@mixin user-activity-history-tooltip-common() {
    .contributor {
        &-menu {
            &-wrap {
                &.mat-menu-panel {
                    max-width: 250px;
                    overflow-x: hidden;
                }
            }
            &-avatar {
                .avatar-initials-wrap {
                    .text,
                    .main-image {
                        font-size: inherit;
                        width: 2em;
                        height: 2em;
                        line-height: 2em;
                    }
                }
            }
        }
    }
}