// Fix: "Remove the cdk-global-scrollblock effects from the HTML"
// We are disabling it
// because on Windows, it causes a scrollbar to show up.
html {
    &.cdk-global-scrollblock {
        position: relative !important;
        overflow: hidden !important;
    }
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    body {
        transform: translateZ(0);
        backface-visibility: hidden;
        perspective: 1000;
    }
}

// Fix: "Inconsistent button heights due to inconsistent line heights"
button {
    .mat-button-wrapper {
        line-height: normal;
    }
}

// Fix: "Icon button ripple radius is not correct on Edge & Safari"
.mat-icon-button {
    .mat-button-ripple {
        border-radius: 50%;
    }
}

// Fix: "Smooth scrolling for iOS"
.mat-dialog-container {
    -webkit-overflow-scrolling: touch;
}

// Fix: "Inconsistent font sizes across elements"
.mat-checkbox {
    font-size: 16px;
}

.mat-radio-button {
    font-size: 16px;
}

// Fix: "Input underlines has wrong color opacity value"
.mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.12);
}

.mat-form-field {
    // Fix: "Table-cell and inline-table in mat-select"
    &.mat-form-field-type-mat-select {
        .mat-form-field-infix {
            display: inline-flex;
            width: auto;

            .mat-select-trigger {
                display: inline-flex;
                align-items: center;
                width: 100%;

                .mat-select-value {
                    display: flex;
                    max-width: none;
                    margin-right: 8px;
                }

                .mat-select-arrow-wrapper {
                    display: inline-flex;
                }
            }
        }
    }
}
