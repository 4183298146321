@mixin single-activity-controls() {
    .activities {
        &-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-wrap {
                min-height: 40px;
            }
            &-item {
                &-divider {
                    height: 26px;
                }
            }
        }
    }
}