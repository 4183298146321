@mixin important-dialog-common() {
    .important {
        &-dialog {
            &.rpc-pick-list-confirm {
                max-width: 320px !important;
                width: 100%;
                @include media-breakpoint("gt-xs") {
                    max-width: none !important;
                    width: 470px;
                }
                .mat-dialog-container {
                    padding: 15px 0 0;
                    @include media-breakpoint("gt-sm") {
                        padding: 25px 0 0;
                    }
                }
            }
            .confirm {
                &-btn {
                    &:not(:only-of-type) {
                        &.confirm-btn {
                            &--submit {
                                .rpc-btn-link {
                                    border-bottom-left-radius: 0 !important;
                                }
                            }
                            &--cancel {
                                .rpc-btn-link {
                                    border-bottom-right-radius: 0 !important;
                                }
                            }
                        }
                    }
                    .rpc-btn-link {
                        width: 100%;
                        border-top-left-radius: 0 !important;
                        border-top-right-radius: 0 !important;
                        &-text {
                            text-transform: uppercase;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
