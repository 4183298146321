@use "@angular/material" as mat;
@mixin activity-button($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-activity-button {
        .rpc-icon-btn--badge-icon {
            .rpc-icon-btn-badge {
                color: var(--visit-color);
            }
        }
    }
}