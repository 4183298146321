@use '@angular/material' as mat;
@import "src/app/modules/map/components/price-marker/price-marker.theme";
@import "src/app/modules/map/components/map-cluster/map-cluster.theme";
@import "src/app/modules/map/components/neighborhood-tooltip/neighborhood-tooltip.theme";
@import "src/app/modules/map/components/map-controls/map-controls.theme";

@mixin map-theme-mixin($theme){
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    @include price-marker($theme);
    @include map-cluster($theme);
    @include neighborhood-tooltip($theme);
    @include map-controls($theme);

    .cm-btn {
        border-color: mat.get-color-from-palette($foreground, divider);
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover,
        &.active {
            color: mat.get-color-from-palette($accent, default);
        }
        &:active {
            border-color: mat.get-color-from-palette($accent, default);
        }
        &--rectangle,
        &--circle {
            &:after {
                border-color: currentColor;
            }
        }
        &--delete {
            color: mat.get-color-from-palette($warn, default);
        }
    }
    .gm-style {
        .gm-style-iw {
            &-d {
                & + button {
                    background-color: mat.get-color-from-palette($primary, default-contrast) !important;
                    color: mat.get-color-from-palette($primary, default) !important;
                    filter: drop-shadow(0 4px 2px rgba(178,178,178,.4));
                    &:hover {
                        background-color: mat.get-color-from-palette($primary, default) !important;
                        color: mat.get-color-from-palette($primary, default-contrast) !important;
                    }
                }
            }
            &-tc {
                &:after {
                    background: #fff !important;
                }
            }
        }
    }
    .gm-style {
        &.poi-info-window {
            background-color: #fff;
            div,
            a {
                color: rgba(0,0,0,.87);
                background-color: #fff;
            }
        }
        .gm-style-iw-c {
            background-color: #fff;
        }
        .info-window-wrap {
            .search-results-view-gallery {
                background-color: mat.get-color-from-palette($background, card);
            }
        }
    }
}
