@use '@angular/material' as mat;
@mixin on-market($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .sr {
        &-empty {
            color: mat.get-color-from-palette($foreground, secondary-text);
            --secondary: #{mat.get-color-from-palette($foreground, divider)};
            --bg: #{mat.get-color-from-palette($background, card)};
            &-icon {
                color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
