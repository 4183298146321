@mixin rpc-range-slider-common() {
    .range-slider {
        &-wrap {
            .mat-slider {
                &-thumb-label {
                    width: 50px;
                    height: 28px;
                    border-radius: 5px;
                }
                &-horizontal {
                    .mat-slider {
                        &-wrapper {
                            top: 19px;
                        }
                        &-thumb-label {
                            right: -25px;
                            top: -40px;
                            transform: scale(0.01);
                            &-text {
                                transform: none;
                            }
                        }
                    }
                }
                &:not(.mat-slider-disabled) {
                    &.cdk-focused {
                        &.mat-slider-thumb-label-showing {
                            .mat-slider-thumb {
                                transform: scale(1);
                            }
                        }
                        .mat-slider-thumb-label {
                            border-radius: 5px;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
        &--min {
            .mat-slider {
                &-ticks-container,
                &--track-wrapper {
                    display: none !important;
                }
                &-thumb-container {
                    z-index: 3;
                }
            }
        }
        &--max {
            .mat-slider-track-wrapper {
                .mat-slider-track {
                    &-fill {
                        transform: none !important;
                        z-index: 2;
                        /* in order for the filltrack not to disappear */
                        display: block !important;
                    }
                    &-background {
                        transform: none !important;
                        z-index: 1;
                    }
                }
            }
        }
    }
}
