@use '@angular/material' as mat;
@mixin folder-management-dialog($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .f-management {
        &-box {
            &-title {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}