@mixin rpc-button-common() {
    .rpc-btn-link {
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        &:hover {
            .mat-button-focus-overlay {
                opacity: 0 !important;
            }
        }
        .mat-button {
            &-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
