@mixin saved-search-info-common() {
    .saved-search-info {
        &-dropdown {
            &.mat-menu-panel {
                @include media-breakpoint("gt-xs") {
                    max-width: 500px;
                }
            }
        }
        &-divider {
            @include media-breakpoint("gt-xs") {
                margin: 0 20px;
            }
        }
    }
}
