@use '@angular/material' as mat;
@mixin folder-details-item($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .folder {
        &-creator {
            &-note,
            &-date {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-saved-search {
            &--multiple {
                &:hover {
                    .folder-saved-search {
                        &-title {
                            color: mat.get-color-from-palette($foreground, text);
                        }
                        &-name {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
            &-title {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-icon {
                color: mat.get-color-from-palette($accent, default);
            }
            &-trigger {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-default-toggle {
            &-note {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}