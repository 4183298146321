@use '@angular/material' as mat;
@mixin avatars-icon($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .custom-avatar {
        &--front {
            &:after {
                border-color: mat.get-color-from-palette($background, card);
            }
        }
        &-icon {
            color: mat.get-color-from-palette($accent, default);
            &-wrap {
                background-color: mat.get-color-from-palette($background, card);
            }
        }
    }
}