@mixin carousel-common() {
    .rpc-carousel {
        &-btn {
            .rpc-btn-link {
                padding: 0;
                min-width: 40px;
                border-radius: 50%;
                transition:
                    border-color .5s cubic-bezier(0.35, 0, 0.25, 1),
                    background-color .5s cubic-bezier(0.35, 0, 0.25, 1);
                will-change: border-color, background-color;
                &-icon {
                    font-size: 20px;
                    transition: color .5s cubic-bezier(0.35, 0, 0.25, 1);
                    will-change: color;
                }
            }
        }
    }
}