@use '@angular/material' as mat;
@mixin profile-agent-info-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .pai {
        &-name {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-company,
        &-title,
        &-info-text {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-info {
            &:hover {
                .pai-info-text {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
        }
    }
}