@use '@angular/material' as mat;
@mixin remove-listing-dialog($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);
    .remove-listing {
        &-wrap {
            .rpc-warning {
                &-block {
                    border-color: mat.get-color-from-palette($warn, default);
                    background-color: mat.get-color-from-palette($warn, default, .1);
                    color: mat.get-color-from-palette($warn, default);
                }
                &-link {
                    color: mat.get-color-from-palette($warn, default);
                    &:hover {
                        color: mat.get-color-from-palette($warn, default);
                    }
                }
            }
        }
    }
}
