@mixin internal-listings-common() {
    .secondary-toolbar-container--minimized {
        .listings {
            &-sided {
                &-map-box {
                    @include media-breakpoint('gt-sm') {
                        top: 5px;
                    }
                }
            }
        }
    }

    .listings {
        &-wrap {
            &--at-bottom {
                &:not(.map-view) {
                    .listings-sided {
                        &--with-map {
                            .as-split-gutter {
                                bottom: 143px;
                                top: 0;
                                margin: auto 0;
                            }
                        }
                    }
                }
            }
            .cm-btn {
                &--fullscreen {
                    @include media-breakpoint("gt-xs") {
                        right: auto !important;
                        left: 0 !important;
                    }
                    @include media-breakpoint("gt-sm") {
                        right: 0 !important;
                        left: auto !important;
                    }
                }
            }
        }
        &-sided {
            &-btn {
                .rpc-btn-link {
                    padding: 0 10px;
                    min-width: 42px;
                    height: 42px;
                    line-height: 42px;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    transition: box-shadow 0.2s ease-in-out;
                }
            }
            &-map-wrap {
                height: auto !important;
                @include media-breakpoint("xs") {
                    display: none;
                }
            }
            .as-split-gutter {
                position: absolute;
                display: flex;
                justify-content: flex-end !important;
                align-items: center;
                opacity: 0;
                top: 0;
                left: 50%;
                width: 30px;
                bottom: 30px;
                height: auto !important;
                padding-right: 2px;
                z-index: 10;
                transition: bottom 0.2s ease-in-out,
                            top 0.2s ease-in-out;
                &-icon {
                    display: block;
                    width: 6px !important;
                    height: 120px !important;
                    border-radius: 3px;
                    background-image: none !important;
                }
            }
        }
    }
}
