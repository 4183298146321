/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/

@media print {
    @page {
        size: auto letter;
        margin: 10mm;
    }
    /* html and body tweaks */
    html {
        position: static;
        height: 100% !important;
        overflow-y: auto !important;
        background: #fff !important;
    }
    body {
        position: static;
        height: auto !important;
        overflow: initial !important;
        background: #fff !important;
    }

    /* General styles */
    .mp-splash,
    .overlay-container,
    .cdk-overlay-container {
        display: none !important;
    }
    app {
        position: static !important;
        display: block !important;
        height: auto !important;
        progress-bar,
        paginator,
        .scroll-to-top-wrap,
        .mobile-menu,
        .theme-options-sidebar,
        .theme-options-button,
        .refresher,
        .multi-select--desktop,
        .multi-select--mobile,
        .multi-selection--route,
        .single-select--mobile,
        .as-split-gutter {
            display: none !important;
        }
        .default-image-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            &.default-background-image {
                img {
                    display: block !important;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                .main-background-image {
                    display: none !important;
                }
            }
        }
        .slider {
            &-wrapper {
                &--thumb {
                    grid-template-rows: 1fr !important;
                }
                .main-background-image,
                .broken-background-image {
                    display: none !important;
                }
            }
            &-btn,
            &-dots,
            &-thumb-wrap {
                display: none !important;
            }
            &-item {
                &:after {
                    display: none !important;
                }
            }
        }
        .toolbar {
            border-bottom: none !important;
            height: 40px !important;
            &-wrap {
                position: static !important;
            }
            &-secondary {
                display: none !important;
            }
            &-box {
                display: flex;
                justify-content: center;
                align-items: center;
                & > * {
                    &:not(.toolbar-logo) {
                        display: none !important;
                    }
                }
            }
            &-logo {
                width: 170px;
            }
        }
        .global {
            &-content {
                min-height: auto !important;
                overflow-y: initial !important;
                &--print {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 10px;
                    text-align: center;
                    color: #000;
                    font-size: 20px;
                }
                &-wrap {
                    position: static !important;
                    height: auto !important;
                    margin: 0 !important;
                }
                // my-listings,
                app-profile,
                login,
                forgot-password,
                restore-password,
                registration,
                my-appointments,
                external-listings,
                agent-selection,
                .folders-add-btn {
                    display: none !important;
                }
                terms-and-conditions,
                folders-view,
                listing-details,
                internal-listings,
                shared-listing-details,
                my-listings,
                on-market,
                mortgage-report {
                    & ~ .global-content--print {
                        display: none !important;
                    }
                }
            }
        }
        .folders-item,
        .listings-gallery-item,
        .rpc-ld-body-transportation,
        .rpc-ld-body-map,
        .rpc-ld-body-b-features,
        .rpc-ld-body-u-features {
            page-break-inside: avoid;
        }
        .portfolio-page-container-title-action {
            display: none;
        }
        .folder {
            border-width: 1px;
            border-style: solid;
            page-break-inside: avoid;
        }
        .rpc-ld-body-appointments,
        .rpc-ld-body-comments {
            page-break-before: always;
        }
        .listings-gallery {
            grid-template-columns: repeat(auto-fill,minmax(300px,1fr)) !important;
            padding-bottom: 560px !important;
            margin-bottom: 0 !important;
        }
        .listings-sided-map-wrap {
            overflow: hidden !important;
            width: 100% !important;
            height: 600px !important;
        }
        .folders-gallery {
            padding-bottom: 300px !important;
            margin-bottom: 0 !important;
        }
        .footer-wrap {
            height: auto !important;
            background: #fff !important;
            & > div {
                width: auto !important;
                margin-top: 3px !important;
                padding: 0 10px;
            }
        }

        /* Listing Details */
        .rpc-ld {
            &-page {
                display: block !important;
                padding-bottom: 0 !important;
                .single-select--mobile,
                .scroll-to-top-wrap,
                .multi-select--desktop,
                .multi-select--mobile {
                    display: none !important;
                }
            }
            &-header,
            &-media {
                padding-bottom: 20px !important;
            }
            &-body {
                display: block !important;
                padding-bottom: 100px !important;
                &-header,
                &-top--single,
                &-item,
                &-map {
                    padding-bottom: 20px !important;
                }
                & > .mat-divider {
                    padding-bottom: 20px;
                }
            }
        }
        /* End Listing Details */

        .listings {
            &-wrap {
                min-height: auto !important;
                &.summary-view {
                    .listings {
                        &-box {
                            position: relative;
                            padding: 50px 0;
                            .listings-box--print {
                                position: absolute;
                                display: flex !important;
                                justify-content: center;
                                align-items: center;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                text-align: center;
                                color: #000;
                                font-size: 20px;
                            }
                        }
                        &-sided {
                            display: none !important;
                        }
                    }
                }
                &.map-view {
                    .listings {
                        &-map {
                            position: static !important;
                            height: 450px !important;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            &-title {
                font-size: 16px !important;
                line-height: 16px !important;
                padding: 5px 0 !important;
            }
            &-sided {
                &-block {
                    display: block !important;
                    height: auto !important;
                }
                &-map {
                    &-wrap {
                        display: none;
                    }
                }
                &-inner {
                    height: auto !important;
                    overflow: visible !important;
                }
            }
        }
        .map {
            &-view-wrap {
                page-break-inside: avoid;
                .gm-style .gm-style-iw-c {
                    display: none !important;
                }
            }
            &-wrap {
                page-break-inside: avoid;
            }
            &-marker-price {
                & > div {
                    color: #000 !important;
                }
            }
        }
        .gallery-card {
            page-break-inside: avoid;
            &-img-wrap,
            &-body {
                page-break-inside: avoid;
            }
            &-img {
                &-wrap {
                    height: 160px !important;
                }
                &-expand {
                    display: none !important;
                }
            }
            &--extended {
                .gallery-card {
                    &-img-wrap {
                        height: 110px !important;
                    }
                    &-body {
                        height: 230px !important;
                    }
                }
            }
        }
        .external {
            &-card {
                page-break-inside: avoid;
                &-img-wrap,
                &-body {
                    page-break-inside: avoid;
                }
                &-empty {
                    height: 340px !important;
                }
                &-empty-box {
                    padding: 13px !important;
                }
                &-img-wrap {
                    height: 140px !important;
                }
                &--extended {
                    .external-card {
                        &-img-wrap {
                            height: 120px !important;
                        }
                    }
                }
            }
            &-listings {
                &--add {
                    display: none !important;
                }
            }
        }

        /* Mortgage Report */
        .mr {
            &-wrap {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                max-width: none !important;
                margin: 20px auto !important;
            }
            &-form {
                &-wrap {
                    flex: 0 0 50%;
                    padding: 20px 15px !important;
                }
            }
            &-summary {
                &-wrap {
                    flex: 0 0 50%;
                }
            }
        }
        .mc {
            &-summary {
                padding-bottom: 40px !important;
            }
            &-toggler {
                .mat-slide-toggle {
                    .mat-slide-toggle {
                        &-bar,
                        &-thumb {
                            border: 1px solid rgba(0,0,0,.3);
                        }
                        &-thumb-container {
                            top: -4px;
                        }
                    }
                }
            }
            &-form {
                rpc-input {
                    .mat-form-field-appearance-fill {
                        .mat-form-field {
                            &-wrapper {
                                padding-bottom: 15px;
                            }
                            &-flex {
                                border-radius: 4px;
                                border: 1px solid rgba(0,0,0,.3);
                            }
                        }
                    }
                }
            }
        }
    }
}
