// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$cbwarburg-theme-primary-palette: mat.define-palette($cbwarburg-accent-color);
$cbwarburg-theme-accent-palette: mat.define-palette($cbwarburg-accent-color);
$cbwarburg-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$cbwarburg-theme: mat.define-light-theme(
    $cbwarburg-theme-primary-palette,
    $cbwarburg-theme-accent-palette,
    $cbwarburg-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-cbwarburg {
    // Generate the Angular Material theme
    @include mat.all-component-themes($cbwarburg-theme);

    // Apply the theme to the user components
    @include components-theme($cbwarburg-theme);

}
