@use '@angular/material' as mat;
@mixin folder-attachment-dialog($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .fad {
        &-item {
            .rpc-btn-link {
                &:not(.mat-button-disabled) {
                    &.active,
                    &:hover {
                        .rpc-btn-link-text {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                    .rpc-btn-link-text {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
        }
    }
}