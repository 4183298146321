@mixin password-common() {
    .change-password {
        &-item {
            .mat-form-field-wrapper {
                padding-bottom: 12px !important;
                margin : 0 !important;
            }
        }
    }
}
