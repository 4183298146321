@use '@angular/material' as mat;
@mixin notification-group($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-notification-group {
        background-color: rgba(0,0,0,0);
        &--opened {
            background-color: mat.get-color-from-palette($background, background);
        }
        &--read {
            .rpc-notification-group {
                &-trigger,
                &-title,
                &-date {
                    color: mat.get-color-from-palette($foreground, disabled);
                }
            }
        }
        &-icon {
            color: mat.get-color-from-palette($foreground, secondary-text);
            &.picklisted {
                color: var(--picklist-color);
            }
            &.liked {
                color: var(--like-color);
            }
            &.disliked {
                color: var(--dislike-color);
            }
            &.shown {
                color: var(--visit-color);
            }
        }
        &-address {
            &:hover {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-title {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-date {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-block {
            &--read {
                .rpc-notification-group-block {
                    &-name,
                    &-activity-text {
                        color: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
            &-activity {
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    &.picklisted {
                        color: var(--picklist-color);
                    }
                    &.liked {
                        color: var(--like-color);
                    }
                    &.disliked {
                        color: var(--dislike-color);
                    }
                    &.shown {
                        color: var(--visit-color);
                    }
                }
            }
        }
        &-list-item {
            &--read {
                .rpc-notification-group {
                    &-address,
                    &-date {
                        color: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
        }
    }
}
