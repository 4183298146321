@use '@angular/material' as mat;
@mixin landing-section-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .landing-section {
        &-block {
            .mp-splash-inner {
                .mp-splash-backdrop {
                    @if ($is-dark) {
                        background: mat.get-color-from-palette($background, background);
                    } @else {
                        background: #f3f3f3;
                    }
                }
            }
        }
    }
}