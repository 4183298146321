@mixin toolbar-filter-base-common() {

    .filter {
        &-menu {
            &--main {
                .filter {
                    &-item {
                        &--ellipsis {
                            .rpc-menu-btn-link {
                                &.active {
                                    .rpc-menu-btn-link-text {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        max-width: 110px;
                                    }
                                }
                            }
                        }
                        .rpc {
                            &-btn-link {
                                -webkit-transform: none;
                            }
                            &-btn-link,
                            &-menu-btn-link {
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
            &-mobile {
                .rpc-btn-link {
                    padding: 0;
                    min-width: 40px;
                    width: 40px;
                    &-text {
                        display: none;
                    }
                }
            }
            &-item {
                &:not(:last-of-type) {
                    .filter {
                        &-checkbox-box,
                        &-range-box,
                        &-omni-box {
                            padding-bottom: 10px;
                        }
                    }
                }
                &--omni {
                    padding-top: 15px !important;
                    .chip {
                        &-wrap {
                            width: 100%;
                            height: 116px;
                            .chip-list-wrap {
                                height: 114px;
                            }
                        }
                        &-icon {
                            display: none;
                        }
                    }
                    .chip-wrap--focused .mat-form-field-flex {
                        bottom: 0 !important;
                    }
                }
                &--main {
                    .chip-wrap {
                        width: 100%;
                    }
                }
                &--toggle-wrap {
                    .rpc-button-toggle {
                        width: 50%;
                        &-wrap {
                            display: flex;
                        }
                    }
                }
                &--btns {
                    .rpc-btn-link {
                        width: 100%;
                        padding: 0 7px;
                        &-icon {
                            flex: 0 0 26px;
                        }
                        &-text {
                            flex: 0 1 100%;
                            text-align: left;
                        }
                        .mat-button-wrapper {
                            justify-content: flex-start;
                        }
                    }
                    .filter-item {
                        &.declined {
                            .rpc-btn-link {
                                padding: 0 8px 0 4px;
                                @include media-breakpoint('gt-xs') {
                                    padding: 0 10px;
                                }
                            }
                        }
                        &.declined,
                        &.passed {
                            .mat-button-wrapper {
                                justify-content: flex-start;
                            }
                            .rpc-btn-link {
                                &-icon {
                                    flex: none;
                                    @include media-breakpoint('gt-xs') {
                                        flex: 0 0 26px;
                                    }
                                }
                                &-text {
                                    flex: none;
                                    @include media-breakpoint('gt-xs') {
                                        flex: 0 1 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                &--toggler {
                    .filter-menu-item-title {
                        .toggler {
                            &-icon {
                                position: absolute;
                                display: block;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                font-size: 20px;
                            }
                        }
                    }
                }
                &-title {
                    &-user {
                        .rpc-menu-btn-link-icon {
                            display: block;
                            font-size: 32px;
                        }
                    }
                }
                .rpc-date-wrap {
                    display: block;
                }
                .cf-wrap--chips {
                    padding: 10px 0;
                }
            }
            &-box {
                &--mobile {
                    html[data-device*="ios"] & {
                        padding-bottom: 105px;
                    }
                    .filter-menu-item--btns-box {
                        .filter-item {
                            .rpc-btn-link {
                                padding-inline: 5px;
                            }
                        }
                    }
                }
            }
        }
        &-item {
            .rpc-btn-link,
            .rpc-menu-btn-link {
                &-text {
                    font-weight: 500;
                }
            }
            .mat-button-toggle {
                &-label-content {
                    font-weight: 500;
                }
            }
            &--clear {
                .rpc-btn-link {
                    min-width: 40px;
                    padding: 0 6px;
                    &-text {
                        display: none;
                    }
                }
            }
        }
        &-dropdown {
            &:not(mat-menu) {
                max-width: 410px !important;
                width: 410px;
                margin: 10px 0;
                border-width: 1px;
                border-style: solid;
                max-height: 85vh;
                &.filter-dropdown--checkboxes-list {
                    width: auto;
                    max-width: 310px !important;
                    @include media-breakpoint('gt-xs') {
                        max-width: 400px !important;
                    }
                }
                &.filter-dropdown--saved-search {
                    max-width: 310px !important;
                    width: 310px;
                    overflow-y: hidden;
                    .mat-menu-content {
                        height: 100%;
                        & > div {
                            height: 100%;
                        }
                    }
                }
            }
            &--mobile {
                padding: 0;
                max-height: 88vh !important;
                overflow: hidden;
                .filter {
                    &-menu {
                        &-wrap {
                            display: grid;
                            grid-template-columns: 100%;
                            grid-template-rows: 50px 1px minmax(0, 1fr);
                            align-items: start;
                            height: 100%;
                            &--with-absolute {
                                position: relative;
                            }
                            .rpc-date-wrap {
                                .mat-form-field {
                                    &.field-outline {
                                        .mat-form-field {
                                            &-infix {
                                                padding-left: 0 !important;
                                            }
                                            &-outline-start {
                                                flex: 0 0 5px;
                                                min-width: 5px;
                                            }
                                            &-flex {
                                                padding: 0 5px;
                                            }
                                            &-label {
                                                mat-label {
                                                    padding-left: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &-title {
                            &-wrap {
                                position: relative;
                                justify-content: center;
                                flex: 0 0 auto;
                                padding-right: 40px;
                            }
                            &-icon--close {
                                position: absolute;
                                display: block;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                                z-index: 5;
                            }
                        }
                        &-item {
                            &--omni {
                                padding-top: 0 !important;
                            }
                            &--clear {
                                position: absolute;
                                bottom: 100px;
                                right: 15px;
                                z-index: 10;
                                @media screen and (orientation: portrait) {
                                    [data-device*="android"] & {
                                        bottom: 120px;
                                    }
                                    [data-device*="ios"] & {
                                        bottom: 100px;
                                    }
                                    [data-device*="ios,safari"] & {
                                        bottom: 160px;
                                    }
                                }
                                .rpc-btn-link {
                                    padding: 0 10px 0 5px;
                                    box-shadow: 0px 3px 20px 0 rgb(0 0 0 / 10%) !important;
                                    border-radius: 10px;
                                    &-text {
                                        font-size: 0.8em;
                                        letter-spacing: 0.24px;
                                        margin-left: 0.2em;
                                    }
                                    &-trigger {
                                        display: none;
                                    }
                                }
                            }
                        }
                        &-box {
                            flex: 0 1 100%;
                            max-height: none;
                            padding-inline: 10px;
                            overflow-x: hidden;
                        }
                    }
                }
            }
            .mat-menu-content {
                padding: 0;
            }
        }
        &-messages-box {
            .filter-item {
                .rpc-btn-link {
                    width: 100%;
                    padding: 0 7px;
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}
