@use '@angular/material' as mat;
@mixin scrollbars-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    &:not(.is-mobile) {
        ::-webkit-scrollbar {
            &:hover {
                background-color: mat.get-color-from-palette($foreground, divider);
            }
            &-thumb {
                box-shadow: inset 0 0 0 12px mat.get-color-from-palette($foreground, disabled);
                &:active {
                    box-shadow: inset 0 0 0 12px mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
        .scroll {
            &--accent {
                &::-webkit-scrollbar {
                    &-thumb {
                        box-shadow: inset 0 0 0 12px mat.get-color-from-palette($accent, default);
                        &:hover,
                        &:active {
                            box-shadow: inset 0 0 0 12px mat.get-color-from-palette($accent, default);
                        }
                    }
                }
            }
            &--primary {
                &::-webkit-scrollbar {
                    &-thumb {
                        box-shadow: inset 0 0 0 12px mat.get-color-from-palette($primary, default);
                        &:hover,
                        &:active {
                            box-shadow: inset 0 0 0 12px mat.get-color-from-palette($primary, default);
                        }
                    }
                }
            }
        }
    }
}

body:not(.is-mobile) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
        &-thumb {
            border: 2px solid transparent;
            border-radius: 12px;
            &:active {
                box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
                border-radius: 12px;
            }
        }
    }
}
