@use '@angular/material' as mat;
@mixin landing-banner-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .landing-banner {
        border-color: mat.get-color-from-palette($foreground, divider);
        background-color: mat.get-color-from-palette($background , card);
        &-info {
            &-subtitle {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}