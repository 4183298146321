@mixin multi-activity-compact-controls() {
    .activity-modal {
        &-wrap {
            &.mat-bottom-sheet-container {
                border-radius: 10px;
                min-width: 300px;
                max-width: 300px;
                padding: 8px 0;
                margin-bottom: 15px;
            }
        }
    }
}