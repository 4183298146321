@use '@angular/material' as mat;
@mixin registration-form-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .register-password-form-terms {
        a {
            color: mat.get-color-from-palette($foreground, secondary-text);
            text-decoration: underline;
            &:hover {
                text-decoration: none !important;
            }
        }
    }
}