@use '@angular/material' as mat;
@mixin notification-checkbox($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-notification-checkbox-btn {
        color: rgba(0,0,0,0);
        background-color: transparent;
        &:hover {
            .rpc-notification-checkbox-btn {
                &-bg {
                    border-color: mat.get-color-from-palette($accent, 50);
                    background-color: mat.get-color-from-palette($accent, 50);
                }
            }
        }
        &.rpc-notification-checkbox-btn--checked {
            color: mat.get-color-from-palette($accent, 600);
            &:hover {
                .rpc-notification-checkbox-btn {
                    &-bg {
                        border-color: mat.get-color-from-palette($accent, 200);
                        background-color: mat.get-color-from-palette($accent, 200);
                    }
                }
            }
            .rpc-notification-checkbox-btn {
                &-bg {
                    border-color: mat.get-color-from-palette($accent, 50);
                    background-color: mat.get-color-from-palette($accent, 50);
                }
            }
        }
        &-bg {
            background-color: rgba(0,0,0,0);
            border-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}
