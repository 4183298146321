@use '@angular/material' as mat;
@mixin toast($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);
    
    .rpc-toast {
        background-color: #333e57;
        color: #fff;
        box-shadow: 20px 10px 40px 0 rgba(0, 0, 0, 0.12);
        &-close {
            .rpc-icon-btn {
                .rpc-icon-btn {
                    &-icon {
                        color: rgba(255, 255,255,1);
                        &:hover {
                            color: rgba(255,255,255,.7);
                        }
                    }
                }
            }
        }
    }
}