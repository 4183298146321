@mixin price-marker-common() {
    .map-marker {
        &-wrap {
            &--listing {
                position: absolute;
                z-index: 5;
                &:hover {
                    z-index: 7;
                }
                &-active {
                    z-index: 7;
                }
            }
            &--place {
                position: absolute;
                z-index: 2;
            }
        }
        &-icon {
            &--simple {
                font-size: 48px;
            }
        }
        &-dropdown {
            &.tooltip-list {
                padding: 8px 10px;
                max-height: 40vh;
            }
            &-title {
                font-size: 16px;
            }
            &-list {
                list-style: none;
                margin: 5px 0 0;
                padding: 0;
                li {
                    font-size: 14px;
                    padding: 4px 0;
                }
            }
        }
    }
}
