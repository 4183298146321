@use '@angular/material' as mat;
@mixin card-appointment($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .apnt-t-appointment {
        &-wrap {
            background-color: transparent;
            &--new {
                background-color: mat.get-color-from-palette($accent, 50);
            }
        }
        &-date {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-name {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-info {
            &-icon-wrap {
                color: mat.get-color-from-palette($foreground, text);
                border-color: currentColor;
            }
        }
        &-status {
            &-wrap {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &--confirmed {
                color: var(--status-confirmed);
                --main-color: var(--status-confirmed);
            }
            &--declined {
                color: var(--status-declined);
                --main-color: var(--status-declined);
            }
            &--shown {
                color: var(--status-shown);
                --main-color: var(--status-shown);
            }
        }
        &-update {
            &--accept {
                .rpc-btn-link {
                    background-color: var(--status-confirmed) !important;
                    &-text,
                    &-icon {
                        color: mat.get-color-from-palette($background, card) !important;
                    }
                }
            }
            &--decline {
                .rpc-btn-link {
                    background-color: var(--status-declined) !important;
                    &-text,
                    &-icon {
                        color: mat.get-contrast-color-from-palette($warn, 600) !important;
                    }
                }
            }
        }
    }
}
