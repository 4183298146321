@mixin my-appointments-common() {
    .apnt {
        &-wrap {
            .agent-contacts-popover {
                .agent-contact-wrap {
                    border-radius: 16px;
                    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
                }
            }
            .multi-select--mobile {
                &.multi-select--mobile-pure {
                    &.multi-select--mobile-restore {
                        .multi-selection--active {
                            grid-template-columns: 150px calc(100% - 286px) 40px 40px 40px;
                            grid-template-areas: "checkbox . folder activities restore";
                        }
                    }
                    .multi-selection--active {
                        grid-template-columns: 150px calc(100% - 242px) 40px 40px;
                        grid-template-areas: "checkbox . folder activities";
                    }
                }
                &.multi-select--mobile-restore {
                    .multi-selection--active {
                        grid-template-columns: 145px calc(100% - 305px) 40px 40px 40px 40px;
                        grid-template-areas: "checkbox . folder activities delete restore";
                        column-gap: 0;
                    }
                }
            }
        }
    }
}
