@mixin profile-top-common() {
    .customer {
        &-info {
            &-form {
                &-input {
                    .rpc-input {
                        margin-bottom: 20px;
                        .mat-form-field {
                            &-wrapper {
                                padding-bottom: 0;
                            }
                            &-flex {
                                padding: 0 12px;
                            }
                            &-infix {
                                padding: 0.25em 0 0.75em !important;
                            }
                            &-subscript-wrapper {
                                margin: 0 !important;
                            }
                        }
                    }
                }
                &-select {
                    .rpc-select {
                        margin-bottom: 20px;
                        .mat-form-field {
                            &-wrapper {
                                padding-bottom: 0;
                            }
                            &-flex {
                                padding: 0 6px !important;
                            }
                            &-infix {
                                padding: 0.25em 0 0.75em !important;
                            }
                            &-subscript-wrapper {
                                margin: 0 !important;
                            }
                        }
                    }
                }
                &-submit {
                    .rpc-btn-link {
                        width: 100%;
                        &-text {
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
        &-item {
            .item {
                &-btn {
                    .rpc-icon-btn {
                        width: 34px;
                        height: 34px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}
