@use "@angular/material" as mat;
@mixin profile($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .profile {
        &-customer {
            &-wrap {
                background: mat.get-color-from-palette($background, card);
                border-color: mat.get-color-from-palette($foreground, divider);
            }
            &-bg {
                background: mat.get-color-from-palette($accent, default);
            }
        }
        &-agent {
            &-box {
                background: mat.get-color-from-palette($background, card);
                border-color: mat.get-color-from-palette($foreground, divider);
            }
            &-inner {
                .pal {
                    &-item {
                        &:not(:first-of-type) {
                            &:before {
                                border-left-color: mat.get-color-from-palette($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
        &-content {
            &-box {
                background: mat.get-color-from-palette($background, card);
                border-color: mat.get-color-from-palette($foreground, divider);
            }
        }
        &-col {
            border-color: mat.get-color-from-palette($foreground, divider);
            &-title {
                border-bottom-color: mat.get-color-from-palette($foreground, divider);
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-box {
                &-title {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                .dark-toggle {
                    &-box {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
}
