@use '@angular/material' as mat;
@mixin not-found-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    .error{
        &-info{
            color:mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}