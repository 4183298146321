// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$elliman-theme-primary-palette: mat.define-palette($elliman-accent-color);
$elliman-theme-accent-palette: mat.define-palette($elliman-accent-color);
$elliman-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$elliman-theme: mat.define-light-theme(
    $elliman-theme-primary-palette,
    $elliman-theme-accent-palette,
    $elliman-theme-warn-palette
);

// Add ".theme-elliman" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-elliman {
    // Generate the Angular Material theme
    @include mat.all-component-themes($elliman-theme);

    // Apply the theme to the user components
    @include components-theme($elliman-theme);
    @include elliman-common($elliman-theme);
}
