@mixin avatars-icon-common() {
    .custom-avatar {
        .avatar-initials {
            &-wrap {
                .text,
                .main-image {
                    font-size: inherit;
                    width: 2em;
                    height: 2em;
                    line-height: 2em;
                }
            }
        }
    }
}