@mixin edit-saved-search-dialog-common() {
    .saved-search-edit {
        &-modal {
            width: 100%;
            max-width: 100% !important;
            @include media-breakpoint("gt-xs") {
                width: 480px;
            }
            .mat-dialog-container {
                padding: 0;
            }
        }
        &-footer {
            &-item {
                &--delete {
                    .rpc-btn-link {
                        .rpc-btn-link-text {
                            display: none;
                            @include media-breakpoint("gt-xs") {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
