// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$serhant-theme-accent-palette: mat.define-palette($serhant-accent-dark-color);
$serhant-theme-primary-palette: mat.define-palette($serhant-primary-color);
$serhant-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$serhant-dark-theme: mat.define-dark-theme(
    $serhant-theme-primary-palette,
    $serhant-theme-accent-palette,
    $serhant-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-serhant-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($serhant-dark-theme);

    // Apply the theme to the user components
    @include components-theme($serhant-dark-theme);

}
