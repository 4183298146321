@use '@angular/material' as mat;
@mixin rpc-icon-button($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc {
        &-icon {
            &-btn {
                &:not(.mat-button-disabled) {
                    &.rpc-icon-btn--custom {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                        background-color: mat.get-color-from-palette($background, hover);
                        &.active {
                            &.mat {
                                &-accent {
                                    color: mat.get-color-from-palette($accent, default);
                                    background-color: rgba(0,0,0,0);
                                    &:hover {
                                        color: mat.get-color-from-palette($accent, default);
                                    }
                                }
                                &-primary {
                                    color: mat.get-color-from-palette($primary, default);
                                    background-color: rgba(0,0,0,0);
                                    &:hover {
                                        color: mat.get-color-from-palette($primary, default);
                                    }
                                }
                                &-warn {
                                    color: mat.get-color-from-palette($warn, default);
                                    background-color: rgba(0,0,0,0);
                                    &:hover {
                                        color: mat.get-color-from-palette($warn, default);
                                    }
                                }
                            }
                            &.pick {
                                color: var(--picklist-color);
                                background-color: rgba(0,0,0,0);
                                &:hover {
                                    color: var(--picklist-color);
                                }
                                .rpc-icon-btn-icon {
                                    color: currentColor;
                                }
                            }
                            &.liked {
                                color: var(--like-color);
                                background-color: rgba(0,0,0,0);
                                &:hover {
                                    color: var(--like-color);
                                }
                                .rpc-icon-btn-icon {
                                    color: currentColor;
                                }
                            }
                            &.disliked {
                                color: var(--dislike-color);
                                background-color: rgba(0,0,0,0);
                                &:hover {
                                    color: var(--dislike-color);
                                }
                                .rpc-icon-btn-icon {
                                    color: currentColor;
                                }
                            }
                            &.shown {
                                color: var(--visit-color);
                                background-color: rgba(0,0,0,0);
                                &:hover {
                                    color: var(--visit-color);
                                }
                                .rpc-icon-btn-icon {
                                    color: currentColor;
                                }
                            }
                        }
                        &:hover,
                        &.active {
                            background-color: mat.get-color-from-palette($background, focused-button);
                        }
                    }
                    &:hover {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                            --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                            --text-color: #{mat.get-color-from-palette($background, card)};
                        }
                    }
                    &.active {
                        color: mat.get-color-from-palette($accent, default);
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default);
                            --main-color: #{mat.get-color-from-palette($accent, default)};
                            --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                        }
                    }
                    .rpc-icon-btn {
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                            --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                            --text-color: #{mat.get-color-from-palette($background, card)};
                        }
                        &-badge {
                            background-color: mat.get-color-from-palette($background, card);
                        }
                    }

                    &.mat {
                        &-accent {
                            &:hover,
                            &.active {
                                .rpc-icon-btn-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                    --main-color: #{mat.get-color-from-palette($accent, default)};
                                    --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                }
                            }
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default);
                                --main-color: #{mat.get-color-from-palette($accent, default)};
                                --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                            }
                        }
                        &-primary {
                            &:hover,
                            &.active {
                                .rpc-icon-btn-icon {
                                    color: mat.get-color-from-palette($primary, default);
                                    --main-color: #{mat.get-color-from-palette($primary, default)};
                                    --text-color: #{mat.get-color-from-palette($primary, default-contrast)};
                                }
                            }
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($primary, default);
                                --main-color: #{mat.get-color-from-palette($primary, default)};
                                --text-color: #{mat.get-color-from-palette($primary, default-contrast)};
                            }
                        }
                        &-warn {
                            &:hover,
                            &.active {
                                .rpc-icon-btn-icon {
                                    color: mat.get-color-from-palette($warn, default);
                                    --main-color: #{mat.get-color-from-palette($warn, default)};
                                    --text-color: #{mat.get-color-from-palette($warn, default-contrast)};
                                }
                            }
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($warn, default);
                                --main-color: #{mat.get-color-from-palette($warn, default)};
                                --text-color: #{mat.get-color-from-palette($warn, default-contrast)};
                            }
                        }
                    }
                }
                &.mat-button-disabled {
                    &.rpc-icon-btn--custom {
                        color: mat.get-color-from-palette($foreground, disabled) !important;
                    }
                    .rpc-icon-btn-icon {
                        color: mat.get-color-from-palette($foreground, disabled) !important;
                    }
                }
            }
        }
        &-fab {
            &-btn {
                &:not(.mat-button-disabled) {
                    &.mat {
                        &-accent {
                            &:hover,
                            &.active {
                                .rpc-fab-btn-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($accent, default)};
                                }
                            }
                            .rpc-fab-btn-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                        }
                        &-primary {
                            &:hover,
                            &.active {
                                .rpc-fab-btn-icon {
                                    color: mat.get-color-from-palette($primary, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($primary, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($primary, default)};
                                }
                            }
                            .rpc-fab-btn-icon {
                                color: mat.get-color-from-palette($primary, default-contrast);
                                --main-color: #{mat.get-color-from-palette($primary, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($primary, default)};
                            }
                        }
                        &-warn {
                            &:hover,
                            &.active {
                                .rpc-fab-btn-icon {
                                    color: mat.get-color-from-palette($warn, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($warn, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($warn, default)};
                                }
                            }
                            .rpc-fab-btn-icon {
                                color: mat.get-color-from-palette($warn, default-contrast);
                                --main-color: #{mat.get-color-from-palette($warn, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($warn, default)};
                            }
                        }
                    }
                }
                &.mat-button-disabled {
                    .rpc-fab-btn-icon {
                        color: mat.get-color-from-palette($foreground, disabled) !important;
                    }
                }
            }
        }
        &-mini-fab {
            &-btn {
                &:not(.mat-button-disabled) {
                    &.mat {
                        &-accent {
                            &:hover,
                            &.active {
                                .rpc-mini-fab-btn-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($accent, default)};
                                }
                            }
                            .rpc-mini-fab-btn-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                        }
                        &-primary {
                            &:hover,
                            &.active {
                                .rpc-mini-fab-btn-icon {
                                    color: mat.get-color-from-palette($primary, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($primary, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($primary, default)};
                                }
                            }
                            .rpc-mini-fab-btn-icon {
                                color: mat.get-color-from-palette($primary, default-contrast);
                                --main-color: #{mat.get-color-from-palette($primary, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($primary, default)};
                            }
                        }
                        &-warn {
                            &:hover,
                            &.active {
                                .rpc-mini-fab-btn-icon {
                                    color: mat.get-color-from-palette($warn, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($warn, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($warn, default)};
                                }
                            }
                            .rpc-mini-fab-btn-icon {
                                color: mat.get-color-from-palette($warn, default-contrast);
                                --main-color: #{mat.get-color-from-palette($warn, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($warn, default)};
                            }
                        }
                    }
                }
                &.mat-button-disabled {
                    .rpc-mini-fab-btn-icon {
                        color: mat.get-color-from-palette($foreground, disabled) !important;
                    }
                }
            }
        }
    }
}
