@use '@angular/material' as mat;
@mixin gallery-listing-appointment-card($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .apnt-t-card {
        &-box {
            &-map {
                &-wrap {
                    border-color: mat.get-color-from-palette($foreground, divider);
                    background-color: mat.get-color-from-palette($background, card);
                }
            }
        }
    }
}
