@use '@angular/material' as mat;
@mixin video-placeholder($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-media-video-preview {
        border-color: mat.get-color-from-palette($foreground, divider);
        color: mat.get-color-from-palette($foreground, secondary-text);
        &:hover {
            color: mat.get-color-from-palette($primary, default);
        }
    }
}