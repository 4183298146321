@use '@angular/material' as mat;
@mixin important-dialog-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);

    .important-dialog {
        .confirm {
            &-wrap {
                &--pick {
                    .confirm {
                        &-top {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default);
                                --main-color: #{mat.get-color-from-palette($accent, default)};
                                --text-color: #{mat.get-color-from-palette($background, card)};
                            }
                        }
                        &-btn {
                            &--submit {
                                .rpc-btn-link {
                                    background: mat.get-color-from-palette($accent, default) !important;
                                    border-color: mat.get-color-from-palette($accent, default) !important;
                                    &-icon {
                                        color: mat.get-color-from-palette($background, card);
                                        --main-color: #{mat.get-color-from-palette($background, card)};
                                        --text-color: #{mat.get-color-from-palette($accent, default)};
                                    }
                                    &-text {
                                        color: mat.get-color-from-palette($background, card);
                                    }
                                }
                            }
                        }
                    }
                }
                &--alert {
                    .confirm {
                        &-top {
                            &-icon {
                                color: mat.get-color-from-palette($warn, default);
                                --main-color: #{mat.get-color-from-palette($warn, default)};
                                --text-color: #{mat.get-color-from-palette($background, card)};
                            }
                        }
                        &-btn {
                            &--submit {
                                .rpc-btn-link {
                                    background: mat.get-color-from-palette($warn, default) !important;
                                    border-color: mat.get-color-from-palette($warn, default) !important;
                                    &-icon {
                                        color: mat.get-color-from-palette($background, card);
                                        --main-color: #{mat.get-color-from-palette($background, card)};
                                        --text-color: #{mat.get-color-from-palette($warn, default)};
                                    }
                                    &-text {
                                        color: mat.get-color-from-palette($background, card);
                                    }
                                }
                            }
                        }
                    }
                }
                .confirm {
                    &-title,
                    &-message {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
}
