@mixin on-market-toolbar-common() {
    .toolbar {
        &-secondary {
            &--minimized {
                .header {
                    &--on-market {
                        @include media-breakpoint('gt-sm') {
                            grid-template-columns: minmax(0, 1fr) 91px;
                            grid-template-areas: "filters btns";
                            row-gap: 0px;
                            padding: 5px 20px;
                            width: 100%;
                            margin: 0;
                        }
                        &-with-counter {
                            @include media-breakpoint('gt-sm') {
                                grid-template-columns: auto 1px minmax(0, 1fr) 91px;
                                grid-template-areas: "counter divider-counter filters btns";
                                column-gap: 16px;
                                align-items: center;
                            }
                            .header-minimized-counter {
                                @include media-breakpoint('gt-sm') {
                                    display: block;
                                }
                                &-divider {
                                    @include media-breakpoint('gt-sm') {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                    &-btn-wrap {
                        .filter-menu-btn {
                            &--saved-search {
                                &-desktop {
                                    @include media-breakpoint('gt-sm') {
                                        display: block !important;
                                    }
                                }
                                &-divider {
                                    @include media-breakpoint('gt-sm') {
                                        display: block !important;
                                    }
                                }
                            }
                        }
                    }
                    &-filters {
                        &--with-saved-search {
                            @include media-breakpoint('gt-sm') {
                                display: block;
                            }
                        }
                        &-item {
                            @include media-breakpoint('gt-sm') {
                                width: 100%;
                            }
                            &--saved-search {
                                @include media-breakpoint('gt-sm') {
                                    display: none;
                                }
                            }
                        }
                        .filter {
                            &-menu {
                                .filter-item {
                                    &--saved-search {
                                        @include media-breakpoint('gt-sm') {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                            &-item {
                                &--saved-search {
                                    @include media-breakpoint('gt-sm') {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header {
        &-filters {
            &-item {
                &--saved-search {
                    &--saved-search {
                        @include media-breakpoint('gt-sm') {
                            display: none;
                        }
                    }
                    .filter-item {
                        &--saved-search {
                            .rpc-menu-btn-link {
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .saved-search {
        &-option {
            &-dropdown {
                @include media-breakpoint("gt-xs") {
                    width: 320px;
                    max-width: 320px;
                }
                .mat-menu-content {
                    padding: 20px;
                }
            }
        }
        &-panel {
            &-wrap {
                &.mat-bottom-sheet-container {
                    position: relative;
                    display: block;
                    padding: 0;
                    border-radius: 10px 10px 0 0;
                    overflow: hidden;
                    .saved-search {
                        &-panel-btn {
                            position: absolute;
                            top: 6px;
                            right: 5px;
                            z-index: 10;
                        }
                        &-option {
                            &-wrap {
                                grid-template-rows: auto minmax(0, 1fr);
                                height: 100%;
                            }
                            &-note {
                                position: sticky;
                                top: 0;
                                left: 0;
                                right: 0;
                                padding: 16px 50px 5px 10px;
                                z-index: 5;
                            }
                            &-list {
                                row-gap: 10px;
                                padding: 0 10px 20px;
                                height: 100%;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
