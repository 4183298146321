@use '@angular/material' as mat;
@mixin custom-spinner($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .mp-splash-inner {
        .mp-splash {
            &-backdrop {
                @if ($is-dark) {
                    background: mat.get-color-from-palette($background, background);
                } @else {
                    background: mat.get-color-from-palette($background, background);
                }
            }
        }
        .logo {
            &-spinner {
                color: mat.get-color-from-palette($accent, default);
            }
        }
    }
}
