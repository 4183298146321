@use '@angular/material' as mat;
@mixin side-menu($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .mobile {
        &-customer-info {
            background-color: mat.get-color-from-palette($accent, default);
            &-name {
                color: mat.get-color-from-palette($background, card);
            }
            .avatar-initials-wrap {
                &.customer {
                    .text {
                        background: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
        }
    }
}
