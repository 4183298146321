@use '@angular/material' as mat;
@mixin rpc-input($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .mat-form-field {
        .mat-error {
            color: mat.get-color-from-palette($warn, 500);
        }
        &-label,
        &-suffix,
        &-prefix {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-invalid {
            .mat-form-field {
                &-ripple {
                    background-color: mat.get-color-from-palette($warn, 500)!important;
                }
                &-label{
                    color: mat.get-color-from-palette($warn, 500)!important;
                }
            }
            .mat-input-element{
                color: mat.get-color-from-palette($warn, 500);
            }
        }
        &.mat-focused {
            &:not(.mat-form-field-invalid) {
                .mat-form-field {
                    &-required-marker {
                        color: mat.get-color-from-palette($foreground, secondary-text) !important;
                    }
                }
            }
            .mat-form-field {
                &-ripple {
                    background-color: mat.get-color-from-palette($foreground, text)!important;
                }
                &-label,
                &-outline{
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
        }
        &.field {
            &-fill,
            &-legacy {
                .mat-form-field-underline {
                    &:before {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
