// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$aanyc-theme-primary-palette: mat.define-palette($aanyc-color);
$aanyc-theme-accent-palette: mat.define-palette($aanyc-color);
$aanyc-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$aanyc-dark-theme: mat.define-dark-theme(
    $aanyc-theme-primary-palette,
    $aanyc-theme-accent-palette,
    $aanyc-theme-warn-palette
);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-aanyc-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($aanyc-dark-theme);

    // Apply the theme to the user components
    @include components-theme($aanyc-dark-theme);

}
