@mixin rpc-datepicker-common() {
    .mat-datepicker {
        &-popup {
            @media screen and (max-height: 550px) {
                top: 0 !important;
                bottom: 0 !important;
                justify-content: center;
            }
        }
        &-content {
            border-radius: 10px !important;
            &-container {
                overflow-y: auto;
            }
            .mat-calendar {
                height: 370px;
            }
            .mat-datepicker-close-button {
                display: none;
                margin: 0;
            }
            mat-custom-header {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                &.type {
                    &-date {
                        & ~ .mat-calendar-content {
                            padding: 0 8px;
                        }
                        .mat {
                            &-custom {
                                &-controls {
                                    padding: 0 4%;
                                    .mat-custom-date {
                                        &-year,
                                        &-monthday {
                                            font-size: 36px;
                                            line-height: 32px;
                                        }
                                    }
                                }
                                &-prev-next {
                                    padding: 0;
                                }
                            }
                        }
                    }
                    &-time {
                        .mat {
                            &-custom {
                                &-controls {
                                    padding: 0 4%;
                                }
                            }
                        }
                    }
                }
            }
            mat-clock-view {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                min-height: 236px;
                min-width: 224px;
                overflow: hidden;
                font-size: 14px;
                box-sizing: border-box;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
            }
            .mat {
                &-calendar {
                    &-table {
                        &-header {
                            th {
                                &:not(.mat-calendar-table-header-divider) {
                                    padding-top: 8px !important;
                                }
                            }
                        }
                    }
                }
                &-clock {
                    border-radius: 50%;
                    position: relative;
                    &-center {
                        top: 50%;
                        left: 50%;
                        width: 2%;
                        height: 2%;
                        margin: -1%;
                        border-radius: 50%;
                    }
                    &-center,
                    &-hand {
                        position: absolute;
                    }
                    &-hand {
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 1px;
                        margin: 0 auto;
                        transform-origin: bottom;
                        &:before {
                            content: "";
                            position: absolute;
                            top: -4px;
                            left: -4px;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                        }
                    }
                    &-hours,
                    &-minutes {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        visibility: hidden;
                        transition: 0.35s;
                        transform: scale(1.2);
                        &.active {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                    &-minutes {
                        transform: scale(0.8);
                    }
                    &-cell {
                        position: absolute;
                        display: flex;
                        width: 14.1666%;
                        height: 14.1666%;
                        justify-content: center;
                        box-sizing: border-box;
                        border-radius: 50%;
                        align-items: center;
                        cursor: pointer;
                        &:not(.mat-clock-cell-selected) {
                            &:not(.mat-clock-cell-disabled) {
                                &:hover {
                                    background-color: rgba(0, 0, 0, 0.1);
                                }
                            }
                        }
                        &.mat-clock-cell {
                            &-disabled {
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .rpc-date-wrap {
        &--hidden {
            .mat-form-field {
                display: block;
                height: 100%;
                &-outline,
                &-infix {
                    display: none !important;
                }
                &-wrapper {
                    display: block;
                    height: 100%;
                    margin: 0 !important;
                    padding: 0 !important;
                }
                &-flex {
                    display: block !important;
                    height: 100%;
                    padding: 0 !important;
                    margin: 0 !important;
                    align-items: center !important;
                    justify-content: center !important;
                }
                &-suffix {
                    position: static !important;
                    display: block !important;
                    height: 100%;
                    padding: 0 !important;
                    margin: 0 !important;
                    .rpc-datepicker-icon {
                        margin: 0 !important;
                        font-size: 24px;
                    }
                    .mat-datepicker-toggle {
                        .mat-icon-button {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
        .mat-form-field {
            &-disabled {
                .mat-form-field {
                    &-flex {
                        pointer-events: none;
                    }
                    &-autofill-control {
                        opacity: 0.5;
                    }
                }
            }
            &-infix {
                display: flex;
            }
            &-suffix,
            &-prefix {
                .mat-icon-button {
                    width: 2em !important;
                    height: 2em !important;
                }
            }
            &-prefix {
                margin-left: -5px;
            }
            &-suffix {
                margin-right: -5px;
            }
            &-subscript-wrapper {
                position: static;
                padding: 0 !important;
            }
            &.field {
                &-fill,
                &-legacy {
                    .mat-form-field {
                        &-flex {
                            padding: 8px 15px 0;
                        }
                        &-underline {
                            position: static;
                        }
                        &-ripple {
                            height: 1px;
                        }
                        &-prefix {
                            margin-top: 6px;
                        }
                    }
                }
                &-outline {
                    .mat-form-field {
                        &-wrapper {
                            padding-bottom: 0;
                        }
                        &-flex {
                            padding: 0 7px;
                            display: flex;
                        }
                        &-suffix,
                        &-prefix {
                            top: 0.35em;
                            .mat-button-wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100%;
                            }
                        }
                        &-infix {
                            padding: 0.25em 0 0.75em 0.25em !important;
                            .mat-input-element {
                                margin-top: 0;
                            }
                        }
                        &-outline {
                            &-start,
                            &-end,
                            &-gap {
                                border-width: 1px;
                            }
                            &-start {
                                flex: 0 0 10px;
                                min-width: 10px;
                            }
                            &-gap {
                                flex: 0 0 auto;
                            }
                            &-end {
                                flex: 1 0 10px;
                            }
                        }
                        &-label {
                            top: 1.35em !important;
                            mat-label {
                                padding-left: 4px;
                            }
                        }
                    }
                }
            }
            &-should-float {
                &.field {
                    &-outline {
                        .mat-form-field {
                            &-label {
                                margin-top: 3px !important;
                                mat-label {
                                    padding-left: 0.75em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
