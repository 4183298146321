@use '@angular/material' as mat;
@mixin media-viewer($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-media-viewer {
        &-title {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-tabs {
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
            &:before,
            &:after {
                background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
            }
            &-item {
                color: mat.get-color-from-palette($foreground, secondary-text);
                &:hover {
                    color: mat.get-color-from-palette($primary, default);
                }
                &--active {
                    color: mat.get-color-from-palette($primary, default);
                }
            }
            &-slash {
                border-bottom-color: mat.get-color-from-palette($primary, default);
            }
        }
        &-btn {
            background-color: mat.get-color-from-palette($background, background);
            color: mat.get-color-from-palette($foreground, text);
            &:hover {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-footer {
            &-section {
                &:before,
                &:after {
                    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);;
                }
            }
            &-item {
                &-block {
                    &:after {
                        background-color: mat.get-color-from-palette($primary, default);
                    }
                }
            }
        }
    }
}
