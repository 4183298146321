@use '@angular/material' as mat;
@mixin knowledge-base-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .kb {
        &-header {
            background-color: mat.get-color-from-palette($accent, default);
            &:before,
            &:after {
                background-color: rgba(0,0,0,.08);
            }
            &-decoration {
                &:before,
                &:after {
                    background-color: rgba(0,0,0,.08);
                }
            }
        }
        &-title {
            color: mat.get-color-from-palette($background, card);
        }
        &-search {
            background-color: mat.get-color-from-palette($background, card);
            box-shadow: 10px 20px 40px 0 rgba(0, 0, 0, 0.1);
            .mat-form-field {
                &-appearance-outline {
                    .mat-form-field {
                        &-outline {
                            color: transparent !important;
                            &-thick {
                                color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }
}