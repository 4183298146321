@mixin knowledge-base-item-common() {
    .kb {
        &-card {
            &-img {
                &-wrap {
                    .default-image-wrap {
                        border-radius: 10px;
                        overflow: hidden;
                    }
                }
            }
        }
        &-modal {
            &-backdrop {
                &.cdk-overlay-backdrop {
                    
                }
            }
            .mat-dialog-container {
                width: 85vmin;
                aspect-ratio: 16/9;
                // Fix for MacOS & iOS
                height: 47.8125vmin;
                padding: 0;
                media-gallery {
                    display: initial;
                    height: auto;
                }
                .rpc-mg {
                    &-wrap {
                        position: static;
                        height: auto;
                    }
                    &-slide {
                        position: static;
                        &-inner {
                            display: block;
                            .default-video-frame {
                                display: block;
                                width: 100%;
                                height: 100%;
                                &-wrap {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &:not(.is-mobile) {
        .kb-card {
            &-img-wrap {
                &:hover {
                    .main,
                    .broken {
                        &-background-image {
                            transform: scale(1.03);
                        }
                    }
                }
                .main,
                .broken {
                    &-background-image {
                        transform: scale(1);
                        transition: transform 1s cubic-bezier(0.35, 0, 0.25, 1);
                        will-change: transform;
                    }
                }
            }
        }
    }
}