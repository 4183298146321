@use '@angular/material' as mat;
@mixin scroll-to-top($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .scroll-to-top {
        .rpc-icon-btn {
            background: mat.get-color-from-palette($foreground, disabled);
            &:hover {
                background: mat.get-color-from-palette($foreground, disabled);
            }
            &-icon {
                color: mat.get-color-from-palette($background, card) !important;
            }
        }
    }
}
