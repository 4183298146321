@mixin my-listings-filter-common() {
    .filter {
        &-item {
            &--user {
                .rpc-menu-btn-link {
                    &-text {
                        margin-left: 0.4em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 110px;
                    }
                }
            }
            &--icon {
                .rpc-btn-link {
                    min-width: 40px;
                    width: 40px;
                    padding: 0;
                    &-text {
                        display: none;
                    }
                    &-icon {
                        font-size: 1.2em;
                    }
                }
            }
            &--portfolio-btn {
                .rpc-menu-btn-link {
                    padding-inline: 0;
                    min-width: 40px;
                    @include media-breakpoint("gt-sm") {
                        padding-inline: 15px;
                    }
                    &-text {
                        display: none;
                        @include media-breakpoint("gt-sm") {
                            display: block;
                        }
                    }
                    &-trigger {
                        display: none;
                    }
                }
                .badge-count {
                    top: -2px;
                    right: -2px;
                }
            }
        }
        &-portfolio-desk {
            .filter-menu-item--toggle-wrap {
                display: none;
            }
        }
        &-dropdown {
            &:not(mat-menu) {
                &.filter-dropdown--large {
                    max-width: 500px !important;
                    width: auto;
                    overflow-x: hidden;
                    .rpc-inline-filter {
                        .filter-item {
                            &:not(:first-child) {
                                margin-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
