@mixin listing-details-base-common() {
    .mc {
        &-dialog {
            width: 100%;
            max-width: 767px !important;
            .mat-dialog-container {
                padding: 0;
                border-radius: 0;
            }
        }
    }
    .rpc-ld {
        &-page {
            .single-select--mobile {
                &.single-select--ld {
                    .activity-controls-wrapper {
                        .rpc-icon-btn {
                            transition: background-color .2s ease-in-out;
                            will-change: background-color;
                        }
                    }
                }
                .activity-controls-wrapper {
                    justify-content: space-around;
                }
            }
            .multi-select {
                &--desktop {
                    &-max {
                        & + .scroll-to-top-wrap.upper {
                            @include media-breakpoint("gt-xs") {
                                bottom: 110px;
                            }
                            @include media-breakpoint("gt-sm") {
                                bottom: 50px;
                            }
                        }
                        .multi-selection.multi-selection--active {
                            grid-template-columns: 95px 1px 120px 1px 331px;
                        }
                    }
                }
                &--mobile {
                    &-pure {
                        &.multi-select--mobile-restore {
                            .multi-selection--active {
                                grid-template-columns: 150px calc(100% - 286px) 40px 40px 40px;
                                grid-template-areas: "checkbox . folder activities restore";
                            }
                        }
                        .multi-selection--active {
                            grid-template-columns: 150px calc(100% - 242px) 40px 40px;
                            grid-template-areas: "checkbox . folder activities";
                        }
                    }
                    &-restore {
                        .multi-selection--active {
                            grid-template-columns: 145px calc(100% - 305px) 40px 40px 40px 40px;
                            grid-template-areas: "checkbox . folder activities delete restore";
                            column-gap: 0;
                        }
                    }
                    &-removed-listings {
                        .multi-selection--active {
                            grid-template-columns: 145px calc(100% - 225px) 80px;
                            grid-template-areas: "checkbox . activities";
                            column-gap: 0;
                        }
                    }
                }
            }
        }
        &-header {
            &-address {
                &--checkbox {
                    .mat-checkbox {
                        &-layout {
                            display: grid;
                            grid-template-columns: 20px calc(100% - 26px);
                            column-gap: 6px;
                            align-items: center;
                        }
                        &-label {
                            display: block;
                            font-size: 20px;
                            line-height: 24px;
                            font-weight: 400;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            @include media-breakpoint("gt-xs") {
                                font-size: 24px;
                                line-height: 1.17;
                            }
                        }
                        &-inner-container {
                            display: block;
                            margin: 0;
                        }
                    }
                }
            }
            &-price {
                .price {
                    &-wrap {
                        justify-content: flex-end;
                        flex-direction: row-reverse;
                        @include media-breakpoint("gt-sm") {
                            flex-direction: row;
                        }
                    }
                    &-simple {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 400;
                        @include media-breakpoint("gt-xs") {
                            font-size: 24px;
                            line-height: 1.17;
                        }
                    }
                }
            }
            &-info {
                &-item {
                    &--labels {
                        .listing-labels {
                            &-wrap {
                                justify-content: flex-start;
                                align-items: center;
                                li {
                                    display: flex !important;
                                    &.listing-labels-item-wrap--type {
                                        order: -1;
                                    }
                                }
                            }
                            &-item {
                                &-wrap {
                                    &--type {
                                        order: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-media {
            &-labels {
                .listing-labels-wrap {
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    column-gap: 10px;
                    row-gap: 10px;
                }
            }
            &-box {
                &-item {
                    .main,
                    .broken {
                        &-background-image {
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            background-clip: content-box;
                        }
                    }
                }
            }
        }
        &-nav {
            &-communication {
                &-counter {
                    .badge-count {
                        top: -5px;
                        right: -14px;
                        min-width: 0;
                    }
                }
            }
            &-item {
                &--removed {
                    .activity-controls-wrapper {
                        display: grid;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        column-gap: 10px;
                        align-items: center;
                    }
                }
            }
            &-actions {
                &-folder {
                    .rpc-btn-link {
                        display: block;
                        max-width: 100%;
                        .mat-button-wrapper {
                            display: grid;
                            grid-template-columns: 26px calc(100% - 31px);
                            column-gap: 5px;
                            justify-content: start;
                        }
                        &-text {
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            margin-left: 0 !important;
                        }
                    }
                }
                .activities-wrap--combined {
                    grid-template-columns: 100%;
                }
            }
        }
        &-history-tooltip {
            padding-inline: 0 !important;
        }
        &-body {
            &-item {
                &-title {
                    .badge-count {
                        top: -5px;
                        right: -16px;
                    }
                }
            }
            &-price-changes,
            &-map-wrap {
                .mat-tab-group {
                    .mat-tab-body-content {
                        padding-inline: 0;
                    }
                }
            }
            &-map-wrap {
                .mat-tab-group {
                    .mat-tab-body-content {
                        padding-bottom: 0;
                    }
                }
            }
            .rpc-icon-field {
                align-items: start;
                &-text {
                    padding-block: 2px;
                }
            }
        }
        &-chat-button {
            position: absolute;
            display: block;
            bottom: 40px;
            right: 15px;
            width: 74px;
            height: 74px;
            z-index: 10;
            & ~ .scroll-to-top-wrap {
                right: 32px;
                bottom: 125px;
                @include media-breakpoint("gt-xs") {
                    bottom: 125px;
                }
            }
        }
    }
}