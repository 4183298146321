@mixin m-calculator-form-common() {
    .mc-toggler {
        &-text {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &--left {
                grid-area: left;
            }
            &--right {
                grid-area: right;
                text-align: right;
            }
        }
        .mat-slide-toggle {
            &-label {
                position: relative;
            }
            &-bar {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                margin: 0;
            }
            &-content {
                display: grid;
                grid-template-columns: 60px 40px 60px;
                grid-template-areas: "left . right";
            }
        }
    }
}
