@mixin internal-listing-details-toolbar-common() {
    .toolbar {
        &-secondary {
            &--minimized {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            }
        }
    }
    .ld {
        &-toolbar {
            &-back {
                .rpc-btn-link {
                    min-width: 40px;
                    padding: 0;
                    @include media-breakpoint("gt-xs") {
                        padding: 0 8px;
                    }
                    @include media-breakpoint("gt-sm") {
                        padding: 0 16px;
                    }
                    &-icon {
                        font-size: 18px;
                        transition: color 0.2s ease-in-out;
                    }
                    &-text {
                        display: none;
                        font-size: 14px;
                        margin-left: 4px !important;
                        @include media-breakpoint("gt-xs") {
                            display: block;
                        }
                    }
                }
            }
            &-nav {
                .rpc-btn-link {
                    min-width: auto;
                    padding: 0;
                    width: 40px;
                }
            }
        }
    }
}
