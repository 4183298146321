@mixin folder-details-common() {
    .folder {
        &-menu {
            .rpc-dropdown {
                &-btn {
                    &--first {
                        .rpc-btn-link-text:before {
                            border-top: none;
                        }
                    }
                }
            }
        }
        &-default-toggle {
            .mat-slide-toggle {
                &-label {
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 14px 20px 15px;
                }
                &-bar {
                    margin-top: 3px;
                }
            }
        }
    }
}