@use '@angular/material' as mat;
@mixin landing-message-card-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .lm-card {
        &-footer {
            border-top-color: mat.get-color-from-palette($foreground, divider);
            &-icon {
                color: mat.get-color-from-palette($accent, default);
                &-wrap {
                    background-color: mat.get-color-from-palette($accent, default, .1);
                }
            }
            &-message,
            &-name {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-date {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}