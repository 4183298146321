@mixin external-listings-dialog-common() {
    .external-listings-dailog {
        max-width: 900px !important;
        .mat-dialog-container {
            padding: 0;
            max-height: 80vh;
        }
    }
    .el-dialog {
        &-spinner {
            .mp-splash {
                pointer-events: all;
            }
        }
        &-info {
            .rpc-info {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                border-left-width: 3px;
                border-left-style: solid;
                border-radius: 0;
                padding: 16px 20px 16px 17px;
            }
        }
    }
}