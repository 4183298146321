@use '@angular/material' as mat;
@mixin email-alert-settings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .notif-alert {
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
        }
        &-item {
            &-text {
                &-wrap {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    &--picked {
                        color: var(--picklist-color);
                    }
                    &--liked {
                        color: var(--like-color);
                    }
                    &--disliked {
                        color: var(--dislike-color);
                    }
                }
            }
        }
    }
}
