@use '@angular/material' as mat;
@mixin answer-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .rpc-chatbot-answer {
        &-list {
            li {
                a {
                    color: mat.get-color-from-palette($primary, default);
                    &:focus {
                        color: mat.get-color-from-palette($primary, default);
                    }
                    &:hover {
                        color: mat.get-color-from-palette($primary, 400);
                    }
                }
            }
        }
    }
}