@use '@angular/material' as mat;

@mixin media-gallery($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-mg {
        &-dialog {
            .mat-dialog-container {
                box-shadow: none;
                -webkit-backdrop-filter: blur(25.1px);
                backdrop-filter: blur(25.1px);
                background-color: mat.get-color-from-palette($background, card);
            }
        }
        &-close {
            .rpc-btn-link {
                &:not(.mat-button-disabled) {
                    &.active,
                    &:hover {
                        .rpc-btn-link {
                            &-text {
                                color: mat.get-color-from-palette($foreground, text);
                            }
                            &-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                    .rpc-btn-link {
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
        }
        &-btn {
            color: mat.get-color-from-palette($background, card);
            @include media-breakpoint("gt-sm") {
                background-color: mat.get-color-from-palette($background, background);
                color: mat.get-color-from-palette($foreground, text);
            }
            &:after {
                background-image: linear-gradient(-90deg, mat.get-color-from-palette($foreground, secondary-text), transparent);
            }
            &--previous {
                &:after {
                    background-image: linear-gradient(90deg, mat.get-color-from-palette($foreground, secondary-text), transparent);
                }
            }
            &:hover {
                @include media-breakpoint("gt-sm") {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
        }
        &-slide {
            &-inner {
                .main-zoom-img-wrap {
                    background-color: transparent !important;
                }
                .pz-zoom-button {
                    background-color: rgba(0,0,0,.54);
                    &:hover {
                        background-color: rgba(0,0,0,.87);
                    }
                }
            }
        }
        &-card {
            color: mat.get-color-from-palette($foreground, text);
            background-color: mat.get-color-from-palette($background, background);
            &:hover {
                .rpc-mg-card-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
        }
    }
}
