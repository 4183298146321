@use "@angular/material" as mat;
@mixin welcome-video-link($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .welcome {
        &-video {
            border-color: mat.get-color-from-palette($foreground, divider);
            background-color: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, text);
            &:hover {
                border-color: mat.get-color-from-palette($accent, default);
            }
            &-button {
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
        }
    }
}