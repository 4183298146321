@mixin notifications-common() {
    .rpc-notifications {
        &-dropdown {
            min-width: 1px;
            max-width: none;
            width: 560px;
            &-mobile-wrap {
                border-radius: 8px;
                padding: 0;
                height: 80vh;
                max-height: 80vh !important;
            }
        }
        &-tabs {
            &.mat-tab-group {
                .mat-tab-body-content {
                    padding: 20px 0px 0;
                    // padding: 20px 15px 0;
                    @include media-breakpoint("gt-xs") {
                        // padding-left: 25px;
                        // padding-right: 25px;
                    }
                }
            }
            .mat-tab {
                &-header {
                    position: -webkit-sticky;
                    position: sticky;
                    top: -1px;
                    padding-top: 5px;
                    z-index: 5;
                    @include media-breakpoint("gt-xs") {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
                &-label {
                    min-width: 1px;
                    height: 40px;
                    padding: 0 15px;
                    &-content {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 20px;
                        text-transform: uppercase;
                        user-select: none;
                        .badge-count {
                            top: -9px;
                            right: -13px;
                        }
                    }
                }
            }
        }
    }
}
