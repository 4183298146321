@mixin rpc-select-common() {
    .rpc-select {
        &.mat-form-field {
            &-disabled {
                .mat-form-field {
                    &-flex {
                        pointer-events: none;
                    }
                    &-autofill-control {
                        opacity: 0.5;
                    }
                }
            }
            &-subscript-wrapper {
                position: static;
                padding: 0 !important;
            }
            &.field {
                &-fill,
                &-legacy {
                    .mat-form-field {
                        &-flex {
                            padding: 8px 15px 0;
                        }
                        &-underline {
                            position: static;
                        }
                        &-ripple {
                            height: 1px;
                        }
                    }
                }
                &-outline {
                    .mat-form-field {
                        &-flex {
                            padding: 0 1.2em 0 1.2em;
                        }
                        &-infix {
                            padding: 0.25em 0.25em 0.75em !important;
                        }
                        &-outline {
                            &-start,
                            &-end,
                            &-gap {
                                border-width: 1px;
                            }
                            &-start {
                                min-width: 5px;
                            }
                        }
                        &-label {
                            top: 1.35em !important;
                        }
                    }
                }
            }
            &-should-float {
                &.field {
                    &-outline {
                        .mat-form-field {
                            &-label {
                                margin-top: 3px !important;
                            }
                        }
                    }
                }
            }
            .mat {
                &-select {
                    &-value {
                        &-text {
                            display: block;
                        }
                    }
                    &-arrow {
                        &-wrapper {
                            transform: translateY(0) !important;
                        }
                    }
                    &-trigger {
                        display: flex !important;
                    }
                }
            }
            mat-select-trigger {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
        &-option {
            .mat-option-text {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}
