@use '@angular/material' as mat;
@mixin calendar-appointment($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .apnt-c-appointment {
        &-group-dropdown-wrap {
            .apnt-c-appointment-box--current {
                background-color: transparent;
            }
        }
        &-box {
            @if ($is-dark) {
                background-color: mat.get-color-from-palette($background, background);
            } @else {
                background-color: mat.get-color-from-palette($background, card);
            }
            &--current {
                background-color: mat.get-color-from-palette($background, card, 0.7);
            }
            &--new {
                &:before {
                    background-color: mat.get-color-from-palette($accent, 50);
                }
            }
            &--shown {
                .apnt-c-appointment {
                    &-line {
                        border-left-color: var(--status-shown);
                    }
                    &-time-icon {
                        color: var(--status-shown);
                        --main-color: var(--status-shown);
                    }
                }
            }
            &--declined {
                .apnt-c-appointment {
                    &-line {
                        border-left-color: var(--status-declined);
                    }
                    &-time-icon {
                        color: var(--status-declined);
                        --main-color: var(--status-declined);
                    }
                }
            }
            &--confirmed {
                .apnt-c-appointment {
                    &-line {
                        border-left-color: var(--status-confirmed);
                    }
                    &-time-icon {
                        color: var(--status-confirmed);
                        --main-color: var(--status-confirmed);
                    }
                }
            }
            &--unconfirmed {
                &-customer {
                    .apnt-c-appointment {
                        &-line {
                            background: linear-gradient(
                                0deg,
                                var(--status-confirmed) 0%,
                                var(--status-confirmed) 49.9%,
                                var(--status-declined) 50%
                            );
                        }
                        &-time-icon {
                            color: var(--status-confirmed);
                            --main-color: var(--status-confirmed);
                            &--second {
                                color: var(--status-declined);
                                --main-color: var(--status-declined);
                            }
                        }
                    }
                }
                .apnt-c-appointment {
                    &-line {
                        border-left-color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-time-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                        --main-color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        &-group {
            &-dropdown {
                &-wrap {
                    .apnt-c-appointment {
                        &:not(:first-of-type) {
                            border-top-color: mat.get-color-from-palette($foreground, divider);
                        }
                    }
                }
            }
        }
    }
}
