@use '@angular/material' as mat;

@mixin rpc-icon-field($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-icon-field {
        color: mat.get-color-from-palette($foreground, text);
        &-icon {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}