@use '@angular/material' as mat;
@mixin checkbox-filter($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .cf {
        &-btn {
            .rpc-btn-link {
                &.mat-stroked-button {
                    &:not(.mat-button-disabled) {
                        &.active {
                            border-color: mat.get-color-from-palette($accent, default);
                            background-color: mat.get-color-from-palette($accent, default);
                            .rpc-btn-link-text {
                                color: mat.get-color-from-palette($background, card);
                            }
                        }
                    }
                }
            }
        }
    }

}