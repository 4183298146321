@use '@angular/material' as mat;
@mixin multi-selection-menu($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .multi-selection {
        background: mat.get-color-from-palette($accent, default);
        color: mat.get-color-from-palette($accent, default-contrast);
        &:not(.mobile) {
            .multi-selection {
                &-counter {
                    border-color: mat.get-color-from-palette($foreground, divider);
                }
            }
        }
        &-checkbox {
            border-color: mat.get-color-from-palette($foreground, divider);
            .mat-checkbox {
                &-frame {
                    border-color: mat.get-color-from-palette($accent, default-contrast);
                }
                &-background {
                    background-color: mat.get-color-from-palette($accent, default-contrast) !important;
                }
                &-mixedmark {
                    background-color: mat.get-color-from-palette($accent, default);
                }
                &-checkmark {
                    fill: mat.get-color-from-palette($accent, default) !important;
                    &-path {
                        stroke: mat.get-color-from-palette($accent, default) !important;
                    }
                }
            }
        }
        &--route {
            .rpc-icon-btn {
                background: mat.get-color-from-palette($background, card);
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
                &:not(.mat-button-disabled) {
                    &:hover {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    .rpc-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        .mat-divider-vertical {
            border-right-color: mat.get-color-from-palette($accent, default-contrast);
        }
        .activity-controls-wrapper {
            .activity-controls-item {
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default-contrast, .75) !important;
                            }
                        }
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast);
                        }
                    }
                    &.mat-button-disabled {
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                        }
                    }
                }
            }
            .rpc-icon-btn {
                &:not(.mat-button-disabled) {
                    &:hover {
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .75);
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                        }
                    }
                    .rpc-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default-contrast);
                        --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                        --text-color: #{mat.get-color-from-palette($accent, default)};
                    }
                }
                &.mat-button-disabled {
                    .rpc-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                        --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                    }
                }
            }
            .rpc-btn-link {
                &:not(.mat-button-disabled) {
                    &:hover {
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast, .75);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                            }
                            &-text {
                                color: mat.get-color-from-palette($accent, default-contrast, .75);
                            }
                        }
                    }
                    .rpc-btn-link {
                        &-icon {
                            color: mat.get-color-from-palette($accent, default-contrast);
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                            --text-color: #{mat.get-color-from-palette($accent, default)};
                        }
                        &-text {
                            color: mat.get-color-from-palette($accent, default-contrast);
                        }
                    }
                }
                &.mat-button-disabled {
                    .rpc-btn-link {
                        &-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                        }
                        &-text {
                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                        }
                    }
                }
            }
        }
        .multi-selection {
            &-folder,
            &-pick,
            &-directions,
            &-remove,
            &-delete,
            &-restore {
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default-contrast, .75);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                            }
                        }
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast);
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                            --text-color: #{mat.get-color-from-palette($accent, default)};
                        }
                    }
                    &.mat-button-disabled {
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                        }
                    }
                }
                .rpc-menu-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-menu-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default-contrast, .75);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                            }
                        }
                        .rpc-menu-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast);
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                            --text-color: #{mat.get-color-from-palette($accent, default)};
                        }
                    }
                    &.mat-button-disabled {
                        .rpc-menu-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                        }
                    }
                }
            }
            &-activity {
                .rpc-menu-btn-link {
                    &:not(.mat-button-disabled) {
                        &:hover,
                        &.rpc-menu-btn-link--opened {
                            .rpc-menu-btn-link {
                                &-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast, .75);
                                    --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                                }
                                &-text {
                                    color: mat.get-color-from-palette($accent, default-contrast, .75);
                                }
                            }
                        }
                        .rpc-menu-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                            &-text {
                                color: mat.get-color-from-palette($accent, default-contrast);
                            }
                        }
                    }
                    &.mat-button-disabled {
                        .rpc-menu-btn-link-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                        }
                    }
                }
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-icon-btn {
                                &-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast, .75);
                                    --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                                }
                            }
                        }
                        .rpc-icon-btn {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast);
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                --text-color: #{mat.get-color-from-palette($accent, default)};
                            }
                        }
                    }
                    &.mat-button-disabled {
                        .rpc-icon-btn {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                                --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                            }
                        }
                    }
                }
            }
        }
        .activity-controls-item {
            .rpc-menu-icon-btn {
                &--opened {
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default-contrast, .75) !important;
                        --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                    }
                }
                &:not(.mat-button-disabled) {
                    &:hover {
                        .rpc-menu-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default-contrast, .75) !important;
                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                        }
                    }
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default-contrast);
                        --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                        --text-color: #{mat.get-color-from-palette($accent, default)};
                    }
                }
                &.mat-button-disabled {
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                        --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                    }
                }
            }
        }
    }
}
