@use '@angular/material' as mat;
@mixin gallery-external-listing-card($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-external-card {
        &-back,
        &-front {
            background-color: mat.get-color-from-palette($background, card);
            box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
        }
        &-top {
            &-price {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
        &-source {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-customer {
            &-date {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-info {
            &-btn {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-footer {
            &-link {
                color: mat.get-color-from-palette($accent, default);
            }
        }
    }
}
