@use "@angular/material" as mat;
@mixin listing-bell($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .card-communication {
        &-item {
            &-name {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-icon,
            &-date,
            &-comment,
            &-time,
            &-date-combined,
            &-empty {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-status {
                &-block {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    &--confirmed {
                        color: var(--status-confirmed);
                        --main-color: var(--status-confirmed);
                    }
                    &--declined {
                        color: var(--status-declined);
                        --main-color: var(--status-declined);
                    }
                    &--shown {
                        color: var(--status-shown);
                        --main-color: var(--status-shown);
                    }
                }
            }
        }
    }
}
