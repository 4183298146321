@use '@angular/material' as mat;
@mixin notifications($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-notifications {
        &-clear {
            color: mat.get-color-from-palette($accent, default);
            &:hover {
                color: mat.get-color-from-palette($accent, 700);
            }
        }
        &-tabs {
            .mat-tab {
                &-header {
                    background-color: mat.get-color-from-palette($background, card);
                }
            }
        }
    }
}
