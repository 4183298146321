@use '@angular/material' as mat;
@mixin landing-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .landing-page {
        &-header {
            &:after {
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.66%, rgba(0, 0, 0, .6) 65.65%);
                @include media-breakpoint("gt-xs") {
                    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.11%, rgba(0, 0, 0, .6) 78.46%);
                }
            }
            &-title {
                color: rgba(255,255,255,1);
            }
        }
        &-form {
            &-wrap--sticky {
                .landing-page-form-component {
                    @include media-breakpoint("gt-sm") {
                        background-color: mat.get-color-from-palette($background, card);
                        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .06);
                    }
                }
            }
            &-component {
                background-color: rgba(0,0,0,0);
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
            }
        }
    }
}