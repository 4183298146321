@use '@angular/material' as mat;
@mixin listing-labels($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .listing-labels {
        &-item {
            color: mat.get-color-from-palette($primary, default);
            background-color: #fff;
            &:after {
                background-color: mat.get-color-from-palette($primary, default, .2);
            }
            &.nm {
                &:after {
                    background-color: var(--nm-200);
                }
                .listing-labels-item {
                    &-icon,
                    &-text {
                        color: var(--nm-500);
                    }
                }
            }
            &.na {
                &:after {
                    background-color: var(--na-200);
                }
                .listing-labels-item {
                    &-icon {
                        color: var(--na-500);
                        --main-color: var(--na-500);
                        --text-color: var(--na-200);
                    }
                    &-text {
                        color: var(--na-500);
                    }
                }
            }
            &.price-drop {
                &:after {
                    background-color: var(--price-drop-200);
                }
                .listing-labels-item {
                    &-icon,
                    &-text {
                        color: var(--price-drop-500);
                    }
                }
            }
            &-icon {
                color: mat.get-color-from-palette($primary, default);
                --main-color: #{mat.get-color-from-palette($primary, default)};
                --text-color: #{mat.get-color-from-palette($primary, 200)};
            }
        }
        &-menu-oh-main {
            span {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
