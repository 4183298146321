@use '@angular/material' as mat;
@mixin listing-share($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .share {
        &-wrap {
            &-dropdown {
                border-color: mat.get-color-from-palette($foreground, divider);
            }
        }
        &-info {
            &-icon {
                color: mat.get-color-from-palette($accent, default);
                &--pick {
                    color: var(--picklist-color);
                }
                &--like {
                    color: var(--like-color);
                }
                &--dislike {
                    color: var(--dislike-color);
                }
                &--shown {
                    color: var(--visit-color);
                }
            }
        }
        &-title {
            &-icon {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-item {
            &-divider {
                color: mat.get-color-from-palette($foreground, divider);
                background: mat.get-color-from-palette($background, card);
                &-wrap {
                    &:before {
                        border-top-color: mat.get-color-from-palette($foreground, divider);
                    }
                }
            }
        }
        &-field {
            &--suffix {
                .share-field-copy-btn {
                    color: mat.get-color-from-palette($accent, default-contrast);
                }
            }
        }
    }
}
