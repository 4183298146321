@use '@angular/material' as mat;
@mixin commute-time($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-commute-time {
        &-info {
            border-left-color: var(--info-500);
            background-color: var(--info-500-15);
            color: var(--info-500);
        }
        &-item-input-wrap {
            &--from {
                .mat-input-element:disabled,
                .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            .mat-form-field-prefix {
                color: mat.get-color-from-palette($primary, default);
            }
        }
        &-card {
            color: mat.get-color-from-palette($primary, default);
            border-color: mat.get-color-from-palette($foreground, divider);
            background-color: mat.get-color-from-palette($primary, default-contrast);
            &:hover {
                border-color: mat.get-color-from-palette($primary, default);
                background-color: mat.get-color-from-palette($primary, 100);
            }
        }
        &-tooltip-text {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}