@use '@angular/material' as mat;
@mixin landing-search-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .landing-page-search {
        background-color: mat.get-color-from-palette($background, card);
        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, .06);
        &--sticky {
            @include media-breakpoint("gt-sm") {
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0);
            }
        }
        &-filter {
            .rpc-btn-link,
            .rpc-menu-btn-link {
                background: mat.get-color-from-palette($background, card);
            }
        }
    }
    &.rpc-landing-page--sticky {
        .toolbar-wrap--single {
            .toolbar-global {
                .mat-toolbar.toolbar {
                    @include media-breakpoint("gt-sm") {
                        box-shadow: 0 3px 20px rgba(0, 0, 0, 0);
                        border-bottom-color: mat.get-color-from-palette($foreground, divider);
                    }
                }
            }
        }
    }
}