@mixin appointment-form-common() {
    .apnt-t,
    .apnt-c {
        &-appointment-form-wrap {
            .app {
                &-form {
                    &-btn {
                        display: block;
                        flex: 0 0 calc(50% - 7px);
                        &:not(:first-of-type) {
                            margin-left: 14px;
                        }
                        .rpc-btn-link {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
    .appointment-form-trigger {
        &--sm {
            .app {
                &-form {
                    &-box {
                        grid-template-columns: 1fr 1fr;
                        grid-template-areas:
                            "date date"
                            "start-time end-time"
                            "comment comment";
                        @media screen and (max-width: 400px) {
                            grid-template-columns: 1fr;
                            grid-template-areas:
                                "date"
                                "start-time"
                                "end-time"
                                "comment";
                        }
                    }
                }
            }
        }
    }
    .app {
        &-form {
            &-date,
            &-time {
                cursor: pointer;
                .mat {
                    &-form {
                        &-field {
                            &-wrapper {
                                padding: 0;
                            }
                            &-infix {
                                width: auto;
                                input {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    &-button {
                        &-focus-overlay,
                        &-ripple {
                            display: none;
                        }
                    }
                }
            }
            &-comment {
                .mat {
                    &-form {
                        &-field {
                            &-wrapper {
                                padding-bottom: 0 !important;
                            }
                        }
                    }
                    &-input {
                        &-element {
                            resize: none;
                            height: 60px;
                            @include media-breakpoint("xs") {
                                height: 100px;
                            }
                        }
                    }
                }
            }
            &-btn {
                &-wrap {
                    .rpc-btn-link-text {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
