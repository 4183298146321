@use '@angular/material' as mat;
@mixin my-appointments-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .header {
        &-nav {
            &-btn {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        background-color: mat.get-color-from-palette($background, card);
                        &:hover {
                            .rpc-btn-link {
                                &-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                        }
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text)
                            }
                        }
                    }
                    &.mat-button-disabled {
                        opacity: .3;
                    }
                    &-icon {
                        color: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
        }
    }
}