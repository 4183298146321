.main-table {
    width: 100%;
    min-width: 500px;
    &-wrap {
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        touch-action: auto;
        -ms-touch-action: auto;
        -webkit-overflow-scrolling: touch;
    }

    tr {
        &.mat-row,
        &.mat-footer-row,
        &.mat-header-row {
            height: auto;
        }
        &.mat-row {
            &:last-of-type {
                td {
                    &.mat-cell {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    td {
        &.mat-cell,
        &.mat-footer-cell {
            font-size: 14px !important;
            line-height: 1.17;
            font-weight: 400;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            padding: 13px 8px !important;
        }
    }

    th.mat-header-cell {
        font-size: 14px !important;
        line-height: 1.2;
        font-weight: 500 !important;
        text-transform: uppercase;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding: 10px 8px !important;
        white-space: nowrap;
    }
}
