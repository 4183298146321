@mixin agent-short-info-common() {
    .agent-short-info {
        &-img {
            .avatar-initials-wrap {
                .text,
                .main-image {
                    font-size: 22px;
                    width: 2.5em;
                    height: 2.5em;
                    line-height: 2.5em;
                }
            }
        }
    }
}
