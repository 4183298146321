@mixin remove-folder-dialog-common() {
    .delete-folder-modal {
        max-width: 100% !important;
        @include media-breakpoint("gt-xs") {
            max-width: 80vw !important;
        }
        .mat-dialog-container {
            padding: 0;
        }
    }
    .rfd {
        &-checkbox {
            .mat-checkbox-layout {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                .mat-checkbox {
                    &-inner-container {
                        display: block;
                        margin: 4px 8px 4px 0;

                    }
                    &-label {
                        display: block;
                        font-size: 18px;
                        font-weight: 500;
                        white-space: normal;
                    }
                }
            }
        }
    }
}
