@mixin side-menu-common() {
    .mobile {
        &-menu {
            @include media-breakpoint("xs") {
                max-width: 256px !important;
            }
        }
        &-side {
            &-wrap {
                .divider {
                    margin-left: 16px;
                }
            }
            &-agents {
                .pai {
                    &-wrap {
                        grid-template-columns: 32px calc(100% - 42px);
                        column-gap: 10px;
                    }
                    &-avatar {
                        font-size: 16px;
                    }
                }
            }
            &-pwa {
                .rpc-btn-link {
                    width: 100%;
                }
            }
        }
        &-customer-info {
            .main-image,
            .text {
                font-size: 20px;
                width: 2em;
                height: 2em;
                line-height: 2em;
            }
        }
        &-nav {
            &,
            &-secondary {
                .rpc-btn-link {
                    width: 100%;
                    &.badge-wrap {
                        .rpc-btn-link-text {
                            margin-right: 30px;
                        }
                        .badge-count {
                            min-width: 20px;
                            top: 50%;
                            right: 16px;
                            transform: translateY(-50%);
                        }
                    }
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
                .rpc-menu-btn-link {
                    width: 100%;
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
            }
            &-secondary {
                .rpc-btn-link {
                    width: 100%;
                    .mat-button-wrapper {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}
