@mixin commute-time-common() {
    .rpc-commute-time {
        .rpc-commute-time-item-input {
            &-wrap {
                &--from {
                    .mat-form-field {
                        pointer-events: none;
                    }
                }
            }
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 0;
                }
                &-flex {
                    column-gap: 3px;
                }
                &-subscript-wrapper {
                    margin: 0;
                }
            }
        }
    }
}