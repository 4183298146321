@use '@angular/material' as mat;
@mixin agent-selection-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $is-dark: map-get($theme, is-dark);

    .agent-selection {
        &-wrap {
            #{--start-top-color}: #344a73;
            #{--stop-top-color}: #8c4d98;
            #{--start-bottom-color}: #466196;
            #{--stop-bottom-color}: #a25aaf;

            #{--bubble-start-color}: #354973;
            #{--bubble-middle-color}: #634c88;
            #{--bubble-stop-color}: #924f9e;

            border-color: mat.get-color-from-palette($foreground, divider);
            background-color: mat.get-color-from-palette($background, card);
        }
        &-block {
            &:before {
                background-image: linear-gradient(to top right, var(--bubble-start-color), var(--bubble-stop-color));
            }
            &:after {
                background-image: linear-gradient(to top right, var(--bubble-start-color), var(--bubble-middle-color) 49%, var(--bubble-stop-color));
            }
        }
        &-decoration {
            .bg-top {
                fill: url(#gradientTop);
                &-start-color {
                    stop-color: var(--start-top-color);
                }
                &-stop-color {
                    stop-color: var(--stop-top-color);
                }
            }
            .bg-bottom {
                opacity: 0.64;
                fill: url(#gradientBottom);
                &-start-color {
                    stop-color: var(--start-bottom-color);
                }
                &-stop-color {
                    stop-color: var(--stop-bottom-color);
                }
            }
        }
    }
}
