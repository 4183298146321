@import "src/app/modules/map/components/price-marker/price-marker.common";
@import "src/app/modules/map/components/map-cluster/map-cluster.common";
@import "src/app/modules/map/components/map-layout/map-layout.common";
@import "src/app/modules/map/components/map-controls/map-controls.common";

$max-height: 400px;

/* Fix Google Map Fullscreen Mode */

body {
    &.map-fullscreen-wrap {
        &.rpc-ld-page-wrap {
            .rpc-ld-body {
                position: static !important;
                &-map {
                    &-box {
                        position: static !important;
                        google-map {
                            .map-container {
                                position: absolute !important;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                z-index: 20;
                            }
                        }
                    }
                }
            }
        }
        on-market {
            flex: 1 0 100%;
            @include media-breakpoint("gt-xs") {
                flex: 0 0 auto;
            }
        }
        .toolbar-wrap {
            visibility: hidden;
            opacity: 0;
        }
        .global-content-wrap {
            margin: 0 !important;
            height: 100% !important;
            padding: 0 !important;
        }
        .listings-map {
            bottom: 0;
        }
        .listings-wrap {
            margin: 0;
            width: 100%;
            padding: 0;
            min-height: 100%;
        }
        .listings-title,
        .listings-sided-btn {
            visibility: hidden;
            opacity: 0;
        }
        .listings-sided {
            height: 100% !important;
        }
        .multi-select--desktop,
        .multi-select--mobile,
        .agent-contacts-popover,
        .scroll-to-top-wrap,
        .global-content .global-footer,
        .global-footer {
            visibility: hidden;
            opacity: 0;
        }
        .listings-sided-map-box {
            position: fixed;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            width: auto !important;
            border-radius: 0;
            z-index: 100;
        }
    }
}

body {
    @include price-marker-common();
    @include map-cluster-common();
    @include map-layout-common();
    @include map-controls-common();
}

.gm-style {
    .gm-style-iw {
        padding: 0 !important;
        max-width: 280px !important;
        max-height: 380px !important;
        border-radius: 10px;
        @media screen and (max-height: $max-height) {
            max-height: 180px !important;
            max-width: 460px !important;
        }
        @media screen and (max-height: 350px) {
            max-height: 170px !important;
        }
        &-c,
        &-d {
            overflow: visible !important;
            clear: both;
            border-radius: 10px;
        }
        &-d {
            position: relative;
            z-index: 1;
            min-width: 280px !important;
            max-height: 380px !important;
            @media screen and (max-height: $max-height) {
                max-height: 180px !important;
                max-width: 460px !important;
            }
            @media screen and (max-height: 350px) {
                max-height: 170px !important;
            }
            & + button {
                border-radius: 50% !important;
                opacity: 1 !important;
                z-index: 3;
                top: -12px !important;
                right: -12px !important;
                transition:
                    background-color .5s cubic-bezier(0.35, 0, 0.25, 1),
                    color .5s cubic-bezier(0.35, 0, 0.25, 1);
                &:before,
                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    top: 14px;
                    left: 7px;
                    width: 16px;
                    border-top-width: 2px;
                    border-top-style: solid;
                    border-top-color: currentColor;
                    z-index: 5;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
                span {
                    display: none !important;
                }
            }
        }
        &-t {
            &:after {
                top: -1px !important;
            }
        }
        &-tc {
            &:after {
                top: 14px;
            }
        }
    }
    .info-window-wrap {
        .search-results-view-gallery {
            max-height: 394px;
            overflow-y: auto;
            overscroll-behavior-y: contain;
            overflow-anchor: none;
            -ms-overflow-style: none;
            touch-action: auto;
            -ms-touch-action: auto;
            -webkit-overflow-scrolling: touch;
            border-radius: 10px;
            @media screen and (max-height: $max-height) {
                max-height: 180px;
            }
            @media screen and (max-height: 350px) {
                max-height: 170px;
            }
            &-item {
                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
// Point of interest on Google map
.poi-info-window {
    padding: 12px;
    border-radius: 10px;
    .title {
        width: 100%;
        margin-bottom: 10px;
    }
    .address {
        margin-bottom: 5px;
        padding-bottom: 10px;
    }
}

.cm-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin: 5px;
    cursor: pointer;
    &--rectangle,
    &--circle {
        position: relative;
        &:after {
            display: block;
            content:'';
            width: 26px;
            height: 26px;
            border-width: 1.5px;
            border-style: solid;
        }
    }
    &--circle {
        &:after {
            border-radius: 50%;
        }
    }
    &--delete {
        display: none;
        &.cm-btn--show {
            display: flex;
        }
        .rpci {
            font-size: 30px !important;
        }
    }
    .rpci {
        display: block;
        font-size: 24px;
        width: 1em;
        height: 1em;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;
    }
}
