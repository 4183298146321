@mixin rpc-menu-button-common() {
    .rpc-menu-btn-link {
        font-size: 20px!important;
        height: 40px;
        line-height: 40px;
        .mat-button-focus-overlay {
            opacity: 0!important;
        }
        .mat-button {
            &-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}