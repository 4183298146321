@use '@angular/material' as mat;
@mixin rpc-input-controls-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-textarea {
        &.mat-form-field {
            .mat-error {
                color: mat.get-color-from-palette($warn, 500);
            }
            &-label,
            &-suffix,
            &-prefix {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-invalid {
                .mat-form-field {
                    &-ripple {
                        background-color: mat.get-color-from-palette($warn, 500) !important;
                    }
                    &-label {
                        color: mat.get-color-from-palette($warn, 500) !important;
                    }
                }
                .mat-input-element {
                    color: mat.get-color-from-palette($warn, 500);
                }
            }
            &.mat-focused {
                .mat-form-field {
                    &-ripple {
                        background-color: mat.get-color-from-palette($foreground, text) !important;
                    }
                    &-label,
                    &-outline {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            &.mat-form-field-appearance {
                &-fill,
                &-legacy {
                    .mat-form-field-underline {
                        &:before {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .rpc-input-button-wrapper {
        .mat-form-field {
            &-suffix {
                .rpc-input-button {
                    color: mat.get-color-from-palette($accent, default-contrast);
                }
            }
        }
        &.mat-form-field-appearance {
            &-fill,
            &-legacy {
                .mat-form-field-underline {
                    &:before {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.rpc-textarea {
    &.mat-form-field {
        display: block;
        width: 100%;
        &-disabled {
            .mat-form-field {
                &-flex {
                    pointer-events: none;
                }
                &-autofill-control {
                    opacity: 0.5;
                }
            }
        }
        &-suffix,
        &-prefix {
            display: flex;
        }
        &-prefix {
            margin-right: 5px;
            margin-left: -6px;
        }
        &-subscript-wrapper {
            position: static;
            padding: 0 !important;
        }
        &.mat-form-field-appearance {
            &-fill,
            &-legacy {
                .mat-form-field {
                    &-flex {
                        padding: 8px 15px 0;
                    }
                    &-underline {
                        position: static;
                    }
                    &-ripple {
                        height: 1px;
                    }
                    &-prefix {
                        margin-top: 6px;
                    }
                }
            }
            &-outline {
                .rpc-input-icon {
                    &[matsuffix] {
                        font-size: 18px;
                    }
                }
                .mat-form-field {
                    &-flex {
                        padding: 0 1.2em 0 1.2em;
                    }
                    &-infix {
                        padding: 0.25em 0 0.75em !important;
                    }
                    &-outline {
                        &-start,
                        &-end,
                        &-gap {
                            border-width: 1px;
                        }
                        &-start {
                            min-width: 5px;
                        }
                    }
                    &-label {
                        top: 1.35em !important;
                    }
                }
            }
        }
        &-should-float {
            &.mat-form-field-appearance {
                &-outline {
                    .mat-form-field {
                        &-label {
                            margin-top: 3px !important;
                        }
                    }
                }
            }
        }
        .mat-input-element {
            min-height: 60px;
            max-height: 120px;
        }
    }
}
.rpc-input-button-wrapper {
    &.mat-form-field {
        display: block;
        &-appearance {
            &-fill,
            &-legacy {
                .mat-form-field {
                    &-flex {
                        padding: 8px 15px 0;
                    }
                    &-underline {
                        position: static;
                    }
                    &-ripple {
                        height: 1px;
                    }
                    &-prefix {
                        margin-top: 6px;
                    }
                    &-suffix {
                        top: -8px !important;
                    }
                }
            }
            &-outline {
                .rpc-input-icon {
                    &[matsuffix] {
                        font-size: 18px;
                    }
                }
                .mat-form-field {
                    &-flex {
                        padding: 0 1.2em 0 1.2em;
                    }
                    &-infix {
                        padding: 0.25em 0 0.75em !important;
                    }
                    &-outline {
                        &-start,
                        &-end,
                        &-gap {
                            border-width: 1px;
                        }
                        &-start {
                            min-width: 5px;
                        }
                    }
                    &-suffix {
                        top: -2px !important;
                    }
                    &-label {
                        top: 1.35em !important;
                    }
                }
            }
        }
        &-subscript-wrapper {
            position: static;
            padding: 0 !important;
        }
    }
    .mat-form-field {
        &-infix {
            width: 100%;
            padding-right: 5px !important;
            textarea {
                &.mat-input-element {
                    max-height: 350px;
                    min-height: 54px;
                }
            }
        }
        &-suffix {
            .rpc-input-button {
                margin-right: -5px;
            }
        }
    }
}
