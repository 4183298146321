@mixin rpc-tooltip-common() {
    .tooltip {
        &-item {
            display: grid;
            grid-template-columns: 20px calc(100% - 25px);
            grid-template-rows: 30px;
            column-gap: 5px;
            align-items: center;
            user-select: none;
            &:not(:first-of-type) {
                margin-top: 10px;
            }
            &-icon {
                display: block;
                font-size: 20px;
            }
            &-text {
                font-size: 16px !important;
                line-height: 1.19;
                font-weight: 400;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
