@use '@angular/material' as mat;
@mixin toolbar-base($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .header {
        &-title {
            &-note {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-view-item {
            &--mobile {
                .rpc-menu-icon-btn {
                    &.active {
                        &:not(.mat-button-disabled) {
                            .rpc-menu-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
            }
        }
        .divider {
            background-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}
