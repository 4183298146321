@mixin scroll-to-top-common() {
    .scroll-to-top-wrap {
        &.upper {
            bottom: 110px;
            right: 12px;
            @include media-breakpoint("gt-xs") {
                bottom: 50px;
                right: 15px;
            }
        }
    }
    .scroll-to-top {
        .rpc-icon-btn {
            &-icon {
                font-size: 20px;
                .rpci {
                    stroke-width: 0.5px;
                }
            }
            .mat-button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }
    }
}
