@mixin profile-info-base-common() {
    .profile {
        &-item {
            &-header {
                &-btn {
                    .rpc-btn-link {
                        padding: 0 10px;
                        margin-right: -10px;
                        &-text {
                            font-size: 12px;
                            line-height: 1.17;
                            font-weight: 500;
                            letter-spacing: 0.18px;
                        }
                        &-icon {
                            font-size: 18px;
                        }
                    }
                }
            }
            &-input {
                .mat-form-field {
                    &-wrapper {
                        padding-bottom: 0 !important;
                    }
                    &-subscript-wrapper {
                        margin: 0 !important;
                    }
                }
                .rpc-input {
                    margin-bottom: 20px;
                }
                .combined-phone {
                    &-wrap {
                        padding-bottom: 0px;
                        margin-bottom: 20px;
                    }
                    &-select {
                        &-wrap {
                            width: 80px;
                            .mat-form-field-appearance-outline {
                                .mat-select-arrow-wrapper {
                                    transform: translateY(0);
                                    margin-right: -5px;
                                }
                            }
                            .mat-select-value {
                                margin-right: 3px !important;
                            }
                        }
                    }
                    &-input {
                        &-wrap {
                            width: calc(100% - 85px);
                        }
                    }
                }
            }
            &-hint {
                &-toggle {
                    display: block;
                    .mat-slide-toggle-content {
                        flex: 0 0 calc(100% - 45px);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.19;
                    }
                }
            }
            .mat-form-field-flex {
                padding: 0 12px !important;
            }
            .rpc-select {
                margin-bottom: 20px;
                .mat-form-field {
                    &-wrapper {
                        padding-bottom: 0 !important;
                    }
                    &-flex {
                        padding: 0 12px 0 9px !important;
                    }
                    &-subscript-wrapper {
                        margin: 0 !important;
                    }
                }
            }
            .profile {
                &-item {
                    &-input {
                        .mat-form-field-wrapper {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
