@use '@angular/material' as mat;
@mixin app-page($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    .refresher {
        .loading-bar {
            background-color: mat.get-color-from-palette($accent, default);
        }
    }
    .theme-options-button {
        background: mat.get-color-from-palette($accent, default);
        color: mat.get-color-from-palette($accent, default-contrast);
    }
    .toolbar-secondary{
        background-color:mat.get-color-from-palette($background, card);
        @include media-breakpoint('gt-sm'){
            background-color:transparent;
        }
        &--minimized{
            background-color:mat.get-color-from-palette($background, card);
        }
    }
}
