@use '@angular/material' as mat;
@mixin appointment-form($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    $warn: map-get($theme, warn);

    .app {
        &-form {
            &-time {
                &-dropdown {
                    background: mat.get-color-from-palette($background, card);
                }
                &.mat-form-field-appearance-outline {
                    &.mat-focused {
                        &.mat-accent {
                            .mat-form-field {
                                &-outline-thick {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                        }
                        &.mat-primary {
                            .mat-form-field {
                                &-outline-thick {
                                    color: mat.get-color-from-palette($primary, default);
                                }
                            }
                        }
                        .mat-form-field-label {
                            &.mat-accent {
                                color: mat.get-color-from-palette($accent, default);
                            }
                            &.mat-primary {
                                color: mat.get-color-from-palette($primary, default);
                            }
                        }
                    }
                    .mat-form-field {
                        &-label {
                            color: mat.get-color-from-palette($foreground, secondary-text, if($is-dark, 0.7, 0.6));
                        }
                        &-outline-thick {
                            color: mat.get-color-from-palette($foreground, text);
                        }
                    }
                }
            }
            &-error {
                &-wrap {
                    color: mat.get-color-from-palette($warn, default);
                }
            }
        }
    }
}
