@use '@angular/material' as mat;
@mixin mortgage-calculator-form($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .mc {
        &-toggler {
            .mat-slide-toggle {
                .mat-slide-toggle {
                    &-bar {
                        background-color: mat.get-color-from-palette($accent, default, .54);
                    }
                    &-thumb {
                        background-color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
    }
}
