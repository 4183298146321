@mixin email-alert-settings-common() {
    .notif-alert {
        &-item {
            &-select {
                .mat-form-field {
                    &-wrapper {
                        padding: 0 !important;
                        margin: 0;
                    }
                    &-subscript-wrapper {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
}