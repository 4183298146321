// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$berkshire-theme-primary-palette: mat.define-palette($berkshire-primary-color);
$berkshire-theme-accent-palette: mat.define-palette($berkshire-accent-color);
$berkshire-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$berkshire-theme: mat.define-light-theme(
    $berkshire-theme-primary-palette,
    $berkshire-theme-accent-palette,
    $berkshire-theme-warn-palette
);
// Add ".theme-berkshire" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-berkshire {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($berkshire-theme);

    // Apply the theme to the user components
    @include components-theme($berkshire-theme);
}
