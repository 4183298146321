@use '@angular/material' as mat;
@mixin internal-listings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .listings {
        &-wrap {
            &.summary-view {
                .listings {
                    &-box {
                        .listings-empty {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
        }
        &-empty {
            color: mat.get-color-from-palette($foreground, secondary-text);
            --secondary: #{mat.get-color-from-palette($foreground, disabled)};
            --bg: #{mat.get-color-from-palette($background, card)};
        }
        &-sided {
            &-btn {
                .rpc-btn-link {
                    background: mat.get-color-from-palette($background, card);
                    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0);
                    &.active {
                        box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
                        .rpc-btn-link-icon {
                            color: mat.get-color-from-palette($foreground, disabled);
                        }
                    }
                }
            }
            .as-split-gutter {
                background-color: transparent;
                &-icon {
                    background-color: mat.get-color-from-palette($accent, default);
                }
            }
        }
    }
}
