.badge {
    &-wrap {
        position: relative;
        &.rpc-icon-btn{
            .badge-count{
                top:0;
                right:0;
            }
        }
        .badge-count {
            position: absolute;
            font-size: 11px;
            top: 4px;
            right: 4px;
            min-width: 20px;
            height: 16px;
            line-height: 12px;
            font-weight:500;
            padding:2px 4px;
            text-align: center;
            border-radius: 5px;
            background: var(--has-comments);
            color: var(--has-comments-color);
            &--new {
                background: var(--has-new-comments);
                color: var(--has-new-comments-color);
            }
        }
    }
}
