@mixin base-listings-common() {
    .listings {
        &-wrap {
            .agent-contacts-popover {
                display: none;
                position: absolute;
                bottom: 40px;
                right: 65px;
                z-index: 10;
                @include media-breakpoint("gt-sm") {
                    display: block;
                }
            }
            .multi-select--desktop {
                &.multi-select--desktop-max {
                    .multi-selection--active {
                        grid-template-columns: 95px 1px 120px 1px 331px;
                    }
                }
            }
            .scroll-to-top-wrap {
                &.upper {
                    &.scroll-to-top-wrap--up {
                        @include media-breakpoint("gt-xs") {
                            bottom: 110px;
                        }
                        @include media-breakpoint("gt-sm") {
                            bottom: 50px;
                        }
                    }
                }
            }
            .multi-select--mobile-pure {
                &.multi-select--mobile-restore {
                    .multi-selection--active {
                        grid-template-columns: 150px calc(100% - 286px) 40px 40px 40px;
                        grid-template-areas: "checkbox . folder activities restore";
                    }
                }
                .multi-selection--active {
                    grid-template-columns: 150px calc(100% - 242px) 40px 40px;
                    grid-template-areas: "checkbox . folder activities";
                }
            }
            .multi-select--mobile-restore {
                .multi-selection--active {
                    grid-template-columns: 145px calc(100% - 305px) 40px 40px 40px 40px;
                    grid-template-areas: "checkbox . folder activities delete restore";
                    column-gap: 0;
                }
            }
        }
        &-sided {
            &-btn {
                .badge-wrap{
                    .badge-count {
                        top: -7px;
                        right: auto;
                        left: -7px;
                    }
                }
            }
        }
    }
}
