@mixin folder-management-common() {
    .fm {
        max-width: 100vw !important;
        width: 100%;
        @include media-breakpoint("gt-xs") {
            max-width: 80vw !important;
            width: auto;
        }
        .mat-dialog-container {
            padding: 0;
            @include media-breakpoint("gt-sm") {
                overflow: visible;
                -webkit-overflow-scrolling: initial;
            }
        }
    }
    .folder-edit-wrap {
        &.mat-menu-panel {
            width: 280px;
        }
    }
}