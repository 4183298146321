@use "@angular/material" as mat;
@mixin listing-list($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .f-listing {
        &-item {
            &--removed {
                .f-listing-item {
                    &-img {
                        opacity: .5;
                    }
                    &-address {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-price {
                        .price-simple {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
            &-removed {
                color: mat.get-color-from-palette($accent, default);
                border-color: currentColor;
            }
        }
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
