@use '@angular/material' as mat;
@mixin internal-listing-details-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .ld {
        &-toolbar {
            &-back {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-btn-link {
                                &-icon,
                                &-text {
                                    color: mat.get-color-from-palette($foreground, text);
                                }
                            }
                        }
                    }
                }
            }
            &-nav {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-btn-link-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                        }
                    }
                }
            }
            &-share {
                .rpc-menu-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-menu-icon-btn-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
            }
        }
    }
}
