@mixin remove-listing-tooltip-common() {
    .pick-remove-listing-btn {
        .rpc-btn-link {
            .rpc-btn-link {
                &-text {
                    font-size: 16px;
                    letter-spacing: .21px;
                }
            }
        }
    }
}