@mixin notification-group-common() {
    .rpc-notification-group {
        &-listing-img {
            .default-image-wrap {
                .broken-background-image,
                .main-background-image {
                    background-size: cover;
                    border-radius: 6px;
                }
                .broken-background-image {
                    background-size: 90% !important;
                }
            }
        }
        &-block {
            &-avatar {
                .avatar-initials-wrap {
                    .text,
                    .main-image {
                        font-size: 17px;
                        width: 34px;
                        height: 34px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}
