@use '@angular/material' as mat;
@mixin listing-details-base($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    &.rpc-ld-page-wrap {
        @if ($is-dark) {
            background-color: mat.get-color-from-palette($background, background);
        } @else {
            background-color: mat.get-color-from-palette($background, card);
        }
    }

    .rpc-ld {
        &-page {
            .single-select {
                &--mobile {
                    background: mat.get-color-from-palette($accent, default);
                    &.single-select {
                        &--ld {
                            .activity-controls-wrapper {
                                .activity-controls-item {
                                    &.liked,
                                    &.shown,
                                    &.disliked,
                                    &.picklisted {
                                        .rpc-icon-btn {
                                            &:hover {
                                                background-color: mat.get-color-from-palette($accent, default-contrast);
                                            }
                                            &.active {
                                                background-color: mat.get-color-from-palette($accent, default-contrast);
                                            }
                                            &:not(.mat-button-disabled) {
                                                &.rpc-icon-btn {
                                                    &.active {
                                                        .rpc-icon-btn-icon {
                                                            color: mat.get-color-from-palette($accent, default);
                                                        }
                                                    }
                                                    &:not(.active) {
                                                        &:hover {
                                                            .rpc-icon-btn-icon {
                                                                color: mat.get-color-from-palette($accent, default);
                                                            }
                                                        }
                                                        .rpc-icon-btn-icon {
                                                            color: mat.get-color-from-palette($accent, default-contrast);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .activity-controls-wrapper {
                        .rpc-icon-btn {
                            &:not(.mat-button-disabled) {
                                &:hover {
                                    .rpc-icon-btn-icon {
                                        color: mat.get-color-from-palette($accent, default-contrast, .75);
                                        --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                                    }
                                }
                                .rpc-icon-btn-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                    --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                    --text-color: #{mat.get-color-from-palette($accent, default)};
                                }
                            }
                            &.mat-button-disabled {
                                .rpc-icon-btn-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                                    --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                                }
                            }
                        }
                        & > rpc-button {
                            .rpc-btn-link {
                                &:not(.mat-button-disabled) {
                                    &:hover {
                                        .rpc-btn-link {
                                            &-text,
                                            &-icon {
                                                color: mat.get-color-from-palette($accent, default-contrast, .75);
                                                --main-color: #{mat.get-color-from-palette($accent, default-contrast, .75)};
                                            }
                                        }
                                    }
                                    .rpc-btn-link {
                                        &-text,
                                        &-icon {
                                            color: mat.get-color-from-palette($accent, default-contrast);
                                            --main-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                            --text-color: #{mat.get-color-from-palette($accent, default)};
                                        }
                                    }
                                }
                                &.mat-button-disabled {
                                    .rpc-btn-link {
                                        &-text,
                                        &-icon {
                                            color: mat.get-color-from-palette($accent, default-contrast, .3) !important;
                                            --main-color: #{mat.get-color-from-palette($accent, default-contrast, .3)} !important;
                                        }
                                    }
                                }
                            }
                        }
                        .mat-divider-vertical {
                            border-right-color: mat.get-color-from-palette($accent, default-contrast);
                        }
                    }
                }
            }
        }
        &-header {
            &-info-type {
                &.s {
                    background-color: var(--sales-flag);
                    color: #fff;
                }
                &.r {
                    background-color: var(--rentals-flag);
                    color: rgba(0,0,0,.87);
                }
            }
            &-crossroad {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-additional-price {
                &-item {
                    &-title {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        &-media {
            &-section {
                &-item {
                    background-color: mat.get-color-from-palette($background, card);
                    color: mat.get-color-from-palette($foreground, text);
                    border-color: mat.get-color-from-palette($foreground, divider);
                    &:hover {
                        background-color: mat.get-color-from-palette($primary, default-contrast);
                        color: mat.get-color-from-palette($primary, default);
                    }
                }
            }
        }
        &-nav {
            &-section {
                background-color: mat.get-color-from-palette($background, card);
            }
            &-oh {
                color: mat.get-color-from-palette($foreground, text);
                &:hover {
                    .rpc-ld-nav-oh {
                        &-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &-label {
                    background-color: mat.get-color-from-palette($primary, default);
                    color: mat.get-color-from-palette($primary, default-contrast);
                }
                &-time,
                &-date,
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-item {
                &--picked {
                    .rpc-icon-btn {
                        &:not(.mat-button-disabled) {
                            &.active {
                                color: var(--picklist-color);
                                .rpc-icon-btn-icon {
                                    color: currentColor;
                                }
                            }
                        }
                    }
                    &-full {
                        .rpc-btn-link {
                            &:not(.mat-button-disabled) {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                                &.active {
                                    color: var(--picklist-color);
                                }
                                .rpc-btn-link {
                                    &-icon,
                                    &-text {
                                        color: currentColor;
                                    }
                                }
                            }
                        }
                    }
                }
                &--removed {
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &:hover,
                            &.active {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                            .rpc-btn-link {
                                &-icon,
                                &-text {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                }
                            }
                        }
                    }
                }
                &--folder {
                    &-full {
                        .rpc-btn-link {
                            &:not(.mat-button-disabled) {
                                &:hover {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                }
                                .rpc-btn-link {
                                    &-icon {
                                        color: mat.get-color-from-palette($foreground, secondary-text);
                                    }
                                    &-text {
                                        color: mat.get-color-from-palette($foreground, secondary-text);
                                    }
                                }
                            }
                        }
                    }
                }
                &--history {
                    .rpc-menu-icon-btn {
                        &:not(.mat-button-disabled) {
                            &:hover,
                            &.active {
                                color: mat.get-color-from-palette($accent, default);
                                .rpc-menu-icon-btn-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                            &.rpc-menu-icon-btn {
                                &--opened {
                                    color: mat.get-color-from-palette($accent, default);
                                    .rpc-menu-icon-btn-icon {
                                        color: mat.get-color-from-palette($accent, default);
                                    }
                                }
                            }
                        }
                    }
                }

            }
            &-communication {
                color: mat.get-color-from-palette($foreground, text);
                &:hover {
                    .rpc-ld-nav-communication {
                        &-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
                &.rpc-ld-nav-communication--empty {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    &:hover {
                        .rpc-ld-nav-communication {
                            &-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                    .rpc-ld-nav-communication {
                        &-icon {
                            color: mat.get-color-from-palette($foreground, disabled);        
                        }
                    }
                }
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            &-info {
                &-main {
                    .mat-checkbox-layout {
                        .mat-checkbox-label {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
            &-actions {
                &-folder {
                    .rpc-btn-link {
                        &:not(.mat-accent) {
                            &:hover {
                                .rpc-btn-link {
                                    &-icon {
                                        color: mat.get-color-from-palette($accent, default);
                                        --main-color: #{mat.get-color-from-palette($accent, default)};
                                        --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                                    }
                                }    
                            }
                            .rpc-btn-link {
                                &-icon {
                                    color: mat.get-color-from-palette($foreground, secondary-text);
                                    --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                                }
                            }
                        }
                    }
                }
            }
        }
        &-body {
            &-header {
                .rpc-ld-nav-info {
                    &-btn {
                        color: mat.get-color-from-palette($foreground, text);
                        &:hover {
                            color: mat.get-color-from-palette($accent, default);
                            .rpc-ld-nav-info-btn-icon {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
            &-item {
                &-title {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
            &-top {
                &-item {
                    color: mat.get-color-from-palette($foreground, text);
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-features {
                &-item {
                    &-title {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-text {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            &-map {
                &-box {
                    border-color: mat.get-color-from-palette($foreground, divider);
                }
            }
            &-price-changes {
                .main-table {
                    background-color: transparent;
                }
            }
        }
        &-chat {
            &-button {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
            }
        }
    }

    &.is-mobile {
        .rpc-ld-page {
            .single-select {
                &--mobile {
                    &.single-select {
                        &--ld {
                            .activity-controls-wrapper {
                                .activity-controls-item {
                                    &.liked,
                                    &.shown,
                                    &.disliked,
                                    &.picked {
                                        .rpc-icon-btn {
                                            &:hover {
                                                background-color: transparent !important;
                                            }
                                            &.active {
                                                background-color: mat.get-color-from-palette($accent, default-contrast) !important;
                                            }
                                            &:not(.mat-button-disabled) {
                                                &.rpc-icon-btn {
                                                    &.active {
                                                        .rpc-icon-btn-icon {
                                                            color: mat.get-color-from-palette($accent, default) !important;
                                                        }
                                                    }
                                                    &:not(.active) {
                                                        .rpc-icon-btn-icon {
                                                            color: mat.get-color-from-palette($accent, default-contrast) !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}