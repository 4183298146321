@use '@angular/material' as mat;
@mixin rpc-dropdown-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-dropdown {
        &-btn {
            &:not(:first-child) {
                .rpc-btn-link,
                .rpc-menu-btn-link {
                    &-text {
                        &:before {
                            border-top-color: mat.get-color-from-palette($foreground, divider);
                        }
                    }
                }
            }
            &.inactive {
                .rpc-btn-link,
                .rpc-menu-btn-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        &-menu {
            box-shadow: 0 3px 40px 0 rgba(0,0,0,.12) !important;
        }
    }
    .mat-elevation-z4 {
        box-shadow: 0 3px 40px 0 rgba(0,0,0,.12) !important;
    }
}

.rpc-dropdown {
    &-btn {
        display: block;
        position: relative;
        width: 100%;
        &:not(:first-child) {
            .rpc-btn-link,
            .rpc-menu-btn-link {
                &-text {
                    &:before {
                        position: absolute;
                        display: block;
                        content: "";
                        top: 0px;
                        right: 16px;
                        left: 16px;
                        height: 1px;
                        z-index: 5;
                        border-top: 1px solid;
                    }
                }
                &-icon {
                    & ~ .rpc-btn-link-text,
                    & ~ .rpc-menu-btn-link-text {
                        &:before {
                            left: 51px;
                        }
                    }
                }
            }
        }
        .rpc-btn-link,
        .rpc-menu-btn-link {
            display: block;
            width: 100%;
            &-icon {
                & ~ .rpc-btn-link-text,
                & ~ .rpc-menu-btn-link-text {
                    margin-left: 10px !important;
                }
            }
            &-text {
                font-size: 0.8em !important;
            }
            .mat-button-wrapper {
                justify-content: flex-start;
            }
        }
    }
}
.rpc-menu-wrap,
.rpc-dropdown-menu {
    &.mat-menu-panel {
        border-radius: 10px;
    }
}
