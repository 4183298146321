@mixin change-agent-dialog-common() {
    .ca-dialog {
        max-width: 90vw !important;
        .mat-dialog-container {
            padding: 0;
            overflow-x: hidden;
            .agent-selection {
                &-block {
                    &:before,
                    &:after {
                        display: none;
                    }
                }
                &-list {
                    max-height: 30vh;
                    overflow-y: auto;
                    margin: 0 -10px 15px;
                    padding: 0 10px;
                    @include media-breakpoint('gt-sm') {
                        max-height: 38vh;
                        margin: 0 0 20px;
                        padding: 0;
                    }
                }
            }
        }
    }
}