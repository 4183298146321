// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$corcoran-theme-primary-palette: mat.define-palette($primary-color);
$corcoran-theme-accent-palette: mat.define-palette($corcoran-accent-color);
$corcoran-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$corcoran-dark-theme: mat.define-dark-theme(
    $corcoran-theme-primary-palette,
    $corcoran-theme-accent-palette,
    $corcoran-theme-warn-palette
);

// Add ".theme-corcoran" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-corcoran-dark {
    // Generate the Angular Material theme
    @include mat.all-component-themes($corcoran-dark-theme);

    // Apply the theme to the user components
    @include components-theme($corcoran-dark-theme);
    @include corcoran-common($corcoran-dark-theme);
}
