@use '@angular/material' as mat;
@mixin core-sidebar-theme($theme) {

    $background: map-get($theme, background);

    core-sidebar {
        background: mat.get-color-from-palette($background, background);
    }

    .core-sidebar-overlay {
        background-color: rgba(0, 0, 0, 0.6);

        &.core-sidebar-overlay-invisible {
            background-color: transparent;
        }
    }
}