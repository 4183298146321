@use '@angular/material' as mat;
@mixin registration-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .register {
        .auth {
            &-form {
                &-wrap {
                    .forgot-password {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
            &-terms {
                &-wrap {
                    a {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }
    .logout {
        &-description {
            background-color: mat.get-color-from-palette($accent, 50);
            &-icon {
                color: mat.get-color-from-palette($accent, default);
            }
            &-text {
                @if ($is-dark) {
                    color: mat.get-color-from-palette($accent, '50-contrast');
                } @else {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
    }
}
