@use '@angular/material' as mat;
@mixin auth-base-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .auth {
        &-wrap {
            border-color: mat.get-color-from-palette($foreground, divider);
            background-color: mat.get-color-from-palette($background, card);
            // @if ($is-dark) {
            //     background-color: mat-color($foreground, 600);
            // } @else {
            //     background-color: mat-color($background, card);
            // }
        }
        &-decoration {
            .bg-top {
                fill: url(#gradientTop);
                &-start-color {
                    stop-color: #394975;
                }
                &-stop-color {
                    stop-color: #864e98;
                }
            }
            .bg-bottom {
                opacity: 0.635;
                fill: url(#gradientBottom);
                &-start-color {
                    stop-color: #466196;
                }
                &-stop-color {
                    stop-color: #a25aaf;
                }
            }
        }
    }
}
