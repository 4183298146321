@mixin folder-attachment-common() {
    .folder-attachment-dialog {
        max-width: 100% !important;
        width: 100%;
        @include media-breakpoint("gt-xs") {
            width: auto;
            min-width: 300px;
            max-width: 80vw !important;
        }
        @include media-breakpoint("gt-sm") {
            min-width: 400px;
        }
        .mat-dialog-container {
            padding: 0;
        }
    }
    .fad {
        &-item {
            .rpc-btn-link {
                display: block;
                &-text {
                    margin: 0 !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .mat-button-wrapper {
                    display: grid;
                    grid-template-columns: 26px minmax(0, 1fr);
                    column-gap: 5px;
                    align-items: center;
                    justify-content: start;
                }
            }
        }
    }
}