@use '@angular/material' as mat;
@mixin rpc-info($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-info {
        background-color: var(--info-500-15);
        color: var(--info-500);
        &--error {
            background-color: var(--error-500-15);
            color: var(--error-500);
        }
        &--warn {
            background-color: var(--warn-500-15);
            color: var(--warn-500);
        }
        &--success {
            background-color: var(--success-500-15);
            color: var(--success-500);
        }
    }
}