@use '@angular/material' as mat;
@mixin listing-open-house($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .oh-list {
        &-item {
            color: mat.get-color-from-palette($foreground, text);
            border-color: mat.get-color-from-palette($foreground, divider);
            &-icon {
                color: mat.get-color-from-palette($accent, default);
            }
            &-date {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}
