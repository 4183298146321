@use '@angular/material' as mat;
@mixin chatbot-dialog-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $is-dark: map-get($theme, is-dark);
    .rpc-chatbot {
        &-dialog {
            .mat-dialog-container {
                box-shadow: 10px 0 40px 0 rgba(0, 0, 0, 0.12);
            }
        }
    }
}