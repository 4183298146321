@use '@angular/material' as mat;
@mixin price-marker($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .map-marker-wrap {
        &--listing {
            &-active {
                .map-marker {
                    @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                        background: mat.get-color-from-palette($warn, default);
                    } @else {
                        background: mat.get-color-from-palette($accent, default);
                    }
                    &:before {
                        @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                            border-top-color: mat.get-color-from-palette($warn, default);
                        } @else {
                            border-top-color: mat.get-color-from-palette($accent, default);
                        }
                    }
                    .map-marker {
                        &-price {
                            & > div {
                                @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                                    color: mat.get-color-from-palette($warn, default-contrast);
                                } @else {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                }
                            }
                        }
                        &-checkbox {
                            &.mat-checkbox {
                                &-checked,
                                &-indeterminate {
                                    .mat-checkbox {
                                        &-frame {
                                            border-color: mat.get-color-from-palette($background, card);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .map-marker {
        background: mat.get-color-from-palette($primary, default);
        &:before {
            border-top-color: mat.get-color-from-palette($primary, default);
        }
        &--simple {
            background: transparent;
        }
        &-icon {
            &--simple {
                color: mat.get-color-from-palette($primary, default);
            }
        }
        &-checkbox {
            &.mat-checkbox {
                &-checked,
                &-indeterminate {
                    .mat-checkbox {
                        &-background {
                            background-color: transparent !important;
                        }
                    }
                }
            }
            .mat-checkbox {
                &-background {
                    background-color: mat.get-color-from-palette($foreground, disabled) !important;
                }
                &-frame {
                    border-color: #555555;
                }
            }
        }
        &-price {
            & > div {
                color: mat.get-color-from-palette($primary, default-contrast);
            }
        }
    }
}
