@mixin landing-message-card-common() {
    .lm-card {
        .main-gallery-card {
            .main-gallery-card {
                &-body {
                    padding-bottom: 0;
                }
                &-price {
                    padding-bottom: 10px;
                }
            }
        }
    }
}