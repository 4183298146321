@mixin comments-common() {
    .comment-wrap-trigger {
        &--md {
            .comments {
                &-wrap {
                    margin: 0 -10px;
                }
                &-item {
                    grid-template-areas: "avatar main .";
                    grid-template-columns: 50px calc(100% - 130px) 80px;
                    &--customer {
                        grid-template-areas: ". main avatar";
                        grid-template-columns: 80px calc(100% - 130px) 50px;
                        .comments {
                            &-img-wrap {
                                margin-left: 5px !important;
                            }
                        }
                    }
                }
            }
        }
        &--sm {
            .comments {
                &-item {
                    grid-template-areas: "main .";
                    grid-template-columns: calc(100% - 30px) 30px;
                    &--customer {
                        grid-template-areas: ". main";
                        grid-template-columns: 30px calc(100% - 30px);
                    }
                }
                &-img-wrap {
                    display: none;
                }
            }
        }
    }
    .comments {
        &-img {
            &-wrap {
                .avatar-initials-wrap {
                    display: block;
                    .text,
                    .main-image {
                        display: block;
                        width: 4.5rem;
                        height: 4.5rem;
                        line-height: 4.5rem;
                    }
                }
            }
        }
        &-body {
            &-btn {
                .rpc-icon-btn {
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    &-icon {
                        font-size: 1em;
                    }
                }
            }
        }
        &-field {
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 4px !important;
                }
            }
        }
    }
}
