@mixin simple-dialog-common() {
    .confirm {
        &-dialog {
            @include media-breakpoint("xs") {
                max-width: none !important;
            }
            .mat-dialog-container {
                @include media-breakpoint("xs") {
                    padding: 8px 14px;
                }
            }
            .confirm {
                &-btn {
                    .rpc-btn-link {
                        &-text {
                            text-transform: uppercase;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
