@mixin calendar-appointment-common() {
    .apnt-c-appointment {
        &-group {
            &-dropdown {
                &-wrap {
                    .apnt-c-appointment-box {
                        height: auto;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        margin: 0;
                    }
                }
            }
        }
        &-info-wrap {
            &--unconfirmed {
                &.apnt-c-appointment-info-wrap {
                    .apnt-t {
                        &-card-box {
                            height: 435px !important;
                            .main-gallery-card {
                                &-body {
                                    padding-bottom: 0;
                                }
                            }
                        }
                        &-appointment-update-wrap {
                            position: static;
                            margin: -40px -10px 0;
                            &--decline {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
            &--confirmed {
                &.apnt-c-appointment-info-wrap {
                    .apnt-t {
                        &-card-box {
                            height: 475px !important;
                            .main-gallery-card {
                                &-body {
                                    padding-bottom: 0;
                                }
                            }
                        }
                        &-appointment-update-wrap {
                            position: static;
                            margin: 0px -10px 0;
                        }
                    }
                }
            }
            &.mat-menu-panel {
                width: 278px;
                max-width: none;
                min-width: 1px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 10px;
            }
            .mat-menu-content {
                padding: 0;
            }
            .apnt-t-card {
                &-box {
                    height: 445px;
                }
            }
            .apnt-t-appointment {
                &-wrap {
                    display: block;
                    border-radius: 0;
                    padding: 10px 15px 11px;
                }
                &-info {
                    &-wrap {
                        &--requestor {
                            margin-bottom: 10px;
                            &-only {
                                margin-bottom: 43px;
                            }
                        }
                    }
                }
            }
        }
    }
}