@mixin rpc-file-upload-common() {
    .rpc-file {
        &-img {
            &-btn {
                .rpci {
                    font-size: 20px;
                }
            }
        }
    }
}