@use '@angular/material' as mat;
@mixin folder-item($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .folder {
        background-color: mat.get-color-from-palette($background, card);
        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
        &-header {
            &-icon {
                color: mat.get-color-from-palette($accent, default);
                &-wrap {
                    color: mat.get-color-from-palette($accent, default, .1);
                }
            }
            &-name {
                color: mat.get-color-from-palette($foreground, text);
                &-badge {
                    background-color: mat.get-color-from-palette($warn, default);
                    color: mat.get-color-from-palette($warn, default-contrast);
                    @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                        background-color: mat.get-color-from-palette($warn, default);
                        color: mat.get-color-from-palette($warn, default-contrast);
                    } @else {
                        background-color: mat.get-color-from-palette($accent, default);
                        color: mat.get-color-from-palette($accent, default-contrast);
                    }
                }
            }
            &-counter {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-body {
            &-empty {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-footer {
            .chips {
                color: mat.get-color-from-palette($foreground, disabled);
                background-color: mat.get-color-from-palette($background, hover);
                &.chips--active {
                    color: mat.get-color-from-palette($accent, default);
                    background-color: rgba(255,255,255,1);
                    &:after {
                        background-color: mat.get-color-from-palette($accent, default, .2);
                    }
                    &.liked {
                        color: var(--like-color);
                        &:after {
                            background-color: var(--like-color-200);
                        }
                    }
                    &.nm {
                        color: var(--nm-500);
                        &:after {
                            background-color: var(--nm-200);
                        }
                    }
                    &.na {
                        color: var(--na-500);
                        &:after {
                            background-color: var(--na-200);
                        }
                    }
                }
                &:before {
                    @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                        background-color: var(--has-new-comments);
                    } @else {
                        background-color: mat.get-color-from-palette($accent, default);
                    }
                }
                &:after {
                    background-color: currentColor;
                }
            }
        }
    }
}