@use '@angular/material' as mat;
@mixin agent-box($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);
    .agent-box {
        background-color: mat.get-color-from-palette($background, background);
        border-color: mat.get-color-from-palette($background, background);
        &:hover,
        &--active {
            &:not(.agent-box--current) {
                border-color: mat.get-color-from-palette($accent, default);
                .agent-box {
                    &-info {
                        &-item-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
            }
        }
        &--active {
            @if ($is-dark) {
            } @else {
                background-color: mat.get-color-from-palette($accent, 50);
            }
        }
        &-status {
            color: mat.get-color-from-palette($accent, default);
        }
        &-login {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-company {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-info {
            color: mat.get-color-from-palette($foreground, secondary-text);
            &-item {
                &-icon {
                    &:after {
                        border-color: mat.get-color-from-palette($background, card);
                        @if (mat.get-color-from-palette($primary, default) == mat.get-color-from-palette($accent, default)) {
                            background-color: mat.get-color-from-palette($warn, default);
                        } @else {
                            background-color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
            }
        }
    }
}