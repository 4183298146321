@mixin landing-search-common() {
    .landing-page-search {
        &-filter {
            &--toggle {
                .rpc-button-toggle-wrap {
                    --border-radius: 10px;
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    align-items: stretch;
                    .mat-button-toggle {
                        &-button {
                            font-size: 16px;
                            height: 44px;
                            @include media-breakpoint("gt-sm") {
                                height: 48px;
                            }
                        }
                    }
                }
            }
            &--omni {
                .chip {
                    &-wrap {
                        width: 100%;
                        height: 90px;
                        @include media-breakpoint("gt-xs") {
                            height: 44px;
                        }
                        @include media-breakpoint("gt-sm") {
                            height: 48px;
                        }
                        &.chip-wrap--focused {
                            .mat-form-field-flex {
                                bottom: 0 !important;
                                @include media-breakpoint("gt-xs") {
                                    bottom: -40px !important;
                                }
                            }
                            .chip-list-wrap {
                                height: 88px;
                                @include media-breakpoint("gt-xs") {
                                    height: 82px;
                                }
                                @include media-breakpoint("gt-sm") {
                                    height: 86px;
                                }
                            }
                        }
                        .mat-form-field-infix {
                            height: 100%;
                            overflow: hidden;
                        }
                    }
                    &-list {
                        &-box {
                            position: relative;
                            display: block;
                            height: 100%;
                            .mat-chip-list-wrapper {
                                position: absolute;
                                top: 1px;
                                left: 0;
                                right: 0;
                                bottom: 1px;
                                height: auto;
                                z-index: 10;
                                margin: 0;
                            }
                            .chip {
                                &-icon {
                                    margin: 9px 0;
                                    @include media-breakpoint("gt-sm") {
                                        margin: 11px 0;
                                    }
                                }
                                &-list {
                                    column-gap: 5px;
                                    row-gap: 5px;
                                    padding: 6px 0;
                                    @include media-breakpoint("gt-sm") {
                                        padding: 8px 0;
                                    }
                                    &-empty {
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        margin: 0;
                                        &-text {
                                            font-size: 16px;
                                            padding-top: 7px;
                                            padding-bottom: 7px;
                                        }
                                    }
                                    &-wrap {
                                        height: 88px;
                                        @include media-breakpoint("gt-xs") {
                                            height: 42px;
                                        }
                                        @include media-breakpoint("gt-sm") {
                                            height: 46px;
                                        }
                                    }
                                    .mat-chip {
                                        & ~ .chip-list-empty {
                                            display: none;
                                        }
                                    }
                                    .chip-list-empty {
                                        & ~ .chip-input {
                                            position: absolute;
                                            left: -9999px;
                                        }
                                    }
                                }
                                &-item {
                                    margin: 0;
                                }
                                &-input {
                                    height: 30px;
                                }
                            }
                        }
                        &-empty {
                            &-text {
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
            &--select {
                .rpc-menu-btn-link {
                    display: block;
                    width: 100%;
                    border-radius: 10px;
                    height: 44px;
                    line-height: 44px;
                    @include media-breakpoint("gt-sm") {
                        height: 48px;
                        line-height: 48px;
                    }
                    &-text {
                        font-size: 16px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .mat-button-wrapper {
                        justify-content: space-between;
                    }
                }
            }
        }
        &-btn {
            .rpc-btn-link {
                display: block;
                width: 100%;
                border-radius: 10px;
                height: 44px;
                line-height: 44px;
                min-width: 44px;
                @include media-breakpoint("gt-sm") {
                    padding: 0;
                    height: 48px;
                    line-height: 48px;
                    min-width: 48px;
                }
                &-text {
                    @include media-breakpoint("gt-sm") {
                        display: none;
                    }
                }
            }
        }
    }
    &.rpc-landing-page--sticky {
        .toolbar-wrap--single {
            .toolbar-global {
                .mat-toolbar.toolbar {
                    @include media-breakpoint("gt-sm") {
                        border-bottom-width: 1px;
                        border-bottom-style: solid;
                    }
                }
            }
        }
    }
}