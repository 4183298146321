@use '@angular/material' as mat;
@mixin external-listings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .external-listings {
        &-container {
            &-add {
                color: mat.get-color-from-palette($accent, default);
                background-color: mat.get-color-from-palette($background, card);
                box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.06);
                &:hover {
                    color: mat.get-color-from-palette($accent, default-contrast);
                    background-color: mat.get-color-from-palette($accent, default);
                }
            }
        }
        &-card-empty {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}