@mixin folder-edit-common() {
    .folder-edit {
        width: 100%;
        max-width: 410px !important;
        .mat-dialog-container {
            padding: 0;
            border-radius: 0;
            box-shadow: none !important;
            overflow-x: hidden;
            overflow-y: auto;
        }
        &-close {
            .rpc-btn-link {
                min-width: 40px;
                padding: 0;
            }
        }
        &-box {
            .folder-edit-action-wrap {
                margin-bottom: 0;
            }
        }
    }
}