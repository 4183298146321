@mixin listing-bell-common() {
    .mat-menu-panel {
        &.listing-notifications-dropdown {
            width: 100%;
            max-width: 360px;
        }
    }
    .card-communication {
        &-item {
            &-icon {
                .badge-count {
                    top: auto;
                    right: -5px;
                    bottom: -2px;
                }
            }
        }
    }
}