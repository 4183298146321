@mixin my-appointments-toolbar-common() {
    .header {
        &--appointments {
            .filter-menu {
                &--sm {
                    display: none;
                    @include media-breakpoint("gt-xs") {
                        display: block;
                    }
                    @include media-breakpoint("gt-sm") {
                        display: none;
                    }
                    .rpc-menu-btn-link {
                        padding-left: 7px;
                        margin-left: -10px;
                    }
                }
                &--main {
                    @include media-breakpoint("gt-xs") {
                        display: none;
                    }
                    @include media-breakpoint("gt-sm") {
                        display: flex;
                    }
                }
            }
        }
        &-nav {
            &-btn {
                &--today {
                    .rpc-btn-link {
                        width: auto !important;
                        border-radius: 10px !important;
                    }
                }
                .rpc-btn-link {
                    min-width: 1px;
                    width: 40px;
                    border-radius: 8px;
                    &-text {
                        font-weight: 500;
                    }
                    .badge-count {
                        top: -3px;
                        right: -6px;
                        @include media-breakpoint("gt-xs") {
                            top: -5px;
                            right: -5px;
                        }
                    }
                }
            }
        }
    }
    .filter-dropdown {
        .filter-menu-title-wrap {
            display: none;
        }
    }
}
