@use '@angular/material' as mat;
@mixin base-listings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .agent-contacts-popover {
        .agent-contact-wrap {
            background: mat.get-color-from-palette($background, card);
        }
    }
}
