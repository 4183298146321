@use '@angular/material' as mat;
@mixin show-more-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .truncate-t {
        &-wrap {
            &--active {
                .truncate-t {
                    &-icon {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        &-icon {
            &:hover {
                color: mat.get-color-from-palette($accent, default);
            }
        }
    }
}

.truncate-t {
    &-wrap {
        position: relative;
        cursor: pointer;
        transition: height .2s linear;
    }
    &-text {
        padding-right: 1.5em;
        font-size: inherit;
    }
    &-icon {
        position: absolute;
        top: 0;
        right: 0;
        font-size: inherit !important;
        z-index: 5;
    }
}