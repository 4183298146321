@use "@angular/material" as mat;
@mixin rpc-datepicker($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-date-wrap {
        &--hidden {
            .mat-datepicker-toggle {
                &-active {
                    &.mat-accent {
                        .mat-icon-button {
                            background-color: mat.get-color-from-palette($accent, default);
                            .rpc-datepicker-icon {
                                color: mat.get-color-from-palette($background, card) !important;
                            }
                        }
                    }
                }
                &.mat-accent {
                    .mat-icon-button {
                        background-color: mat.get-color-from-palette($accent, default);
                        .rpc-datepicker-icon {
                            color: mat.get-color-from-palette($accent, default-contrast);
                        }
                    }
                }
            }
        }
        .mat-form-field {
            .mat-error {
                color: mat.get-color-from-palette($warn, 500);
            }
            &-label,
            &-suffix,
            &-prefix {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-invalid {
                .mat-form-field {
                    &-ripple {
                        background-color: mat.get-color-from-palette($warn, 500) !important;
                    }
                    &-label {
                        color: mat.get-color-from-palette($warn, 500) !important;
                    }
                }
                .mat-input-element {
                    color: mat.get-color-from-palette($warn, 500);
                }
            }
            &.mat-focused {
                .mat-form-field {
                    &-ripple {
                        background-color: mat.get-color-from-palette($foreground, text) !important;
                    }
                    &-label,
                    &-outline {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            &.field {
                &-fill,
                &-legacy {
                    .mat-form-field-underline {
                        &:before {
                            background-color: transparent;
                        }
                    }
                }
                &-outline {
                    .mat-datepicker-toggle {
                        &-active {
                            &.mat-accent {
                                .rpc-datepicker-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                            &.mat-primary {
                                .rpc-datepicker-icon {
                                    color: mat.get-color-from-palette($primary, default);
                                }
                            }
                        }
                    }
                    .rpc-datepicker-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                        &--clear {
                            &:hover {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
            }
        }
    }

    .mat-datepicker {
        &-content {
            &.mat-accent {
                mat-custom-header {
                    background-color: mat.get-color-from-palette($accent, default);
                    color: mat.get-color-from-palette($accent, default-contrast);
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    .mat-custom-controls {
                        .mat-custom-time-ampm {
                            .mat-custom-control-active {
                                color: mat.get-color-from-palette($accent, default);
                                background-color: mat.get-color-from-palette($accent, default-contrast);
                            }
                        }
                    }
                }
                .mat-calendar-body-selected {
                    color: mat.get-color-from-palette($accent, default-contrast);
                }
            }
            .mat {
                &-clock {
                    background-color: mat.get-color-from-palette($background, background);
                    &-center,
                    &-hand {
                        background-color: mat.get-color-from-palette($accent, default);
                    }
                    &-hand {
                        &:before {
                            background-color: mat.get-color-from-palette($accent, default);
                        }
                    }
                    &-cell {
                        color: mat.get-color-from-palette($foreground, text);
                        &.mat-clock-cell {
                            &-disabled {
                                color: mat.get-color-from-palette($foreground, disabled);
                            }
                            &-selected {
                                color: mat.get-color-from-palette($background, card);
                                background-color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
                &-calendar {
                    &-next,
                    &-previous {
                        &-button {
                            &:after {
                                border-color: mat.get-color-from-palette($background, card);
                            }
                        }
                    }
                }
            }
        }
    }
}
