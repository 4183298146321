@use '@angular/material' as mat;
@mixin listing-details-internal($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-ld {
        &-notes-btn {
            color: mat.get-color-from-palette($foreground, text);
            border-color: mat.get-color-from-palette($foreground, divider);
            &:hover {
                .rpc-ld-notes-btn-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
            &.rpc-ld-notes-btn--active {
                .rpc-ld-notes-btn-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
            &-icon {
                color: mat.get-color-from-palette($foreground, disabled);
            }
            &-text {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}