@use '@angular/material' as mat;
@mixin media-slider($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .slider {
        &-btn {
            background: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, text);
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
            &:hover {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-item {
            &--external {
                background: mat.get-color-from-palette($background, card);
                border-color: mat.get-color-from-palette($foreground, divider);
                &:hover {
                    .slider-item {
                        &-icon,
                        &-text {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
            }
            &-text,
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-dots {
            background-color: mat.get-color-from-palette($background, card, .75);
            li {
                background-color: rgba(0,0,0,0);
                &.active {
                    background-color: mat.get-color-from-palette($accent, default);
                }
            }
        }
        &-counter {
            background: mat.get-color-from-palette($background, card);
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
        }
        &-thumb {
            &-item {
                &--external{
                    background: mat.get-color-from-palette($background, card);
                    border-color: mat.get-color-from-palette($foreground, divider);
                    &:hover,
                    &.slider-thumb-item--active {
                        .slider-thumb-item {
                            &-icon,
                            &-text {
                                color: mat.get-color-from-palette($accent, default);
                            }
                        }
                    }
                }
                &-icon
                &-text {
                    color: mat.get-color-from-palette($foreground, text);
                }
            }
        }
    }
}
