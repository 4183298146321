@mixin checkbox-avatar-filter-common() {
    .filter-dropdown {
        &-box {
            &:not(mat-menu) {
                &.filter-dropdown-box--checkbox-wrap {
                    .caf-title {
                        margin: 0 15px;
                    }
                }
            }
        }
        &:not(mat-menu) {
            &.filter-dropdown--checkboxes-list {
                .caf-item {
                    &-divider {
                        display: block;
                    }
                    .mat-checkbox-layout {
                        padding: 8px 15px;
                    }
                }
            }
        }
    }
    .caf {
        &-item {
            &.mat-checkbox-label-before {
                .mat-checkbox-inner-container {
                    margin-right: 0;
                }
            }
            .mat-checkbox {
                &-layout {
                    display: flex;
                    padding: 5px 0;
                    .mat-checkbox-label {
                        display: grid;
                        grid-template-columns: 40px minmax(0, 1fr);
                        align-items: center;
                        column-gap: 10px;
                        width: calc(100% - 24px);
                    }
                }
            }
        }
    }
}
