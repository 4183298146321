@mixin landing-banner-common() {
    .landing-banner {
        &-info {
            &-btn {
                .rpc-btn-link {
                    border-radius: 20px;
                }
            }
        }
    }
}