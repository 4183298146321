@mixin appointment-common() {
    .appointment-wrap-trigger {
        &--xl {
            .appointment {
                &-action {
                    &-wrap {
                        &--agent-pending {
                            display: grid;
                            grid-template-columns: 1fr auto auto auto;
                            grid-template-areas:
                                ". export divider-export edit"
                                ". accept accept decline";
                            .appointment {
                                &-action {
                                    &--export {
                                        grid-area: export;
                                    }
                                    &--divider-export {
                                        grid-area: divider-export;
                                    }
                                    &--edit {
                                        grid-area: edit;
                                    }
                                    &--accept {
                                        grid-area: accept;
                                    }
                                    &--decline {
                                        grid-area: decline;
                                    }
                                }
                            }
                        }
                        &--confirmed {
                            .appointment-action {
                                &--mobile {
                                    display: block;
                                }
                                &--desktop {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        &--md,
        &--sm {
            .appointment {
                &-box {
                    grid-template-columns: calc(100% - 150px) 140px;
                    grid-template-areas:
                        "author date"
                        "author-second ."
                        "action action";
                    &--requestor-only {
                        grid-template-areas:
                            "author date"
                            "action action";
                    }
                    &--comment {
                        grid-template-areas:
                            "author date"
                            "author-second ."
                            "comment comment"
                            "action action";
                        &.appointment-box--requestor-only {
                            grid-template-areas:
                                "author date"
                                "comment comment"
                                "action action";
                        }
                    }
                }
                &-author {
                    &-name {
                        max-width: 280px;
                    }
                }
                &-action {
                    &-wrap {
                        border-top-width: 1px;
                        border-top-style: solid;
                        margin-top: 5px;
                        margin-bottom: -10px;
                        display: grid;
                        grid-template-columns: auto auto auto 1fr auto auto auto auto;
                        grid-template-areas: "export divider-export edit . accept decline divider-delete delete";
                        justify-content: space-between;
                    }
                    &--export {
                        grid-area: export;
                    }
                    &--divider {
                        &-export {
                            grid-area: divider-export;
                        }
                        &-delete {
                            grid-area: divider-delete;
                        }
                    }
                    &--edit {
                        grid-area: edit;
                    }
                    &--accept {
                        grid-area: accept;
                    }
                    &--decline {
                        grid-area: decline;
                    }
                    &--delete {
                        grid-area: delete;
                    }
                }
            }
        }
        &--sm {
            .appointment {
                &-wrap {
                    padding: 10px 10px 8px;
                }
                &-box {
                    grid-template-columns: 100%;
                    grid-template-areas:
                        "author"
                        "author-second"
                        "date"
                        "action";
                    &--requestor-only {
                        grid-template-areas:
                            "author"
                            "date"
                            "action";
                    }
                    &--comment {
                        grid-template-areas:
                            "author"
                            "author-second"
                            "date"
                            "comment"
                            "action";
                        &.appointment-box--requestor-only {
                            grid-template-areas:
                                "author"
                                "date"
                                "comment"
                                "action";
                        }
                        .appointment {
                            &-comment {
                                width: 100%;
                                margin-left: 0;
                            }
                        }
                    }
                }
                &-date {
                    &-wrap {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        align-items: center;
                        text-align: initial;
                        padding-top: 5px;
                    }
                    &-time {
                        margin-bottom: 4px;
                    }
                }
                &-status {
                    @media screen and (max-width: 360px) {
                        width: 100%;
                    }
                    &-wrap {
                        @media screen and (max-width: 360px) {
                            flex-wrap: wrap;
                        }
                    }
                    &-creator {
                        @media screen and (max-width: 360px) {
                            width: 100%;
                        }
                    }
                }
                &-author {
                    &-img-wrap {
                        margin-right: 5px;
                    }
                    &-name {
                        max-width: 180px;
                    }
                }
                &-action {
                    &-wrap {
                        grid-template-columns: auto auto auto auto auto auto;
                        grid-template-areas: "export divider-export edit accept decline delete";
                        &--confirmed,
                        &--customer-pending {
                            grid-template-columns: auto auto auto 1fr auto;
                        }
                        &--customer-pending {
                            grid-template-areas: "export divider-export edit . delete";
                        }
                        &--confirmed {
                            grid-template-areas: "export divider-export edit . decline";
                            margin: 5px -6px -5px;
                        }
                        &--agent-pending {
                            grid-template-columns: repeat(2, auto);
                            grid-template-areas:
                                "export edit"
                                "accept decline";
                            margin: 5px -6px -5px;
                            .appointment-action {
                                &--divider-export {
                                    display: none;
                                }
                                &--edit {
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: stretch;
                                }
                            }
                        }
                    }
                    &-divider {
                        &-delete {
                            display: none;
                        }
                    }
                    &--mobile {
                        display: block;
                    }
                    &--desktop {
                        display: none;
                    }
                    &-action {
                        &--accept,
                        &--decline {
                            .rpc-btn-link {
                                &-icon {
                                    font-size: 1.2em !important;
                                }
                            }
                        }
                        .rpc-btn-link,
                        .rpc-menu-btn-link {
                            &-icon {
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    .appointment {
        &-action {
            &--accept,
            &--decline {
                .rpc-btn-link {
                    &-icon {
                        font-size: 1.4em !important;
                    }
                    &-text {
                        letter-spacing: 0.35px;
                    }
                }
            }
            .rpc-btn-link,
            .rpc-menu-btn-link {
                min-width: 1px;
                padding: 0 5px;
                &-icon {
                    font-size: 1.2em;
                }
            }
        }
    }
}
