@use '@angular/material' as mat;
@mixin knowledge-base-item-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .kb {
        &-card {
            &-label {
                background-color: mat.get-color-from-palette($background, card);
                color: mat.get-color-from-palette($foreground, secondary-text);
                border-color: mat.get-color-from-palette($foreground, divider);
                &-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
            &-title {
                color: mat.get-color-from-palette($foreground, text);
                &:hover {
                    color: mat.get-color-from-palette($primary, default);
                }
            }
            &-description {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-modal {
            &-backdrop {
                &.cdk-overlay-backdrop {
                    background-color: rgba(0,0,0,.54);
                }
            }
            .mat-dialog-container {
                background-color: transparent;
                box-shadow: none;
            }
            .rpc-mg-close {
                .mat-button {
                    background-color: mat.get-color-from-palette($background, card);
                }
            }
        }
    }
}