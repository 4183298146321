@use '@angular/material' as mat;
@mixin nm-short-info($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .nm-short-info {
        &-label {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-folder {
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}