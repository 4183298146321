@use '@angular/material' as mat;
@mixin phone-form-input-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .combined-phone{
        &-error{
            color: map-get($warn, default);
        }
        &-wrap{
            .mat-form-field {
                .mat-error {
                    color: mat.get-color-from-palette($warn, 500);
                }
                &-label,
                &-suffix {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-invalid {
                    .mat-form-field {
                        &-ripple {
                            background-color: mat.get-color-from-palette($warn, 500)!important;
                        }
                        &-label{
                            color: mat.get-color-from-palette($warn, 500)!important;
                        }
                    }
                    .mat-input-element,
                    .mat-select-value {
                        color: mat.get-color-from-palette($warn, 500);
                    }
                }
                &.mat-focused {
                    .mat-form-field {
                        &-ripple {
                            background-color: mat.get-color-from-palette($foreground, text);
                        }
                        &-label,
                        &-outline{
                            color: mat.get-color-from-palette($foreground, text);
                        }
                    }
                }
                &.mat-form-field {
                    &-appearance {
                        &-fill,
                        &-legacy {
                            .mat-form-field-underline {
                                &:before {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}  