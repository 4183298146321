@mixin omni-search-common() {
    .chip {
        &-wrap {
            &--focused {
                .mat {
                    &-form-field {
                        &-flex {
                            bottom: -76px !important;
                        }
                    }
                }
            }
            &.mat-form-field {
                &-appearance {
                    &-outline {
                        &:not(.mat-form-field-disabled) {
                            .mat-form-field-flex {
                                &:hover {
                                    .mat-form-field-outline {
                                        &:not(.mat-form-field-outline-thick) {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                        .mat-form-field {
                            &-outline {
                                top: 0;
                                border-radius: 10px;
                                &-start,
                                &-end {
                                    min-width: 10px;
                                }
                                &-start {
                                    border-radius: 10px 0 0 10px;
                                }
                                &-end {
                                    border-radius: 0 10px 10px 0;
                                }
                                &-thick {
                                    .mat-form-field-outline {
                                        &-start,
                                        &-gap,
                                        &-end {
                                            border-width: 1px;
                                        }
                                    }
                                }
                            }
                            &-infix {
                                padding: 0 !important;
                                border-top: none;
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .mat {
                &-form-field {
                    &-wrapper {
                        margin: 0;
                        height: 100%;
                        padding-bottom: 0 !important;
                    }
                    &-flex {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: 0;
                    }
                    &-subscript-wrapper {
                        display: none;
                    }
                }
                &-chip {
                    &-list {
                        &-wrapper {
                            margin: 1px -5px;
                            height: 38px;
                            flex-wrap: nowrap;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
        &-dropdown {
            &-group {
                .mat {
                    &-optgroup {
                        &-label {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            text-transform: uppercase;
                            font-size: 12px !important;
                            font-weight: 400 !important;
                            letter-spacing: 0.24px !important;
                        }
                    }
                    &-option {
                        &:not(.mat-option-multiple) {
                            padding: 0 16px;
                        }
                        &-text {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}
