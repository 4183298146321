@use '@angular/material' as mat;
@mixin tabs-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .mat-tab {
        &-group {
            &.mat {
                &-accent {
                    .mat-tab {
                        &-label {
                            &-active {
                                .tab-link {
                                    &-icon,
                                    &-text {
                                        color: mat.get-color-from-palette($accent, default);
                                    }
                                }
                            }
                        }
                    }
                }
                &-primary {
                    .mat-tab {
                        &-label {
                            &-active {
                                .tab-link {
                                    &-icon,
                                    &-text {
                                        color: mat.get-color-from-palette($primary, default);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mat-tab {
                &-label {
                    .tab-link {
                        &-icon,
                        &-text {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                    }
                }
            }
        }
    }
}

.mat-tab-group {
    .mat-tab {
        &-label {
            opacity: 1;
            height: 60px;
            &-content {
                position: relative;
                .badge {
                    &-wrap {
                        position: static;
                    }
                    &-count {
                        right: -10px;
                        top: -4px;
                    }

                }
            }
            .tab {
                &-link {
                    &-icon {
                        margin-right: 6px;
                    }
                    &-text {
                        font-size: 16px;
                        line-height: 1.19;
                        text-transform: uppercase;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }
                }
            }
        }
        &-body {
            &-wrapper {
                overflow: visible;
                overflow-x: hidden;
            }
            &-content {
                padding: 30px 30px;
                overflow: visible;
                overflow-x: hidden;
            }
            &.mat-tab-body {
                overflow: visible;
                overflow-x: hidden;
                &-active {
                    overflow: visible;
                    overflow-x: hidden;
                }
            }
        }
    }
}
