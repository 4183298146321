@use '@angular/material' as mat;
@mixin landing-appointment-card-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);
    
    .la-card {
        &-footer {
            border-top-color: mat.get-color-from-palette($foreground, divider);
            &-icon {
                color: mat.get-color-from-palette($accent, default);
                &-wrap {
                    background-color: mat.get-color-from-palette($accent, default, .1);
                }
            }
            &-date {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-time {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-status {
                color: mat.get-color-from-palette($foreground, secondary-text);
                background-color: mat.get-color-from-palette($foreground, divider);
                &--confirmed {
                    color: var(--status-confirmed);
                    background-color: var(--status-confirmed-200);
                }
            }
        }
    }
}