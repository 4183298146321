@mixin listings-list() {
    .f-listing {
        &-item {
            &-img {
                .default-image-wrap {
                    &.default-image-wrap {
                        .main {
                            &-background-image {
                                &--default {
                                    background-size: 80%;
                                }
                            }
                        }
                        .broken {
                            &-background-image {
                                background-size: 80%;
                            }
                        }
                    }
                    .broken,
                    .main {
                        &-background-image {
                            border-radius: 6px;
                        }
                    }
                }
            }
            &-removed {
                .price-simple {
                    line-height: 28px;
                }
            }
        }
    }
}