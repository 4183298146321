@use '@angular/material' as mat;
@mixin comments($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    &.theme-bhs {
        .comments {
            &-item--customer {
                .comments-inner-body {
                    background-color: mat.get-color-from-palette($accent, 300);
                    &--edit {
                        background-color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
    }

    .comments {
        &-form-btn {
            &--cancel {
                .rpc-mini-fab-btn {
                    &:not(.mat-button-disabled) {
                        .rpc-mini-fab-btn-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
            }
        }
        &-divider {
            &:before {
                border-top-color: mat.get-color-from-palette($foreground, divider);
            }
            &-text {
                color: mat.get-color-from-palette($foreground, secondary-text);
                background-color: mat.get-color-from-palette($background, card);
            }
        }
        &-item {
            &--user {
                &-1 {
                    .avatar-initials-wrap {
                        .text {
                            background: #{var(--picklist-color)};
                        }
                    }
                }
                &-2 {
                    .avatar-initials-wrap {
                        .text {
                            background: #f16161;
                        }
                    }
                }
                &-3 {
                    .avatar-initials-wrap {
                        .text {
                            background: #4bd1f9;
                        }
                    }
                }
                &-4 {
                    .avatar-initials-wrap {
                        .text {
                            background: #ffb37c;
                        }
                    }
                }
                &-5 {
                    .avatar-initials-wrap {
                        .text {
                            background: #c694f3;
                        }
                    }
                }
            }
            &--customer {
                .comments {
                    &-inner {
                        &-body {
                            background-color: mat.get-color-from-palette($accent, 500);
                            &--edit {
                                background-color: mat.get-color-from-palette($foreground, secondary-text);
                                .comments {
                                    &-body,
                                    &-name {
                                        color: mat.get-color-from-palette($background, card);
                                    }
                                    &-body {
                                        &-btn {
                                            .rpc-icon-btn {
                                                &.mat-button-disabled {
                                                    .rpc-icon-btn-icon {
                                                        color: mat.get-color-from-palette($background, card) !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &-name,
                    &-body {
                        color: mat.get-color-from-palette($accent, default-contrast);
                    }
                    &-body {
                        &-btn {
                            .rpc-icon-btn {
                                &:not(.mat-button-disabled) {
                                    .rpc-icon-btn-icon {
                                        color: mat.get-color-from-palette($accent, default-contrast);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &-inner {
            &-body {
                background-color: mat.get-color-from-palette($background, background);
            }
        }
        &-time,
        &-label {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-status {
            color: #{var(--comment-read-color)};
        }
        &-name,
        &-body {
            color: mat.get-color-from-palette($background, background-contrast);
        }
        &-empty {
            &-wrap {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-show {
            &-more {
                color: mat.get-color-from-palette($primary, default);
            }
        }
    }
}
