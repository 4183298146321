@mixin activity-button-common() {
    .rpc-activity-button {
        .rpc-icon-btn {
            &.rpc-icon-btn--custom {
                width: 100%;
                min-width: 1px;
                border-radius: 6px;
            }
        }
    }
}