@mixin media-slider-common() {
    .slider {
        &-wrapper {
            * {
                user-select: none;
            }
        }
        &-btn {
            &-icon {
                .rpci {
                    stroke-width: 0.5px;
                }
            }
        }
    }
}
