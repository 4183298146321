@use "@angular/material" as mat;
@mixin activity-controls($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .activities {
        &-wrap {
            &--colored {
                .rpc-icon-btn {
                    &:hover,
                    &.active {
                        .rpc-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                    .rpc-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        &-menu {
            &-item {
                &.picklisted {
                    .rpc-btn-link {
                        &:hover,
                        &.active {
                            .rpc-btn-link-icon {
                                color: var(--picklist-color);
                            }
                        }
                    }
                }
                &.liked {
                    .rpc-btn-link {
                        &:hover,
                        &.active {
                            .rpc-btn-link-icon {
                                color: var(--like-color);
                            }
                        }
                    }
                }
                &.disliked {
                    .rpc-btn-link {
                        &:hover,
                        &.active {
                            .rpc-btn-link-icon {
                                color: var(--dislike-color);
                            }
                        }
                    }
                }
                &.shown {
                    .rpc-btn-link {
                        &:hover,
                        &.active {
                            .rpc-btn-link-icon {
                                color: var(--visit-color);
                            }
                        }
                    }
                }
                .rpc-btn-link {
                    .rpc-btn-link-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        .rpc-menu-btn-link {
            &:not(.mat-button-disabled) {
                &:hover,
                .active {
                    .rpc-menu-btn-link-icon {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
            &.mat-button-disabled {
                .rpc-menu-btn-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
            &--opened {
                .rpc-menu-btn-link-icon {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &--badge-icon {
                .rpc-menu-btn-link {
                    &-badge-icon {
                        color: var(--visit-color);
                    }
                }
            }
        }
        &.liked {
            &.shown,
            & {
                .rpc-menu-btn-link {
                    &.active {
                        color: var(--like-color);
                        .rpc-menu-btn-link {
                            &-icon {
                                color: var(--like-color);
                            }
                        }
                    }
                }
            }
        }
        &.shown {
            .rpc-menu-btn-link {
                &.active {
                    color: var(--visit-color);
                    .rpc-menu-btn-link {
                        &-icon {
                            color: var(--visit-color);
                        }
                    }
                }
            }
        }
        &.disliked {
            &.shown,
            & {
                .rpc-menu-btn-link {
                    &.active {
                        color: var(--dislike-color);
                        .rpc-menu-btn-link {
                            &-icon {
                                color: var(--dislike-color);
                            }
                        }
                    }
                }
            }
        }
        &.picklisted {
            .rpc-menu-btn-link {
                &.active {
                    color: var(--picklist-color);
                    .rpc-menu-btn-link {
                        &-icon {
                            color: var(--picklist-color);
                        }
                    }
                }
            }
        }
    }

    .activity-controls {
        &-wrapper {
            &:not(.activity-controls-wrapper--base) {
                .activity-controls {
                    &-item {
                        &.picklisted {
                            .rpc-icon-btn,
                            .rpc-menu-icon-btn {
                                &:not(.mat-button-disabled) {
                                    &.rpc-icon-btn,
                                    &.rpc-menu-icon-btn {
                                        &:hover,
                                        &.active {
                                            color: var(--picklist-color);
                                            .rpc-icon-btn-icon,
                                            .rpc-menu-icon-btn-icon {
                                                color: var(--picklist-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.liked {
                            .rpc-icon-btn,
                            .rpc-menu-icon-btn {
                                &:not(.mat-button-disabled) {
                                    &.rpc-icon-btn,
                                    &.rpc-menu-icon-btn {
                                        &:hover,
                                        &.active {
                                            color: var(--like-color);
                                            .rpc-icon-btn-icon,
                                            .rpc-menu-icon-btn-icon {
                                                color: var(--like-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.shown {
                            .rpc-icon-btn,
                            .rpc-menu-icon-btn {
                                &:not(.mat-button-disabled) {
                                    &.rpc-icon-btn,
                                    &.rpc-menu-icon-btn {
                                        &:hover,
                                        &.active {
                                            color: var(--visit-color);
                                            .rpc-icon-btn-icon,
                                            .rpc-menu-icon-btn-icon {
                                                color: var(--visit-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.disliked {
                            .rpc-icon-btn,
                            .rpc-menu-icon-btn {
                                &:not(.mat-button-disabled) {
                                    &.rpc-icon-btn,
                                    &.rpc-menu-icon-btn {
                                        &:hover,
                                        &.active {
                                            color: var(--dislike-color);
                                            .rpc-icon-btn-icon,
                                            .rpc-menu-icon-btn-icon {
                                                color: var(--dislike-color);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .activity-controls {
                &-item {
                    .rpc-icon-btn,
                    .rpc-menu-icon-btn {
                        &:not(.mat-button-disabled) {
                            &:hover,
                            &.active {
                                color: mat.get-color-from-palette($accent, default);
                                .rpc-icon-btn-icon,
                                .rpc-menu-icon-btn-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                            .rpc-icon-btn-icon,
                            .rpc-menu-icon-btn-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                        }
                    }
                }
            }
        }
    }
}
