@mixin folder-selection-common() {
    .f-item {
        &-checkbox {
            .mat-checkbox {
                &-layout {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}