@use "@angular/material" as mat;
@mixin rpc-menu-icon-button($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-menu-icon-btn {
        &:not(.mat-button-disabled) {
            &.rpc-menu-icon-btn--custom {
                &.rpc-menu-icon-btn {
                    background-color: mat.get-color-from-palette($background, hover);
                    &--opened,
                    &:hover,
                    &.active {
                        background-color: mat.get-color-from-palette($background, focused-button);
                    }
                }
            }
            &.rpc-menu-icon-btn {
                &--opened,
                &:hover,
                &.active {
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
            &.mat {
                &-accent {
                    &:hover,
                    &.active {
                        .rpc-menu-icon-btn-icon {
                            color: mat.get-color-from-palette($accent, default);
                            --main-color: #{mat.get-color-from-palette($accent, default)};
                            --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                        }
                    }
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($accent, default);
                        --main-color: #{mat.get-color-from-palette($accent, default)};
                        --text-color: #{mat.get-color-from-palette($accent, default-contrast)};
                    }
                }
                &-primary {
                    &:hover,
                    &.active {
                        .rpc-menu-icon-btn-icon {
                            color: mat.get-color-from-palette($primary, default);
                            --main-color: #{mat.get-color-from-palette($primary, default)};
                            --text-color: #{mat.get-color-from-palette($primary, default-contrast)};
                        }
                    }
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($primary, default);
                        --main-color: #{mat.get-color-from-palette($primary, default)};
                        --text-color: #{mat.get-color-from-palette($primary, default-contrast)};
                    }
                }
                &-warn {
                    &:hover,
                    &.active {
                        .rpc-menu-icon-btn-icon {
                            color: mat.get-color-from-palette($warn, default);
                            --main-color: #{mat.get-color-from-palette($warn, default)};
                            --text-color: #{mat.get-color-from-palette($warn, default-contrast)};
                        }
                    }
                    .rpc-menu-icon-btn-icon {
                        color: mat.get-color-from-palette($warn, default);
                        --main-color: #{mat.get-color-from-palette($warn, default)};
                        --text-color: #{mat.get-color-from-palette($warn, default-contrast)};
                    }
                }
            }
            .rpc-menu-icon-btn {
                &-icon {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
        &-icon {
            color: mat.get-color-from-palette($foreground, disabled);
        }
    }
}
