@use '@angular/material' as mat;
@mixin calendar-view($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $is-dark: map-get($theme, is-dark);

    .apnt-c {
        &-calendar,
        &-time {
            box-shadow: 3px 0px 6px 0 rgb(0 0 0 / 10%);
        }
        &-time,
        &-week,
        &-days {
            background-color: mat.get-color-from-palette($background, card);
        }
        &-time {
            &-item {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-week {
            &-wrap {
                border-color: mat.get-color-from-palette($foreground, divider);
            }
            &-item {
                border-bottom-color: mat.get-color-from-palette($foreground, divider);
                &:not(:first-of-type) {
                    border-left-color: mat.get-color-from-palette($foreground, divider);
                }
                &--current {
                    color: mat.get-color-from-palette($accent, default);
                    border-bottom-color: mat.get-color-from-palette($accent, default);
                }
            }
            &-btn {
                .rpc-btn-link {
                    background-color: mat.get-color-from-palette($background, card);
                    &:not(.mat-button-disabled) {
                        &:hover {
                            .rpc-btn-link {
                                &-icon {
                                    color: mat.get-color-from-palette($accent, default);
                                }
                            }
                        }
                        .rpc-btn-link {
                            &-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                        }
                    }
                    &-icon {
                        color: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
        }
        &-box {
            &-wrap {
                border-color: mat.get-color-from-palette($foreground, divider);
            }
        }
        &-current-time {
            border-top-color: mat.get-color-from-palette($accent, default);
            &-text {
                color: mat.get-color-from-palette($accent, default-contrast);
                background-color: mat.get-color-from-palette($accent, default);
            }
        }
        &-days {
            &-item {
                background-image: repeating-linear-gradient(
                    0deg,
                    mat.get-color-from-palette($foreground, divider) 0px,
                    mat.get-color-from-palette($foreground, divider) 1px,
                    mat.get-color-from-palette($background, card) 0px,
                    mat.get-color-from-palette($background, card) 50%
                );
                &:not(:first-of-type) {
                    border-left-color: mat.get-color-from-palette($foreground, divider);
                }
                &--current {
                    background-image: repeating-linear-gradient(
                        0deg,
                        mat.get-color-from-palette($foreground, divider) 0px,
                        mat.get-color-from-palette($foreground, divider) 1px,
                        mat.get-color-from-palette($background, background) 0px,
                        mat.get-color-from-palette($background, background) 50%
                    );
                }
            }
        }
        &-day-appointment {
            &.mat-calendar-body-cell {
                &:after {
                    background-color: mat.get-color-from-palette($accent, default);
                }
                &.mat-calendar-body-active {
                    &:after {
                        @if (mat.get-color-from-palette($accent, default) == mat.get-color-from-palette($primary, default)) {
                            background-color: mat.get-color-from-palette($warn, default);
                        } @else {
                            background-color: mat.get-color-from-palette($primary, default);
                        }
                    }
                }
            }
        }
        &-appointment {
            &-group {
                background-color: mat.get-color-from-palette($background, card);
                &--current {
                    background-color: mat.get-color-from-palette($background, card, 0.7);
                }
                &-item {
                    &--shown {
                        .apnt-c-appointment {
                            &-group-item-icon {
                                color: var(--status-shown);
                                --main-color: var(--status-shown);
                            }
                        }
                    }
                    &--declined {
                        .apnt-c-appointment {
                            &-group-item-icon {
                                color: var(--status-declined);
                                --main-color: var(--status-declined);
                            }
                        }
                    }
                    &--confirmed {
                        .apnt-c-appointment {
                            &-group-item-icon {
                                color: var(--status-confirmed);
                                --main-color: var(--status-confirmed);
                            }
                        }
                    }
                    &--unconfirmed {
                        &-customer {
                            .apnt-c-appointment {
                                &-group-item {
                                    &-icon {
                                        color: var(--status-confirmed);
                                        --main-color: var(--status-confirmed);
                                        &--second {
                                            color: var(--status-declined);
                                            --main-color: var(--status-declined);
                                        }
                                    }
                                    &-divider {
                                        height: 18px;
                                    }
                                }
                            }
                        }
                        .apnt-c-appointment {
                            &-group-item-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                                --main-color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                        }
                    }
                }
                &-title {
                    &-wrap {
                        &:hover {
                            .apnt-c-appointment {
                                &-group {
                                    &-title-icon {
                                        color: mat.get-color-from-palette($accent, default);
                                    }
                                }
                            }
                        }
                    }
                    &-icon {
                        &--active {
                            color: mat.get-color-from-palette($accent, default);
                        }
                    }
                }
            }
        }
    }
}
