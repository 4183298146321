@mixin my-listings-toolbar-common() {
    .toolbar {
        &-secondary {
            &--minimized {
                .header--internal-listings {
                    &.header {
                        @include media-breakpoint("gt-sm") {
                            grid-template-columns: auto minmax(0, 1fr) 40px;
                            grid-template-areas: "title filters btns";
                            column-gap: 16px;
                        }
                        .header {
                            &-title {
                                @include media-breakpoint("gt-sm") {
                                    grid-template-columns: 36px 1px auto 5px 90px 1px;
                                    grid-template-areas: "back divider-left menu divider-right counter divider-last";
                                    column-gap: 5px;
                                }
                                &-back {
                                    .rpc-btn-link {
                                        @include media-breakpoint("gt-md") {
                                            width: 36px;
                                        }
                                        &-text {
                                            @include media-breakpoint("gt-md") {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &-menu {
                                    .rpc-menu-btn-link {
                                        &-text {
                                            @include media-breakpoint("gt-sm") {
                                                font-size: 16px;
                                                max-width: 200px;
                                            }
                                        }
                                        .mat-button-wrapper {
                                            @include media-breakpoint("gt-sm") {
                                                grid-template-columns: 26px minmax(0, 1fr) 10px;
                                                column-gap: 2px;
                                            }
                                        }
                                    }
                                }
                                &-counter {
                                    @include media-breakpoint("gt-sm") {
                                        font-size: 14px !important;
                                    }
                                }
                                &-divider {
                                    &--last {
                                        @include media-breakpoint("gt-sm") {
                                            display: block;
                                        }
                                    }
                                    &--right {
                                        @include media-breakpoint("gt-sm") {
                                            padding-left: 4px;
                                        }
                                    }
                                }
                            }
                            &-btn {
                                &-wrap {
                                    &-mobile {
                                        @include media-breakpoint("gt-sm") {
                                            display: block;
                                        }
                                    }
                                }
                                &-item,
                                &-divider {
                                    &--desktop {
                                        @include media-breakpoint("gt-sm") {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header--internal-listings {
        .header {
            &-title {
                &-back {
                    .rpc-btn-link {
                        padding: 0 5px;
                        min-width: 36px;
                        width: 36px;
                        @include media-breakpoint("gt-md") {
                            width: auto;
                        }
                        &-icon {
                            font-size: 16px;
                        }
                        &-text {
                            display: none;
                            font-size: 18px;
                            margin-left: 0 !important;
                            @include media-breakpoint("gt-md") {
                                display: block;
                            }
                        }
                    }
                }
                &-menu {
                    .rpc-menu-btn-link {
                        padding: 0 5px;
                        width: 100%;
                        &-icon {
                            grid-area: icon;
                        }
                        &-text {
                            grid-area: text;
                            font-size: 14px;
                            line-height: 1.14;
                            font-weight: 400;
                            letter-spacing: 0;
                            text-align: left;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            margin-left: 0 !important;
                            @include media-breakpoint("gt-xs") {
                                max-width: 154px;
                            }
                            @include media-breakpoint("gt-sm") {
                                font-size: 16px;
                                max-width: none;
                            }
                            @include media-breakpoint("gt-md") {
                                font-size: 21px;
                            }
                        }
                        &-trigger {
                            grid-area: trigger;
                            font-size: 0.5em;
                            margin: 0;
                        }
                        .mat-button-wrapper {
                            display: grid;
                            grid-template-columns: 26px minmax(0, 1fr) 10px;
                            grid-template-areas: "icon text trigger";
                            column-gap: 2px;
                            @include media-breakpoint("gt-sm") {
                                grid-template-columns: 26px minmax(0, 1fr) 10px;
                                column-gap: 4px;
                            }
                        }
                    }
                }
            }
            &-btn-wrap {
                .rpc-btn-link,
                .rpc-menu-btn-link {
                    padding: 0 10px;
                    @include media-breakpoint("gt-md") {
                        padding: 0 16px;
                    }
                }
            }
        }
    }
}
