@mixin folders-view-common() {
    .folders-list {
        &-container {
            &-title {
                &-action {
                    .rpc-btn-link {
                        padding: 0;
                        min-width: 40px;
                        width: 40px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}