// Define the primary, accent and warn palettes
@use '@angular/material' as mat;
$halstead-theme-primary-palette: mat.define-palette($bhs-halstead-primary-color);
$halstead-theme-accent-palette: mat.define-palette($bhs-halstead-accent-color);
$halstead-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$halstead-theme: mat.define-light-theme(
    $halstead-theme-primary-palette,
    $halstead-theme-accent-palette,
    $halstead-theme-warn-palette
);
// Add ".theme-halstead" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-halstead {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($halstead-theme);

    // Apply the theme to the user components
    @include components-theme($halstead-theme);
}
