@use '@angular/material' as mat;
@mixin sort-base($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .sorting {
        &-main {
            &-btn-wrap {
                .rpc-menu-btn-link {
                    &-trigger {
                        color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                    &-icon {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        &-item {
            &:not(:first-of-type) {
                border-top-color: mat.get-color-from-palette($foreground, divider);
            }
        }
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
            &--active {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-btn {
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
                &--active {
                    color: mat.get-color-from-palette($accent, default);
                }
            }
        }
    }
}
