@mixin notification-item-common() {
    .rpc-notification-item {
        &-avatar {
            .avatar-initials-wrap {
                .text,
                .main-image {
                    font-size: 17px;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    @include media-breakpoint("gt-xs") {
                        font-size: 20px;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
        &-listing-img {
            .default-image-wrap {
                .broken-background-image,
                .main-background-image {
                    background-size: cover;
                    border-radius: 6px;
                }
                .broken-background-image {
                    background-size: 90% !important;
                }
            }
        }
    }
}
