@mixin invite-user-dialog-common() {
    .ium {
        &-dialog {
            .mat-dialog-container {
                padding: 0;
                border-radius: 0;
                box-shadow: none !important;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
        &-close {
            .rpc-btn-link {
                min-width: 40px;
                padding: 0;
            }
        }
    }
}