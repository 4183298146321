@mixin rpc-menu-icon-button-common() {
    .rpc-menu-icon-btn {
        .mat-button {
            &-wrapper {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
