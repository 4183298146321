@mixin listing-share-common() {
    .share {
        &-wrap {
            &-dropdown {
                min-width: auto;
                width: 100%;
                max-width: 320px;
                border-radius: 10px;
                border-width: 1px;
                border-style: solid;
                .mat-menu-content {
                    padding: 25px 15px 7px;
                    @include media-breakpoint("gt-xs") {
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
            }
        }
        &-field {
            &--suffix {
                .mat-form-field {
                    &-infix {
                        padding-right: 5px !important;
                    }
                    &-suffix {
                        top: -5px;
                    }
                }
                .share-field-copy-btn {
                    margin-right: -5px;
                }
            }
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 0px !important;
                    margin-bottom: 3px !important;
                }
                &-infix {
                    width: 100%;
                    textarea {
                        &.mat-input-element {
                            max-height: 350px;
                            min-height: 54px;
                        }
                    }
                }
                &-subscript {
                    &-wrapper {
                        min-height: 13px;
                        margin-top: 3px !important;
                    }
                }
            }
        }
    }
}
