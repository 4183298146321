@mixin card-appointment-common() {
    .apnt-t-appointment {
        &-action {
            &-wrap {
                .rpc-icon-btn,
                .rpc-menu-icon-btn {
                    min-width: 1px;
                    padding: 0 5px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    &-icon {
                        font-size: 1.2em;
                    }
                }
            }
        }
        &-info {
            &-img-wrap {
                .avatar-initials-wrap {
                    .text,
                    .main-image {
                        font-size: inherit;
                        width: 2em;
                        height: 2em;
                        line-height: 2em;
                    }
                }
            }
        }
        &-update {
            &:first-of-type {
                .rpc-btn-link {
                    border-radius: 0 0 0 10px;
                }
            }
            &:last-of-type {
                .rpc-btn-link {
                    border-radius: 0 0 10px 0;
                }
            }
            &-wrap {
                &--decline {
                    .apnt-t-appointment-update {
                        .rpc-btn-link {
                            border-radius: 0 0 10px 10px;
                        }
                    }
                }
            }
            .rpc-btn-link {
                width: 100%;
                &-text {
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.35px;
                }
            }
        }
    }
}
