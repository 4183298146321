@mixin appointments-common() {
    .app {
        &-btn {
            &--active {
                .app-btn-icon {
                    transform: rotate(45deg);
                }
            }
            .mat-button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &-icon {
                display: block;
                font-size: 20px;
                transform: rotate(0);
                transition: transform 0.3s linear;
            }
        }
        &-form {
            &-wrap {

            }
        }
    }
}
