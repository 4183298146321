@mixin registration-form-common() {
    .register-password-form-terms {
        .mat-checkbox {
            &-layout {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                column-gap: 12px;
                white-space: initial;
            }
            &-inner-container {
                margin: 3px 0 0;
            }
        }
    }
}
