@use '@angular/material' as mat;
@mixin new-match-settings($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .nm {
        &-item {
            &-title {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-subtitle {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-btn {
                .rpc-btn-link {
                    &.mat-flat-button {
                        border-color: rgba(0,0,0,0);
                    }
                }
            }
        }
        &-info {
            .mat-slide-toggle{
                &.mat-primary {
                    &.mat-checked {
                        .mat-slide-toggle {
                            &-bar {
                                background-color: var(--info-500-15);
                            }
                            &-thumb {
                                background-color: var(--info-500);
                            }
                        }
                    }
                }
            }
        }
    }
}
