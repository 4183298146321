@mixin rpc-tooltip-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .tooltip {
        &-list {
            background-color: mat.get-color-from-palette($background, card);
            box-shadow: 0 3px 40px 0 mat.get-color-from-palette($foreground, divider);
            &--light {
                background-color: mat.get-color-from-palette($background, card);
                .tooltip {
                    &-text {
                        background-color: mat.get-color-from-palette($foreground, text);
                    }
                    &-icon {
                        background-color: mat.get-color-from-palette($foreground, secondary-text);
                    }
                }
            }
        }
        &-item {
            &-text {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}