@use '@angular/material' as mat;
@mixin rpc-file-upload($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-file {
        &-box {
            border-color: mat.get-color-from-palette($foreground, secondary-text);
            &.active {
                border-color: mat.get-color-from-palette($accent, default);
                .rpc-file {
                    &-title-wrap {
                        color: mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        &-title-wrap {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-img {
            &-btn {
                background-color: mat.get-color-from-palette($background, card);
                color: mat.get-color-from-palette($accent, default);
                &:hover {
                    background-color: mat.get-color-from-palette($accent, default);
                    color: mat.get-color-from-palette($background, card);
                }
            }
        }
        &-hint {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}