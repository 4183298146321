@use '@angular/material' as mat;
@mixin appointment($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .appointment-wrap-trigger {
        &--md,
        &--sm {
            .appointment {
                &-wrap {
                    &.new--appointment {
                        .appointment {
                            &-action {
                                &-wrap {
                                    border-top-color: mat.get-color-from-palette($accent, 200);
                                }
                            }
                        }
                    }
                }
                &-action {
                    &-wrap {
                        border-top-color: mat.get-color-from-palette($foreground, divider);
                    }
                }
            }
        }
    }

    .appointment {
        &-wrap {
            background: mat.get-color-from-palette($background, background);
            border-color: mat.get-color-from-palette($foreground, divider);
            &.new--appointment {
                background: mat.get-color-from-palette($accent, 50);
                border-color: mat.get-color-from-palette($accent, 200);
            }
        }
        &-author {
            &-name {
                color: mat.get-color-from-palette($foreground, text);
            }
            &-icon-wrap {
                color: mat.get-color-from-palette($foreground, text);
                border-color: currentColor;
            }
        }
        &-status {
            &-wrap {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &--confirmed {
                color: var(--status-confirmed);
                --main-color: var(--status-confirmed);
            }
            &--declined {
                color: var(--status-declined);
                --main-color: var(--status-declined);
            }
            &--shown {
                color: var(--status-shown);
                --main-color: var(--status-shown);
            }
            &-creator {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
        &-date-wrap {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-action {
            &--accept {
                .rpc-btn-link {
                    &-icon {
                        color: var(--status-confirmed) !important;
                        --main-color: var(--status-confirmed) !important;
                    }
                    &-text {
                        color: var(--status-confirmed) !important;
                    }
                }
            }
            &--decline {
                .rpc-btn-link {
                    &-icon {
                        color: var(--status-declined)  !important;
                        --main-color: var(--status-declined) !important;
                    }
                    &-text {
                        color: var(--status-declined) !important;
                    }
                }
            }
        }
    }
}
