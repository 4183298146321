@use "@angular/material" as mat;

@mixin gallery-listing-card($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .main-gallery-card {
        background-color: mat.get-color-from-palette($background, card);
        box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.05);
        &--new {
            .main-gallery-card-address {
                color: mat.get-color-from-palette($accent, default);
            }
        }
        &-media {
            &-btns-item {
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        background-color: mat.get-color-from-palette($background, card);
                        border-color: mat.get-color-from-palette($foreground, divider);
                        &:hover,
                        &.opened {
                            background-color: mat.get-color-from-palette($accent, default);
                            border-color: mat.get-color-from-palette($accent, default);
                            &.active {
                                &:after {
                                    background-color: mat.get-color-from-palette($accent, default-contrast);
                                    border-color: mat.get-color-from-palette($accent, default);
                                }
                            }
                            .rpc-icon-btn {
                                &-icon {
                                    color: mat.get-color-from-palette($accent, default-contrast);
                                }
                            }
                        }
                        .rpc-icon-btn {
                            &-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                        }
                    }
                    &.mat-button-disabled {
                        background-color: mat.get-color-from-palette($background, card);
                        border-color: mat.get-color-from-palette($foreground, divider);
                        .rpc-icon-btn {
                            &-icon {
                                color: mat.get-color-from-palette($foreground, disabled-button);
                            }
                        }
                    }
                    &.active {
                        &:after {
                            background-color: mat.get-color-from-palette($accent, default);
                            border-color: mat.get-color-from-palette($background, card);
                        }
                    }
                }
            }
            &-select {
                .mat-checkbox {
                    &-background {
                        background: mat.get-color-from-palette($background, card);
                    }
                }
            }
        }
        &-main-info-item {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-footer {
            &-communication {
                .rpc-menu-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                            .rpc-menu-icon-btn-icon {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                                --main-color: #{mat.get-color-from-palette($foreground, secondary-text)};
                                --text-color: #{mat.get-color-from-palette($background, card)};
                            }
                        }
                    }
                }
            }
            &-pick {
                &--empty {
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &:hover {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                                .rpc-btn-link-icon {
                                    color: currentColor;
                                }
                            }
                            &.active {
                                color: var(--picklist-color);
                                .rpc-btn-link-icon {
                                    color: currentColor;
                                }
                            }
                        }
                    }
                }
                .rpc-icon-btn {
                    &:not(.mat-button-disabled) {
                        &:hover {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                            .rpc-icon-btn-icon {
                                color: currentColor;
                            }
                        }
                        &.active {
                            color: var(--picklist-color);
                            .rpc-icon-btn-icon {
                                color: currentColor;
                            }
                        }
                    }
                }
            }
        }
    }
}