@use "@angular/material" as mat;

@mixin remove-listing-tooltip($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .pick-remove-listing-btn {
        .rpc-btn-link {
            &:not(.mat-button-disabled) {
                &:hover {
                    .rpc-btn-link-icon {
                        color: mat.get-color-from-palette($accent, default) !important;
                    }    
                }
                .rpc-btn-link {
                    &-icon {
                        color: mat.get-color-from-palette($foreground, secondary-text) !important;
                    }
                    &-text {
                        color: mat.get-color-from-palette($foreground, text) !important;
                    }
                }
            }
        }
    }
}