@use '@angular/material' as mat;
@mixin doughnut-chart($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .dc {
        &-tooltip {
            background-color: mat.get-color-from-palette($background, card);
            border-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}