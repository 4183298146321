@use '@angular/material' as mat;
@mixin additional-profiles-tab($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .ap {
        &-title {

        }
        &-customer {
            &-status {
                &-wrap {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
        }
    }
}