@use '@angular/material' as mat;
@mixin chat-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .rpc-chatbot {
        &-chat {
            &-preview {
                &-img {
                    @if ($is-dark) {
                        border-radius: 50%;
                    }
                }
                &-message {
                    color: var(--chatbot-500);
                    background-color: var(--chatbot-500-contrast);
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                }
                &-text {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            &-message {
                background-color: var(--chatbot-primary-300);
                &--customer {
                    background-color: var(--chatbot-primary-500);
                    .rpc-chatbot-chat-message {
                        &-icon {
                            background-color: var(--chatbot-primary-500-contrast);
                        }
                        &-text {
                            color: var(--chatbot-primary-500-contrast);
                        }
                    }
                }
                &--error {
                    color: mat.get-color-from-palette($warn, 500);
                    background-color: mat.get-color-from-palette($warn, 500, .1);
                    .rpc-chatbot-chat-message-text {
                        color: mat.get-color-from-palette($warn, 500);
                    }
                }
                &-loader {
                    background: mat.get-color-from-palette($foreground, secondary-text);
                }
                &-text {
                    color: var(--chatbot-primary-300-contrast);
                }
                &-counter {
                    &-wrap {
                        color: mat.get-color-from-palette($foreground, disabled);
                        &--alert {
                            .rpc-chatbot-chat-message-counter {
                                &-icon,
                                span {
                                    color: mat.get-color-from-palette($warn, 500);
                                }
                            }
                        }
                    }
                    span {
                        color: var(--status-confirmed);
                    }
                }
            }
            &-button {
                &--secondary {
                    &:hover {
                        background-color: var(--chatbot-300);
                    }
                    border-color: var(--chatbot-600);
                    color: var(--chatbot-500);
                }
                &-text {
                    color: var(--chatbot-500);
                }
                .rpc-btn-link {
                    &.mat-stroked-button {
                        &:not(.mat-button-disabled) {
                            &:hover {
                                background-color: var(--chatbot-300);
                            }
                            border-color: var(--chatbot-600);
                            color: var(--chatbot-500);
                            .rpc-btn-link {
                                &-text,
                                &-icon {
                                    color: var(--chatbot-500);
                                }
                            }
                        }
                    }
                }
            }
            &-footer {
                &-note {
                    color: mat.get-color-from-palette($foreground, disabled);
                }
            }
        }
    }
}