@mixin map-controls-common() {
    .ccp-map-controls {
        .ccp-map-controls-btn {
            .rpc-icon-btn {
                border-radius: 4px;
                border-width: 1px;
                border-style: solid;
            }
        }
    }
}