@mixin external-listings-dialog($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .el-dialog {
        &-title-wrap,
        &-action-wrap {
            background-color: mat.get-color-from-palette($background, card);
        }
        &-title-wrap {
            border-bottom-color: mat.get-color-from-palette($foreground, divider);
        }
        &-info {
            .rpc-info {
                border-left-color: var(--info-500);
                color: mat.get-color-from-palette($foreground, text);
            }
        }
        &-action-wrap {
            border-top-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}