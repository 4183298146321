@mixin external-listings-common() {
    .external-listings {
        &-title {
            &-action {
                .rpc-btn-link {
                    padding: 0;
                    min-width: 40px;
                    width: 40px;
                    border-radius: 50%;
                }
            }
        }
    }
}