@mixin phone-form-input-common() {
    .combined-phone {
        &-input {
            &-wrap {
                .mat-form-field-infix {
                    width: auto;
                }
            }
        }
        &-wrap {
            .mat-form-field {
                &-wrapper {
                    padding-bottom: 0 !important;
                }
                &-disabled {
                    .mat-form-field {
                        &-flex {
                            pointer-events: none;
                        }
                        &-autofill-control {
                            opacity: 0.5;
                        }
                    }
                }
                &-infix {
                    width: 100%;
                    padding-bottom: 0.65em !important;
                }
                &-suffix {
                    display: flex;
                }
                &-subscript-wrapper {
                    position: static;
                    padding: 0 !important;
                }
                &.mat-form-field {
                    &-appearance {
                        &-fill,
                        &-legacy {
                            .mat-form-field {
                                &-flex {
                                    padding: 8px 15px 0;
                                }
                                &-underline {
                                    position: static;
                                }
                                &-ripple {
                                    height: 1px;
                                }
                            }
                        }
                        &-outline {
                            .mat-form-field {
                                &-infix {
                                    padding: 0.25em 0 0.75em !important;
                                }
                                &-outline {
                                    &-start,
                                    &-end,
                                    &-gap {
                                        border-width: 1px;
                                    }
                                    &-gap {
                                        border-top-color: transparent !important;
                                    }
                                }
                                &-label {
                                    transform: translateY(-1.59369em) scale(0.75) !important;
                                    width: 133.3333933333% !important;
                                }
                                &-suffix {
                                    top: 3px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ngx-mat-select-search {
        .mat-select-search-clear {
            top: 0 !important;
            .mat {
                &-button-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &-icon {
                    position: relative;
                    content: "";
                    text-indent: -99999px;
                    &:before,
                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        top: 50%;
                        right: 3px;
                        width: 18px;
                        height: 1px;
                        transform: rotate(45deg);
                        background: #000;
                        z-index: 5;
                    }
                    &:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}
