@mixin auth-base-common() {
    .auth {
        &-submit-button {
            .rpc-btn-link {
                width: 100%;
                @include media-breakpoint("gt-sm") {
                    width: 140px;
                }
            }
        }
    }
}
