@use '@angular/material' as mat;
@mixin rpc-select($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $warn: map-get($theme, warn);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-select {
        &.mat-form-field {
            .mat-error {
                color: mat.get-color-from-palette($warn, 500);
            }
            &-label,
            &-suffix,
            &-prefix {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
            &-invalid {
                .mat-form-field {
                    &-ripple {
                        background-color: mat.get-color-from-palette($warn, 500) !important;
                    }
                    &-label {
                        color: mat.get-color-from-palette($warn, 500) !important;
                    }
                }
                .mat-input-element {
                    color: mat.get-color-from-palette($warn, 500);
                }
            }
            &.mat-focused {
                .mat-form-field {
                    &-ripple {
                        background-color: mat.get-color-from-palette($foreground, text) !important;
                    }
                    &-label,
                    &-outline {
                        color: mat.get-color-from-palette($foreground, text);
                    }
                }
            }
            &.field {
                &-fill,
                &-legacy {
                    .mat-form-field-underline {
                        &:before {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        &.mat-accent {
            .rpc-select {
                &-icon {
                    color:mat.get-color-from-palette($accent, default);
                }
                &-option {
                    &-icon {
                        color:mat.get-color-from-palette($accent, default);
                    }
                }
            }
        }
        &.mat-primary {
            .rpc-select {
                &-icon {
                    color:mat.get-color-from-palette($primary, default);
                }
                &-option {
                    &-icon {
                        color:mat.get-color-from-palette($primary, default);
                    }
                }
            }
        }
        &.mat-warn {
            .rpc-select {
                &-icon {
                    color:mat.get-color-from-palette($warn, default);
                }
                &-option {
                    &-icon {
                        color:mat.get-color-from-palette($warn, default);
                    }
                }
            }
        }
        &-option {
            &:not(.mat-option-disabled) {
                color: mat.get-color-from-palette($foreground, text);
            }
            &--placeholder {
                &:not(.mat-option-disabled) {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                }
            }
            &-icon {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
    .mat {
        &-primary,
        &-accent,
        &-warn {
            .mat-option {
                &.mat-active {
                    &.rpc-select-option--placeholder {
                        color: mat.get-color-from-palette($foreground, disabled);
                    }
                }
            }
        }
    }
}
