@mixin rpc-icon-button-common() {
    .rpc {
        &-icon,
        &-fab,
        &-mini-fab {
            &-btn {
                .mat-button {
                    &-wrapper {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        &-fab {
            &-btn {
                &-img {
                    display: block;
                    font-size: 34px;
                    width: 1em;
                    height: 1em;
                    line-height: 1;
                }
            }
        }
    }
}
