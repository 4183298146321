@mixin multi-selection-menu-common() {
    .multi-select {
        &--desktop {
            &.multi-select--directions-only {
                .multi-selection {
                    padding: 12px 20px;
                    &.multi-selection--active {
                        grid-template-columns: 95px 1px 120px 1px 40px;
                        grid-template-areas: "checkbox checkbox-divider counter activities-divider directions";
                    }
                }
            }
            &-search {
                .multi-selection {
                    padding-inline: 15px !important;
                    &.multi-selection--active {
                        grid-template-columns: 95px 1px 120px 1px 40px 1px 40px 40px !important;
                        grid-template-areas: "checkbox checkbox-divider counter activities-divider pick activities-divider-second folder directions" !important;
                        padding-inline: 15px 10px !important;
                    }
                }
            }
        }
        &--mobile {
            &.multi-select--directions-only {
                .multi-selection {
                    padding: 5px 14px;
                    &.multi-selection--active {
                        grid-template-columns: auto minmax(0, 1fr) 40px;
                        grid-template-areas: "checkbox . directions";
                    }
                }
            }
            &-search {
                .multi-selection {
                    &.multi-selection--active {
                        grid-template-columns: 150px calc(100% - 242px) 40px 40px;
                        grid-template-areas: "checkbox . pick folder";
                        padding-left: 10px;
                    }
                }
            }
            .multi-selection {
                &.multi-selection--active {
                    .multi-selection {
                        &-checkbox {
                            .mat-checkbox {
                                &-layout {
                                    grid-template-columns: 16px calc(100% - 20px);
                                    grid-template-areas: "checkbox text";
                                }
                            }
                        }
                    }
                }
                .multi-selection {
                    &-checkbox {
                        .mat-checkbox {
                            &-layout {
                                grid-template-columns: 1fr 16px auto 1fr;
                                grid-template-areas: ". checkbox text .";
                            }
                            &-label {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        &--removed {
            &.multi-select {
                &--desktop {
                    .multi-selection {
                        padding-inline: 15px !important;
                        &.multi-selection--active {
                            grid-template-columns: 100px 1px 120px 1px 190px 1px 40px !important;
                            grid-template-areas: "checkbox checkbox-divider counter activities-divider activities activities-divider-second directions" !important;
                            padding-inline: 15px 10px !important;
                        }
                    }
                }
                &--mobile {
                    .multi-selection {
                        &.multi-selection--active {
                            grid-template-columns: 150px calc(100% - 242px) 84px;
                            grid-template-areas: "checkbox . activities";
                            padding-left: 10px;
                            .activity-controls-wrapper {
                                &--removed {
                                    column-gap: 4px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .multi-selection {
        &-checkbox {
            .mat-checkbox {
                &-layout {
                    display: grid;
                    grid-template-columns: 16px calc(100% - 22px);
                    grid-template-areas: "checkbox text";
                    grid-template-rows: 40px;
                    column-gap: 6px;
                    align-items: center;
                    height: 100%;
                }
                &-inner-container {
                    grid-area: checkbox;
                    margin: 0;
                }
                &-label {
                    grid-area: text;
                    letter-spacing: 0.24px;
                }
            }
        }
        &-divider {
            height: 24px;
        }
        &-activity {
            grid-area: activities;
            &--all-mobile {
                .activity-controls-wrapper {
                    justify-content: flex-end;
                }
            }
            &--divider {
                grid-area: activities-divider;
                &-second {
                    grid-area: activities-divider-second;
                }
            }
        }
        &-folder {
            grid-area: folder;
        }
        &-pick {
            grid-area: pick;
        }
        &-remove {
            grid-area: remove;
        }
        &-directions {
            grid-area: directions;
        }
        &-delete {
            grid-area: delete;
        }
        &-restore {
            grid-area: restore;
        }
        &--route {
            display: block;
            position: absolute;
            bottom: 60px;
            right: 10px;
            z-index: 100;
            @include media-breakpoint("gt-xs") {
                display: none;
            }
            .rpc-icon-btn {
                font-size: 22px !important;
                height: 44px;
                line-height: 44px;
                width: 44px;
                box-shadow: 0 3px 6px 0 rgba(250, 178, 178, 0.1);
            }
        }
    }
}
