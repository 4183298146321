@use "@angular/material" as mat;
@mixin activities-tooltip($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .activities-tooltip {
        &-item {
            .rpc-btn-link {
                &:not(.mat-button-disabled) {
                    .rpc-btn-link {
                        &-icon {
                            color: mat.get-color-from-palette($foreground, secondary-text);
                        }
                        &-text {
                            color: mat.get-color-from-palette($foreground, text);
                        }
                    }
                }
            }
            &.liked {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        &.rpc-btn-link {
                            &:hover,
                            &.active {
                                color: var(--like-color);
                                .rpc-btn-link-icon{
                                    color: var(--like-color);
                                }
                            }
                        }
                    }
                }
            }
            &.shown {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        &.rpc-btn-link {
                            &:hover,
                            &.active {
                                color: var(--visit-color);
                                .rpc-btn-link-icon {
                                    color: var(--visit-color);
                                }
                            }
                        }
                    }
                }
            }
            &.disliked {
                .rpc-btn-link {
                    &:not(.mat-button-disabled) {
                        &.rpc-btn-link {
                            &:hover,
                            &.active {
                                color: var(--dislike-color);
                                .rpc-btn-link-icon {
                                    color: var(--dislike-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}