@use '@angular/material' as mat;

@mixin user-activity-history-tooltip($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .contributor {
        &-text {
            color: mat.get-color-from-palette($foreground, text);
        }
        &-icon {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-menu {
            &-item {
                &:hover {
                    background-color: mat.get-color-from-palette($foreground, base, .05);
                }
            }
            &-status,
            &-date {
                color: mat.get-color-from-palette($foreground, secondary-text);
            }
        }
    }
}