@use '@angular/material' as mat;
@mixin oh-tooltip($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-oh-tooltip {
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
