@use "@angular/material" as mat;
@mixin my-listing-toolbar($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .header--internal-listings {
        .header {
            &-title {
                &-back {
                    .rpc-btn-link {
                        &:not(.mat-button-disabled) {
                            &:hover,
                            &.active {
                                .rpc-btn-link {
                                    &-icon,
                                    &-text {
                                        color: mat.get-color-from-palette($foreground, text);
                                    }
                                }
                            }
                        }
                    }
                }
                &-menu {
                    .rpc-menu-btn-link {
                        &:hover,
                        &[aria-expanded="true"] {
                            .rpc-menu-btn-link-text {
                                color: mat.get-color-from-palette($foreground, secondary-text);
                            }
                        }
                        &-text {
                            color: mat.get-color-from-palette($foreground, text);
                        }
                    }
                }
                &-counter {
                    color: mat.get-color-from-palette($foreground, secondary-text);
                    &--max {
                        color: mat.get-color-from-palette($warn, 400);
                    }
                }
            }
        }
    }
}
