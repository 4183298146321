@use '@angular/material' as mat;
@mixin listing-price-change-history($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-ld-price-change-icon {
        color: var(--decrease-color);
        &--up {
            color: var(--increase-color);
        }
    }
}