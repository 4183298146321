@use '@angular/material' as mat;
@mixin notification-list($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $is-dark: map-get($theme, is-dark);

    .rpc-notifications-list {
        &-empty {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-title {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
        &-note {
            color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
