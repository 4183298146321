@use '@angular/material' as mat;
@mixin folder-selection($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $is-dark: map-get($theme, is-dark);

    .f-item {
        background-color: mat.get-color-from-palette($accent, default, 0);
        &--active {
            background-color: mat.get-color-from-palette($accent, default, .3);
        }
        &-icon {
            color: mat.get-color-from-palette($accent, default);
        }
    }
}